import styled from '@emotion/styled';
import { Div } from 'components/styles';
import { ReactComponent as SVG } from 'assets/images/bg-orange.svg';

export const AuthStyle = styled(Div)`
   position: relative;
   height: 100%;
   width: 100%;

   ${({ theme }) => theme.media?.semiMedium} {
      flex-direction: column;
      padding: 0 2rem;
   }
`;

export const EmailConfimation = styled.div`
   display: flex;
   justify-content: start;
   align-items: center;
   flex-direction: column;
   padding-top: 5%;
   line-height: 2.5rem;

   background-color: ${({ theme }) => theme.colors?.white};
   width: 400px;
   padding: 3rem;
   // box-shadow: 0 3px 5px rgba(69, 80, 101, 0.2);
   box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);
   position: relative;

   ${({ theme }) => theme.media?.semiMedium} {
      width: 100%
   }

   .logo-box {
      width: 120px;
      border-radius: 5px;
      background: #fff;
      padding: 5px 10px;
      margin: auto;
      margin-bottom: 10px;
      img {
         width: 100%;
      }
   }
   .heading {
      font-size: 4rem;

      ${({ theme }) => theme.media?.semiMedium} {
         font-size: 2rem;
      }
   }
`;

export const BgContainer = styled.div`
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   width: 100%;
   z-index: -1;

   ${({ theme }) => theme.media?.semiMedium} {
      display: none;
   }
`;

export const BGImage = styled(SVG)`
   height: 100%;
   position: absolute;
   right: -47px;
   max-width: 100%;
   display:none;
`;

export const BgWhite = styled.div`
   height: 100vh;
   background: ${({ theme }) => theme.colors?.white};
   position: relative;
   z-index: 10;

   &.orange {
      width: 100%;
      background-color: ${({ theme }) => theme.colors?.orange01};
   }
`;

export const FormContainer = styled(Div)`
   background-color: ${({ theme }) => theme.colors?.white};
   width: 400px;
   padding: 3rem;
   // box-shadow: 0 3px 5px rgba(69, 80, 101, 0.2);
   box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);
   position: relative;
   z-index: 9;

   &.register {
      width: 475px;
   }
   &.login {
      // box-shadow: none;
   }

   .logo-box {
      width: 120px;
      border-radius: 5px;
      background: #fff;
      padding: 5px 10px;
      margin: auto;
      margin-bottom: 10px;
      img {
         width: 100%;
      }
   }
   ${({theme}) => theme.media?.mobile} {
      box-shadow: none;
      width: 100%;
      padding: 1rem;
   }
`;

export const Form = styled.form`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   margin-top: 5rem;
`;
export const InputWrapper = styled(Div)`
   display: grid;
   grid-template-columns: 1fr;
   width: 100%;
   margin-bottom: 1.5rem;

   &.col-2 {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;
   }
`;

export const HeadingOne = styled.div`
   color: #fe4f12;
   font-size: 1.6rem;
   text-align: center;
`;

export const HeadingTwo = styled.div`
   margin-top: 0.5rem;
   color: ${({ theme }) => theme.colors?.black02};
   font-size: 1.7rem;
   font-weight: 580;

   &.text-center {
      text-align: center;
   }
`;

export const NavContainer = styled.div`
   display: flex;
`;

export const Logo = styled.img`
   width: 8%;
   position: absolute;
   top: 40px;
   left: 50px;
`;

export const OtpHint = styled.p`
   font-size: 12px;
   color: #7a7171;
   padding: 5px 0;
`