import { css } from '@emotion/react';

export const Override = css`
   .phone-input {
      height: 43px !important;
   }

   .react-tel-input .form-control {
      width: 100%;
      background-color: #fff;
      outline: none;
      font-size: 1.4rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      border-radius: 0;
      padding: 0 1rem;
      background: transparent !important;
      box-sizing: border-box;
      border: 1px solid #c1c7d0;
      height: 43px;
      border-radius: 3px;
      border: 1px solid #d8d8d8;
   }

   .label {
      pointer-events: none;
      color: #999;
      white-space: nowrap;
      margin-bottom: 1rem;
      display: block;
      font-size: 1.4rem;
      font-weight: 400;
      color: #1e1c24;
   }

   .error {
      margin: 0.3rem 0 0 0;
      color: #ed1c24;
      font-size: 1.4rem;
      letter-spacing: -0.06rem;
      font-weight: 500;
   }

   input:-webkit-autofill,
   input:-webkit-autofill:hover,
   input:-webkit-autofill:focus,
   input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
   }

   .input-range__label {
      font-size: 1.3rem !important;
      font-weight: 500 !important;
      position: absolute !important;
      top: -2px !important;
   }

   .input-range__track--active {
      background: #ed7d32 !important;
   }

   .input-range__slider {
      background: #ed7d32 !important;
      border: 1px solid #ed7d32 !important;
   }

   .input-range__track {
      width: 70%;
      position: absolute;
      height: 0.4rem!important;
      left: 15%;
   }

   .input-range__label--max {
      right: 18px !important;
   }

   .input-range__label--min {
      left: 20px !important;
   }

   .input-range__label--value {
      left: 50% !important;
      top: 10px !important;
   }

   .input-range__slider {
      width: 1.6rem !important;
      height: 1.6rem !important;
   }

   .tooltip {
      background-color: white !important;
      color: #000 !important;
      border: 1px solid #efefef !important;
      width: 250px;
      font-size: 1.2rem !important;
      line-height: 1.6rem;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
   }

   .react-datepicker {
      font-size: 1.1rem !important;
   }

   .react-datepicker__day--selected,
   .react-datepicker__day--in-selecting-range,
   .react-datepicker__day--in-range,
   .react-datepicker__month-text--selected,
   .react-datepicker__month-text--in-selecting-range,
   .react-datepicker__month-text--in-range,
   .react-datepicker__quarter-text--selected,
   .react-datepicker__quarter-text--in-selecting-range,
   .react-datepicker__quarter-text--in-range,
   .react-datepicker__year-text--selected,
   .react-datepicker__year-text--in-selecting-range,
   .react-datepicker__year-text--in-range {
      background-color: #ed7d32 !important;
      border-radius: 50% !important;
   }

   .react-tel-input .form-control.invalid-number {
      border: 1px solid #d8d8d8 !important;
   }

   .react-tel-input .flag-dropdown.invalid-number {
      border: 1px solid #d8d8d8 !important;
   }
`;
