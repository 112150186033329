import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { SideDrawer } from "components/side-drawer/side-drawer";
import { DurationProgress } from "components/duration-progress/duration-progress";
import { Button } from "components/button/button";
import { Div, TextGrey, Span, TextOrange } from "components/styles";
import AccountIcon from "assets/images/credit-card.svg";
import BalanceIcon from "assets/images/balance.svg";
import ErrorIcon from "assets/images/error-icon.png";
import BarclaysIcon from "assets/images/barclays.png";
import MonzoIcon from "assets/images/bank-logo.png";
import SantanderIcon from "assets/images/satander.png";
import NatwestIcon from "assets/images/natwest.png";
import HsbcIcon from "assets/images/hsbc.png";
import moment from "moment";
import { getConsents, redirectToBankPage, sendConsentData } from "api/bank";
import { useSnackbar } from "context/snackbar/snackbar";
import {
  PermissionTitle,
  PermissionTextContent,
  PermissionsStyle,
  PermissionIcon,
  PermissionStyle,
} from "pages/permission/permission.style";
import CheckedIcon from "assets/images/checked.png";
import { getDifference } from "utils/date-function";

import {
  PermissionSidebarDetails,
  ConfirmCancellationStyle,
  GreyBackground,
  GreyWhiteBackground,
  ConfirmCancellationContainerStyle,
  ConfirmSuccessStyle,
  ConfirmSuccessContainer,
  CancelPermissionContainerStyle,
  CancelPermissionStyle,
  ConfirmPermissionContainerStyle,
} from "./permission-details.style";
import { DashboardHeader } from "components/dashboard-header/dashboard-header";

export const logoIcons = {
  hsbc: HsbcIcon,
  barclays: BarclaysIcon,
  monzo: MonzoIcon,
  santander: SantanderIcon,
  natwest: NatwestIcon,
};

// interface IPermissionDetails {
//    visible: boolean;
//    handleClose: () => void;
//    daysLeft: number;
//    status: string;
//    consentExpiry: string;
// }

const permissonContent = [
  {
    id: 1,
    icon: AccountIcon,
    title: "Your accounts",
    brief:
      "For you to use this service E-DocOnline needs to access information from your accounts at your bank.",
  },
  {
    id: 4,
    icon: BalanceIcon,
    title: "Your balances",
    brief:
      "For you to use this service E-DocOnline needs to access information from your accounts at your bank.",
  },
];

const PermissionDetails = (
  { visible, handleClose, daysLeft, status, consentExpiry },
  rest
) => {
  const [cancelScreenvisibility, setcancelScreenVisibility] = useState(false);
  const [confirmScreenVisibility, setConfirmScreenVisibility] = useState(false);
  const history = useHistory();
  function daysRemaining(from) {
    const eventdate = moment(from);
    const todaysdate = moment();
    return eventdate.diff(todaysdate, "days");
  }
  const diff = getDifference(consentExpiry?.consentExpiry);
  return (
    <>
      <ConfirmCancellation
        visible={confirmScreenVisibility}
        cancel={() => setConfirmScreenVisibility(false)}
        consent={consentExpiry}
      />
      <CancelPermission
        visible={cancelScreenvisibility}
        cancel={() => setcancelScreenVisibility(false)}
        consent={consentExpiry}
      />
      <SideDrawer
        returnText="Back to All permissions"
        visible={visible}
        handleClose={handleClose}
      >
        <PermissionSidebarDetails>
          <Div className="font-20 mb-15 font-regular">Permission Details</Div>
          <Span className="font-14 text-grey">
            Currently viewing permission details or barclays
          </Span>
          <DurationProgress
            daysLeft={daysRemaining(consentExpiry?.consentExpiry)}
            status={diff >= 0 ? "Active" : "Expired"}
          />
          <Div className="my-30 font-15 font-regular">
            The data we currently have access to
          </Div>
          <PermissionsStyle>
            {permissonContent.map((permission) => (
              <PermissionStyle>
                <PermissionIcon src={permission.icon} />
                <Div className="">
                  <PermissionTitle>{permission.title}</PermissionTitle>
                  <PermissionTextContent className="side-drawer">
                    <div className="side">{permission.brief}</div>
                  </PermissionTextContent>
                </Div>
              </PermissionStyle>
            ))}
            <Div className="ml-40 font-15">
              <TextGrey className="mb-13">
                You started sharing your data on
              </TextGrey>
              {/* <Div className="mb-13">20 May 2020</Div> */}
              <Div className="mb-13">
                {moment(consentExpiry?.createAt).format("DD MMM YYYY")}
              </Div>
              <TextGrey className="mb-13">
                We will access your data until
              </TextGrey>
              <Div>
                {moment(consentExpiry?.consentExpiry).format("DD MMM YYYY")}
              </Div>
              <Div className="mx-auto mt-30">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => handleClose()}
                >
                  Back
                </Button>
                <Span className="ml-20">
                  <Button
                    type="button"
                    onClick={() => {
                      setcancelScreenVisibility(true);
                      handleClose();
                    }}
                  >
                    Cancel Permission
                  </Button>
                </Span>
                {/* <Span className="mt-20">
                           <Button
                              type="button"
                              onClick={() => {
                                 console.log('consentExpiry',consentExpiry, rest)
                                 window.localStorage.setItem('updateConsent', JSON.stringify(consentExpiry));
                                 history.push('/permission')
                              }}
                           >
                              Update Permission
                           </Button>
                        </Span> */}
              </Div>
            </Div>
          </PermissionsStyle>
        </PermissionSidebarDetails>
      </SideDrawer>
    </>
  );
};

export const ConfirmCancellation = ({ cancel, visible, consent }) => {
  const [successScreen, setSuccessScreen] = useState(false);
  const { notify } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setSuccessScreen(false);
    window.location.reload();
  };
  const cancelConfirm = () => {
    (async () => {
      const parseObj = JSON.parse(consent.accountRequest);
      setLoading(true);
      const obj = {
        bankName: consent.bankName,
        consentType: "DELETE",
        consent: {
          Data: {
            Permissions: parseObj.Permissions,
            ExpirationDateTime: parseObj.ExpirationDateTime,
            // TransactionFromDateTime: parseObj.TransactionFromDateTime,
            // TransactionToDateTime: parseObj.TransactionToDateTime#
            TransactionFromDateTime: "1995-07-15T00:00:00+00:00",
            TransactionToDateTime: "2037-12-31T23:59:59+00:00",
          },
          Risk: {},
        },
      };

      const result = await sendConsentData(obj);
      if (result.ok) {
        notify({ message: "success", variant: "success" });
        setSuccessScreen(true);
        setLoading(false);
        cancel();
        // setTimeout(() => {
        //    window.location.reload();
        // }, 1500)
      }
    })();
  };
  return (
    <>
      <ConfirmSuccess
        visible={successScreen}
        handleClose={() => handleClose()}
      />
      <ConfirmCancellationStyle className={visible ? "visible" : ""}>
        <DashboardHeader />
        <ConfirmPermissionContainerStyle>
          <Div className="font-22 mb-20 mt-20 font-regular text-center">
            Confirm Cancellation
          </Div>
          <TextGrey className="font-15 mb-20 text-center">
            We are cancelling your permission for us to access the following
            data
          </TextGrey>

          <ConfirmCancellationContainerStyle>
            {consent && (
              <img
                src={logoIcons[consent.bankName.toLowerCase()]}
                alt="bank-logo"
              />
            )}
            <PermissionsStyle>
              {permissonContent.map((permission) => (
                <PermissionStyle>
                  <Div className="">
                    <PermissionTitle>{permission.title}</PermissionTitle>
                    <PermissionTextContent className="side-drawer">
                      {permission.brief}
                    </PermissionTextContent>
                  </Div>
                </PermissionStyle>
              ))}
            </PermissionsStyle>
          </ConfirmCancellationContainerStyle>
          <GreyWhiteBackground>
            <Div className="font-16 text-black">
              Are you sure you want to cancel permission to access this account?{" "}
            </Div>
            <Div className="mx-auto mt-30">
              <Button type="button" variant="secondary" onClick={cancel}>
                Back
              </Button>
              <Span className="ml-20">
                <Button type="button" onClick={() => cancelConfirm()}>
                  {loading ? "Loading..." : "I Confirm"}
                </Button>
              </Span>
            </Div>
          </GreyWhiteBackground>
        </ConfirmPermissionContainerStyle>
        {/* <GreyBackground>
               <Div className="font-16 text-black">
                  Are you sure you want to cancel permission to access this account?{' '}
               </Div>
               <Div className="mx-auto mt-30">
                  <Button type="button" variant="secondary" onClick={cancel}>
                     Back
                  </Button>
                  <Span className="ml-20">
                     <Button
                        type="button"
                        onClick={() => cancelConfirm()}
                     >
                        I Confirm
                     </Button>
                  </Span>
               </Div>
            </GreyBackground> */}
      </ConfirmCancellationStyle>
    </>
  );
};

export const CancelPermission = ({ cancel, visible, consent }) => {
  const [successScreen, setSuccessScreen] = useState(false);

  return (
    <>
      <ConfirmCancellation
        visible={successScreen}
        cancel={() => setSuccessScreen(false)}
        consent={consent}
      />
      <CancelPermissionStyle className={visible ? "visible" : ""}>
        <DashboardHeader />
        <CancelPermissionContainerStyle>
          <Div className="font-22 font-bold mb-20 text-center text-black">
            The Impact to your service
          </Div>
          <TextGrey className="font-15 mb-20 text-center">
            The service receive will be reduced to the basic plan, as we can no
            longer access your data. This means
          </TextGrey>
          <Div className="font-15">
            <Div className="d-flex my-30 justify-center-between align-items-start">
              <img src={ErrorIcon} alt="cancel" />
              <Div
                className="ml-20"
                style={{ position: "relative", top: "-5px" }}
              >
                We won&apos;t be able to provide your bank data as part of your
                mortage/ lending process
              </Div>
            </Div>
            <Div className="d-flex justify-center-between align-items-start">
              <img src={ErrorIcon} alt="cancel" />

              <Div
                className="ml-20"
                style={{ position: "relative", top: "-5px" }}
              >
                You will have to enter your financial information manually if
                you want to begin using this service again
              </Div>
            </Div>
          </Div>
          <GreyWhiteBackground>
            <Div className="font-16 text-black">
              Have you read through and wish to continue?
            </Div>
            <Div className="mx-auto mt-30">
              <Button type="button" variant="secondary" onClick={cancel}>
                Cancel
              </Button>
              <Span className="ml-20">
                <Button
                  type="button"
                  onClick={() => {
                    setSuccessScreen(true);
                    cancel();
                  }}
                >
                  I Understand
                </Button>
              </Span>
            </Div>
          </GreyWhiteBackground>
        </CancelPermissionContainerStyle>

        {/* <GreyBackground>
               <Div className="font-16 text-black">Have you read through and wish to continue?</Div>
               <Div className="mx-auto mt-30">
                  <Button type="button" variant="secondary" onClick={cancel}>
                     Cancel
                  </Button>
                  <Span className="ml-20">
                     <Button
                        type="button"
                        onClick={() => {
                           setSuccessScreen(true);
                           cancel();
                        }}
                     >
                        I Understand
                     </Button>
                  </Span>
               </Div>
            </GreyBackground> */}
      </CancelPermissionStyle>
    </>
  );
};

const ConfirmSuccess = ({ visible, handleClose }) => {
  return (
    <ConfirmSuccessStyle className={visible ? "visible" : ""}>
      <DashboardHeader />
      <ConfirmSuccessContainer>
        <Div>
          <img src={CheckedIcon} alt="bank-logo" />
        </Div>
        <Div className="font-22 font-bold text-grey">Cancellation complete</Div>
        <TextGrey className="font-15">
          We are no longer providing us access to your banking data and your
          service had been reduced to the basic plan
        </TextGrey>

        <TextOrange onClick={handleClose}>
          Go to your connected account
        </TextOrange>
        <TextOrange onClick={handleClose}>view updated plan</TextOrange>
      </ConfirmSuccessContainer>
    </ConfirmSuccessStyle>
  );
};

export { PermissionDetails };
