import React, { useEffect, useState } from "react";
import {
  BoxCard,
  BoxCardTable,
  CircularProgressWrapper,
  CircularProgressWrapperMain,
  IncomeCardHead,
  IncomePageWrapperMain,
  IncomePageWrapperTop,
  RatingType,
  ListWrapper,
  IncomeTable,
} from "./ProofOfFunds.style";
import { DashboardOverviewHeader } from "../../../components/dashboard-overview/dashoard-overview-header";
import { useSnackbar } from "../../../context/snackbar/snackbar";

import moment from "moment";

import { MainWrapper, OverviewWrapper } from "../dashboard.overview.style";
import { MonthlyAffordability } from "../tabs/monthly-affordability";
import { MonthlyExpenses } from "../tabs/monthly-expenses";
import { MonthlyIncome } from "../tabs/monthly-income";
import { getAccount, getAccountData, getClientConsents, getConsents, getKyc, getTransaction, getUserData } from "api/bank";
import CircularProgress from "components/CircularProgress/CircularProgress";
import styles from "./styles.module.css";
import { PageLoader } from "components/page-loader/page-loader";

const ProofOfFunds = () => {
  const [incomeTab, setIncomeTab] = useState(false);
  const [endData, setEndData] = useState('2023-05-16')
  const [companyDetailData, setCompanyDetailsData]:any = useState({
    company_number: "",
    company_name: "",
    company_status: "",
    date_of_creation: ''
  })
  const { notify } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false)
  const [selectedConsent, setSelectedConsent] = useState({})
  const [data, setData]: any = useState();
  const [incomeAmount, setIncomeAmount] = useState(0);
  const [expensesAmount, setExpensesAmount] = useState(0);
  const [expensesData, setExpensesData]: any = useState([]);
  const [incomesData, setIncomesData]: any = useState([]);
  const [affordabilityData, setAffordabilityData]: any = useState([]);
  const [accountId, setAccountId] = useState()
  const [dashboardList, setDashboardList]: any = useState([])
  const [keyInfo, setKeyInfo]: any = useState({})
  const [selectedDate, setSelectedDate] = useState()
  const [totalTransactionsCount, setTotalTransactionsCount] = useState(0)
  const [metrics, setMetrics]:any = useState([])
  const [accountName, setAccountName] = useState()
  const userData = JSON.parse(
    localStorage.getItem("<<<user-info>>>") as string
  );
  const [transactionDate, setTransactionDate] = useState({
    fromDate: moment().subtract(12, "month").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  });
  // const [setTransactions,setSetTransactions] = useState([]);
  const [isVerification, setIsVerification]: any = useState({
    isAddressVerification: true,
    isBankingVerification: true,
    isCustomerVerification: true,
  });
  const [sourceOfInCome, setSourceOfInCome] = useState({
    isSalary: false,
    isDividends: false,
    isBusinessIncome: false,
    isInvestments: false,
    isOthers: false
  });
  const isEmpty = (obj: any) => Object.keys(obj).length === 0;
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      // setIsLoading(true);
      const result = await getConsents({ bankName: "all" });

      if (result.ok && result?.data?.data.length) {

        setSelectedDate(result?.data?.data[0]?.createdAt)
        // setIsLoading(false);
        // if (result?.data?.data?.length != 0) {
        //   setSelectedConsent(result.data?.data[0]);
        // } else {
        //   setIsLoading(false);
        // }
        // setIsLoading(false);
        // return;
      } else {
        notify({
          message: "Something went wrong!",
          variant: "error",
        });
        setIsLoading(false);
      }
      // setIsLoading(false);
    })();
  }, []);
  // useEffect(() => {
  //   // (async () => {
  //   //   const result = await getAccount({ bankName: selectedConsent['bankName'] });
  //   //   if (result.ok) {
  //   //     setAccountId(result?.data?.data?.Data?.Account[0]?.AccountId);
  //   //   } else {
  //   //     //   setIsLoading(false);
  //   //   }
  //   // })();
  //   }, [selectedConsent]);
  useEffect(() => {
    const dashboardList: any = [];
    if (accountId != "") {
      (async () => {
        if (userData.id && selectedDate) {
          const result = await getKyc({
            "userId": userData.id,
            "date_to": selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : "",
            "n_months": 12,
            "account_id": null
          });
          if (result?.data?.code === 200) {
            let obj = {
              isSalary: false,
              isDividends: false,
              isBusinessIncome: false,
              isInvestments: false,
              isOthers: false
            }
            if (!isEmpty(result?.data?.data?.kycDetails)) {
              setMetrics(result?.data?.data?.kycDetails?.monthly)
              setCompanyDetailsData(result?.data?.data?.companyDetails)
              setKeyInfo(result?.data?.data?.kycDetails)
              setTotalTransactionsCount(result?.data?.data?.kycDetails?.data.length)
              result?.data?.data?.kycDetails?.data.forEach((item) => {
                if (item?.category?.match('Inbound transfer')) {
                  obj.isSalary = true
                  item['category'] = 'Salary'
                  dashboardList.push(item)
                }
                else if (item?.category?.match('MONZO') ||
                  item?.category?.match('dividends') ||
                  item?.category?.match('dividend')
                  && item.CreditDebitIndicator === "Credit") {
                  obj.isDividends = true
                  item['category'] = 'Divident'
                  dashboardList.push(item)
                }
                else if (item?.category?.match('income') && item.CreditDebitIndicator === "Credit") {
                  obj.isBusinessIncome = true
                  item['category'] = 'Business Income'
                  dashboardList.push(item)
                }
                else if (item?.category?.match('interest') || item?.category?.match('investment') && item.CreditDebitIndicator === "Credit") {
                  obj.isInvestments = true
                  item['category'] = 'Investments'
                  dashboardList.push(item)
                }
                else if (item?.category?.match('Others') || item?.category?.match('Other') && item.CreditDebitIndicator === "Credit") {
                  obj.isOthers = true
                  item['category'] = 'Others'
                  dashboardList.push(item)
                }
                setSourceOfInCome({ ...obj })
              })
              setDashboardList(dashboardList)
              setIsLoading(false);

            } else {
              // setSetTransactions([]);
            }
          } else {
            // setIsLoading(false);
          }
        }
      })();
    }
  }, [selectedDate]);

  const handleSelect = (data) => {
    setSelectedConsent(data);
  };


  // const handleIncomeTab = () => {
  //   closeIncomeTab(true);
  // };
  const closeIncomeTab: any = () => {
    closeIncomeTab(false);
  };

  const [expenses, setExpenses] = useState(false);
  const handleExpensesTab = () => {
    setExpenses(true);
  };
  const closeExpenses = () => {
    setExpenses(false);
  };

  const [affordability, setAffordability] = useState(false);
  const handleAffordabilityTab = () => {
    setAffordability(true);
  };
  const closeAffordability = () => {
    setAffordability(false);
  };

  return (
    <>
      {isLoading ? (
      <PageLoader/>
      ):(<OverviewWrapper>
      <DashboardOverviewHeader
        incomesData={incomesData}
        incomeAmount={incomeAmount}
        expensesData={expensesData}
        expensesAmount={expensesAmount}
        affordabilityData={affordabilityData}
        affordabilityAmount={incomeAmount - expensesAmount}
      />

      <MonthlyIncome
        visible={incomeTab}
        incomesData={incomesData}
        incomeAmount={incomeAmount}
        handleClose={closeIncomeTab}
      />

      <MonthlyExpenses
        visible={expenses}
        expensesData={expensesData}
        expensesAmount={expensesAmount}
        handleClose={closeExpenses}
      />

      <MonthlyAffordability
        visible={affordability}
        affordabilityData={affordabilityData}
        affordabilityAmount={incomeAmount - expensesAmount}
        handleClose={closeAffordability}
      />

      <MainWrapper>
        <IncomePageWrapperMain>
          <IncomePageWrapperTop>
            <BoxCard>
              <div>
                <IncomeCardHead>Rating</IncomeCardHead>
              </div>

              <CircularProgressWrapperMain>
                <CircularProgressWrapper>
                  <CircularProgress
                    progressValue={selectedDate && moment(selectedDate).format("YYYY-MM-DD")}
                        indicatorColor="#40EA8F"
                        fontSize="9px"
                  />
                  <RatingType>Amount Opening Date</RatingType>
                </CircularProgressWrapper>
                <CircularProgressWrapper>
                  <CircularProgress
                     progressValue={keyInfo?.n_income_source}
                     indicatorColor="#40EA8F"
                  />
                  <RatingType>Source of Income</RatingType>
                    </CircularProgressWrapper>
                <CircularProgressWrapper>
                 <CircularProgress
                      progressValue={keyInfo?.transactions_risk_level}
                      indicatorColor={`${keyInfo?.transactions_risk_level === "low"
                        ? "#FF0000" : "#40EA8F"}`}
                    />
                  <RatingType>
                    Transaction Risk Level <br /> Low, Medium, High
                  </RatingType>
                </CircularProgressWrapper>
                <CircularProgressWrapper>
                  <CircularProgress
                    progressValue={keyInfo?.aml_flag}
                    indicatorColor="#40EA8F"
                  />
                  <RatingType>AML Flags</RatingType>
                    </CircularProgressWrapper>
                    <CircularProgressWrapper>
                      <CircularProgress
                    fullProgress={true }
                    progressValue={totalTransactionsCount}
                    indicatorColor="#40EA8F"
                  />
                  <RatingType>No. of Transaction</RatingType>
                    </CircularProgressWrapper>
                
              </CircularProgressWrapperMain>
            </BoxCard>

            <BoxCard>
              <div>
                <IncomeCardHead>Verification</IncomeCardHead>
              </div>

              <div>
                <ListWrapper className={styles.list}>
                  <input
                    type="checkbox"
                    id="BankAccountVerification"
                    name="Bank account verification"
                    checked={isVerification?.isBankingVerification}
                    onChange={(e)=>{setIsVerification({...isVerification,isBankingVerification:e.target.checked})}}
                  />
                  <label htmlFor="BankAccountVerification">
                    Bank account verification
                  </label>
                </ListWrapper>

                <ListWrapper className={styles.list}>
                  <input
                    type="checkbox"
                    id="CustomerVerification"
                    name="Customer Verification"
                    checked={isVerification?.isCustomerVerification}
                    onChange={(e)=>{setIsVerification({...isVerification,isCustomerVerification:e.target.checked})}}

                  />
                  <label htmlFor="CustomerVerification">
                    Customer Verification
                  </label>
                </ListWrapper>

                {/* <ListWrapper className={styles.list}>
                  <input
                    type="checkbox"
                    id="FinancialStatementVerification"
                    name="Financial statement Verification"
                    value="Financial statement Verification"
                  />
                  <label htmlFor="FinancialStatementVerification">
                    Financial statement Verification
                  </label>
                </ListWrapper> */}

                <ListWrapper className={styles.list}>
                  <input
                    type="checkbox"
                    id="AddressVerification"
                    name="Address Verification"
                     checked={isVerification?.isAddressVerification}
                    onChange={(e)=>{setIsVerification({...isVerification,isAddressVerification:e.target.checked})}}
                  />
                  <label htmlFor="AddressVerification">
                    Address Verification
                  </label>
                </ListWrapper>
              </div>

              <div>
                <IncomeCardHead>Source of income</IncomeCardHead>
              </div>

              <div>
                <ListWrapper className={styles.list}>
                  <input
                    type="checkbox"
                    id="Salary"
                    name="Salary"
                    checked={sourceOfInCome?.isSalary}
                  />
                  <label htmlFor="Salary">Salary</label>
                </ListWrapper>

                <ListWrapper className={styles.list}>
                  <input
                    type="checkbox"
                    id="Dividends"
                    name="Dividends"
                     checked={sourceOfInCome?.isDividends}
                  />
                  <label htmlFor="Dividends">Dividends</label>
                </ListWrapper>

                <ListWrapper className={styles.list}>
                  <input
                    type="checkbox"
                    id="BusinessIncome"
                    name="Business income"
                     checked={sourceOfInCome?.isBusinessIncome}
                  />
                  <label htmlFor="BusinessIncome">Business income</label>
                </ListWrapper>

                <ListWrapper className={styles.list}>
                  <input
                    type="checkbox"
                    id="Investments"
                    name="Investments"
                     checked={sourceOfInCome?.isInvestments}
                  />
                  <label htmlFor="Investments">Investments</label>
                </ListWrapper>

                <ListWrapper className={styles.list}>
                  <input
                    type="checkbox"
                    id="Others"
                    name="Others"
                     checked={sourceOfInCome?.isOthers}
                  />
                  <label htmlFor="Others">Others</label>
                </ListWrapper>
              </div>
            </BoxCard>
          </IncomePageWrapperTop>

          <BoxCardTable>
            <IncomeTable>
              <thead>
                <tr>
                  <th>Detail</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Category</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {dashboardList.map((item) => (
                <tr>
                <td>Income Source</td>
                <td>{moment(item?.BookingDateTime).format('DD MMM YYYY')}</td>
                <td>{item?.description}</td>
                <td>{item?.category}</td>
                <td>  £{" "}
                  {(item.amount || 0).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}</td>
              </tr>
                ))}
              </tbody>
            </IncomeTable>
          </BoxCardTable>
        </IncomePageWrapperMain>
      </MainWrapper>
    </OverviewWrapper>)}
    </>
    
  );
};

export default ProofOfFunds;
