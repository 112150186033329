import { Theme } from '@emotion/react';

import { colors } from './color';
import { fontWeights } from './fontWeight';
import { query, media } from './custom-queries';

const theme: Theme = {
   colors,
   fontWeights,
   media: {
      ...media,
      query,
   },
};

export { theme };
