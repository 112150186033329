import styled from "@emotion/styled";
import { Div } from "components/styles";

export const FrameContainer = styled(Div)`
  position: relative;
  height: 100%;
  width: 100%;

  > iframe {
    width: 100%;
    height:100%;
  }
  
`;