/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from 'components/modal/modal';
import { useState } from 'react';
import {
   MainHeading,
   Div,
   Lynk,
   TextGrey,
   HeadingOne,
   GridContainer,
   RightContainer,
} from 'components/styles/index';
import ReactTooltip from 'react-tooltip';
import { Button } from 'components/button/button';
import DatePicker from 'react-datepicker';
import { SideBrandContainer } from 'components/side-brand-container/side-brand-container';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'context/snackbar/snackbar';
import { sendConsentData } from 'api/bank';
import {
   PermissionTitle,
   PermissionTextContent,
   PermissionsStyle,
   PermissionStyle,
   PermsissonContentStyle,
} from './permission.style';

const permissonContent = [
   {
      id: 1,
      title: 'Your accounts',
      brief: 'We will retrieve a list of your current and savings account',
      permissions: ['ReadAccountsDetail'],
   },
   {
      id: 2,
      title: 'Your transactions',
      brief: 'We are pulling your transactions from',
      permissions: ['ReadTransactionsCredits', 'ReadTransactionsDebits', 'ReadTransactionsDetail'],
   },
   {
      id: 4,
      title: 'Your balances',
      brief:
         'For you to use this service E-DocOnline needs to access information from your accounts at your bank.',
      permissions: ['ReadAccountsDetail','ReadBalances'],
   },
];

interface IPermisionAccordion {
   permission: any;
   setTransactionToDateTime: (t) => void;
   setTransactionFromDateTime: (t) => void;
   transactionToDateTime: any;
   transactionFromDateTime: any;
}

export const PermissionAccordion = ({
   permission,
   setTransactionToDateTime,
   setTransactionFromDateTime,
   transactionToDateTime,
   transactionFromDateTime,
}: IPermisionAccordion) => {
   const [permissionToggle, setPermissionToggle] = useState(true);

   return (
      <>
         <PermissionStyle>
            <button
               type="button"
               className={permissionToggle ? 'animate' : ''}
               onClick={() => setPermissionToggle(!permissionToggle)}
            >
               {permissionToggle ? '-' : '+'}
            </button>
            <Div className="d-flex justify-content-between">
               <PermissionTitle>{permission.title}</PermissionTitle>
            </Div>
         </PermissionStyle>

         <PermsissonContentStyle className={`hide ${permissionToggle ? 'animate' : ''}`}>
            <PermissionTextContent className="side">{permission.brief}</PermissionTextContent>
            {permission?.title.includes('transactions') && (
               <Div className="d-flex my-20  align-items-center w-100">
                  <Div className="d-flex ">
                     <Div>
                        
                        <DatePicker
                           dateFormat="yyyy-MM-dd"
                           selected={transactionFromDateTime}
                           onChange={(date) => setTransactionFromDateTime(date)}
                           
                           selectsStart
                           startData={transactionFromDateTime}
                           endDate={transactionToDateTime}
                        />
                     </Div>
                     <Div className="mx-20">to</Div>
                     <Div>
                        <DatePicker
                           dateFormat="yyyy-MM-dd"
                           selected={transactionToDateTime}
                           onChange={(date) => setTransactionToDateTime(date)}
                           maxDate={new Date()}
                           selectsEnd
                           startData={transactionFromDateTime}
                           endDate={transactionToDateTime}
                        />
                     </Div>
                  </Div>
               </Div>
            )}
         </PermsissonContentStyle>
      </>
   );
};

const PermissionModal = ({ visible, handleClose, inputChecked, permissions,houseNumber }) => {
   const updateConsent = JSON.parse(window.localStorage.getItem('updateConsent') || '{}');
   
   let accountRequest ;
   if(Object.keys(updateConsent).length !== 0) {
      accountRequest = JSON.parse(updateConsent.accountRequest);
      
   }
   
   const [transactionFromDateTime, setTransactionFromDateTime] = useState(accountRequest ? new Date(accountRequest.TransactionFromDateTime) : new Date());
   const [transactionToDateTime, setTransactionToDateTime] = useState(accountRequest ? new Date(accountRequest.TransactionToDateTime) : new Date());
   const [isProceeding, setIsProceeding] = useState(false);
   const history = useHistory();
   const { notify } = useSnackbar();

   async function handleProceed() {
      if (!inputChecked) {
         notify({
            message: 'Kindly select a permission to proceed',
            duration: 3000,
            variant: 'info',
         });
         return;
      }

      const data = {
         consentId: 'JHEUE',
         userId: 'J3JEN',
         authcode: 'EJEJEJ',
         consentExpiry: '3J3J3',
         tokenData: {
            accessToken: '3J3N3',
            refreshToken: '4N4N3N',
            start_date: '34J3J3N3',
            end_date: '43I3JEJ',
         },
         accountRequest: {
            Permissions: permissions,
            ExpirationDateTime: '2025-06-11T00:00:00+00:00',
            TransactionFromDateTime: new Date(transactionFromDateTime).toISOString(),
            TransactionToDateTime: new Date(transactionToDateTime).toISOString(),
            ConsentId: '233fa12f-1eee-47e4-9cb5-002363d08aa0',
            Status: 'AwaitingAuthorisation',
            CreationDateTime: '2021-05-26T09:02:42.526Z',
            StatusUpdateDateTime: '2021-05-26T09:02:42.526Z',
         },
         risk: {},
         links: {
            Self:
               'https://sandbox.hsbc.com/psd2/obie/v3.1/account-access-consents/233fa12f-1eee-47e4-9cb5-002363d08aa0',
         },
         meta: {},
      };

      const consentObj = {
         consentType: "CREATE",
         companyNumber:houseNumber,
         consent: {
            Data: {
               Permissions : permissions,
               "ExpirationDateTime": new Date(transactionToDateTime).toISOString(),
               "TransactionFromDateTime": new Date(transactionFromDateTime).toISOString(),
               "TransactionToDateTime": new Date(transactionToDateTime).toISOString()
            }
         }
      }
      
      window.localStorage.setItem('consentObj', JSON.stringify(consentObj));
      history.push('/bank-selection');
      // setIsProceeding(true);
      // const result = await sendConsentData(data);
      // if (result.ok) {
      //    setIsProceeding(false);
      //    history.push('/bank-selection');
      //    return;
      // }

      // setIsProceeding(false);
   }

   return (
      // <Modal width="65vw" height="85vh" visible={visible} handleClose={handleClose}>
         <GridContainer>
            <SideBrandContainer />
            <RightContainer>
               <MainHeading className="purple center">E- DocOnline</MainHeading>
               <HeadingOne className="center purple regular">
                  Needs your permission to read
               </HeadingOne>

               <Div className="font-regular mt-20">Your personal information</Div>

               <PermissionsStyle>
                  {permissonContent.map((permission) => (
                     <>
                        <PermissionAccordion
                           transactionFromDateTime={transactionFromDateTime}
                           transactionToDateTime={transactionToDateTime}
                           setTransactionFromDateTime={setTransactionFromDateTime}
                           setTransactionToDateTime={setTransactionToDateTime}
                           permission={permission}
                        />
                     </>
                  ))}
               </PermissionsStyle>

               <Div className="mt-80 text-center text-grey">
                  <p
                     data-tip="Your account will be accessed on a recurring basis only during your consent period (90 days)"
                     data-for="text"
                  >
                     Your account(s) will be accessed on a recurring basis
                  </p>
               </Div>
               <ReactTooltip id="text" type="error" className="tooltip" arrowColor="#fff" />
               <Div className="text-center w-60 mt-30 flex-row">
                  <Button type="button" variant="secondary" fullWidth onClick={handleClose}>
                     Cancel
                  </Button>
                  <Div className="ml-20" />
                  <Button
                     variant="primary"
                     type="button"
                     fullWidth
                     onClick={handleProceed}
                     loading={isProceeding}
                     disabled={isProceeding}
                  >
                     Allow
                  </Button>
               </Div>
               <TextGrey>
                  <Div className="text-center w-80 mt-40 font-15">
                     By clicking &rdquo;Allow&rdquo; you are permitting E-DocOnline to access
                     account Information from your bank account and share it with E-DocOnline. You
                     also agree to our
                     <Lynk to="https://s3.af-south-1.amazonaws.com/e-doconline.ng/documents/E-Doc+Ng+Terms+%26+Conditions.pdf"> End User Terms of use</Lynk> and
                     <Lynk to="https://s3.af-south-1.amazonaws.com/e-doconline.ng/documents/E-Doc+ng+Privacy+Policy.pdf">Privacy Policy</Lynk>
                  </Div>
               </TextGrey>
            </RightContainer>
         </GridContainer>
      // </Modal>
   );
};

export { PermissionModal };
