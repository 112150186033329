import Logo from 'assets/images/logo.png';
import { Lynk } from 'components/styles/index';
import { useLocation } from 'react-router-dom';
import { Nav, BrandLogo, NavText } from './navbar.style';

const Navbar = () => {
   const location = useLocation();
   return (
      <Nav className="flex-row justify-content-between">
         <BrandLogo src={Logo} alt="brand-logo" />
         {location.pathname === '/login' && (
            <NavText>
               I don&apos;t have an account.{' '}
               <Lynk to="/register" className="text_orenge">
                  Create Account
               </Lynk>
            </NavText>
         )}
      </Nav>
   );
};

export { Navbar };
