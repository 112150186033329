import styled from '@emotion/styled';
import { Div } from 'components/styles';

export const InputWrapper = styled(Div)`
   display: grid;
   grid-template-columns: 1fr;
   width: 100%;
   margin-bottom: 1.5rem;

   &.col-2 {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;
   }
`;
