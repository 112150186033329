import React from 'react';
import styled from '@emotion/styled/macro';

interface ButtonLoaderProps {
   variant?: 'primary' | 'secondary';
}

const WrapperDiv = styled.div<ButtonLoaderProps>`
   @keyframes scaling {
      0%,
      100% {
         transform: scale(0.2);
      }
      40% {
         transform: scale(0.6);
      }
      80% {
         transform: scale(1);
      }
   }

   .circle {
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      transform: scale(0);
      background-color: currentColor;
      animation: scaling 0.7s ease-in-out infinite;
      display: inline-block;
      margin: 0 0.5rem;
   }

   .circle-one {
      animation-delay: 0s;
   }

   .circle-two {
      animation-delay: 0.2s;
   }

   .circle-three {
      animation-delay: 0.4s;
   }
`;

const ButtonLoader: React.FC<ButtonLoaderProps> = ({ variant, ...remaniningProps }) => (
   <WrapperDiv variant={variant} {...remaniningProps}>
      <span className="circle circle-one" />
      <span className="circle circle-two" />
      <span className="circle circle-three" />
   </WrapperDiv>
);

export { ButtonLoader };
