import React from "react";

const CircularProgress = (props) => {
  let {
    size = 100,
    progressValue = "",
    type = "",
    selectMonth = "",
    selectedEndDate = "",
    trackWidth = 6,
    trackColor = `#DEDEDE`,
    indicatorWidth = 6,
    indicatorCap = `round`,
    labelColor = `#000`,
    fontSize,
    fullProgress
  } = props;

  const center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = fullProgress ? "" :dashArray * ((100 - progressValue * 10) / 100);

  return (
    <>
      {" "}
      <div className="svg-pi-wrapper" style={{ width: size, height: size }}>
        {console.log("type", type)}
        {type !== "longevity" && (
          <svg className="svg-pi" style={{ width: size, height: size }}>
            <circle
              cx={center}
              cy={center}
              fill="transparent"
              r={radius}
              stroke={trackColor}
              strokeWidth={trackWidth}
            />
            <circle
              cx={center}
              cy={center}
              fill="transparent"
              r={radius}
              stroke={props.indicatorColor}
              strokeWidth={indicatorWidth}
              strokeDasharray={dashArray}
              strokeDashoffset={dashOffset}
              strokeLinecap={indicatorCap}
            />
          </svg>
        )}
        <div className="svg-pi-label" style={{ color: labelColor }}>
          <span className="svg-pi-label__progress" style={{ fontSize: fontSize }}>
            {!selectedEndDate
              ? type !== "longevity"
                ? props.progressValue
                : `${props.progressValue} / ${
                    selectedEndDate ? 1 : selectMonth || 12
                  }`
              : "N/A"}
          </span>
        </div>
      </div>
    </>
  );
};

export default CircularProgress;
