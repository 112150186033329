import styled from '@emotion/styled';
import check from 'assets/images/radio-check.svg';

export const AccordionContainer = styled.div`
   height: auto;
`;

export const AccordionsContainer = styled.div`
   height: auto;
   margin: 4rem 0;
`;

export const AccordionTitle = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid ${({ theme }) => theme.colors?.grey02};
   padding: 0 0 10px 0;
   margin-top: 2rem;
   cursor: pointer;
   font-size: 1.6rem;
   font-weight: 500;
   color: ${({ theme }) => theme.colors?.purple};

   button {
      border: 0;
      background-color: transparent;
      padding: 1px 10px;
      align-self: center;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-weight: 500;
      transition: background-color 500ms ease;
      cursor: pointer;

      &:hover,
      &.active {
         background-color: #dddada;
         outline: none;
      }

      &:focus {
         outline: none;
      }

      &.animate {
      }
   }
`;

export const AccordionTextContent = styled.div`
   margin-top: 1rem;
   color: ${({ theme }) => theme.colors?.grey04};
   transition: max-height 300ms ease-in-out;
   /* max-height: 0px; */
   overflow: hidden;
   font-size: 1.5rem;

   &.show {
   }

   &.hide {
      max-height: 0px;
   }
   
   &.animate {
      max-height: 400px;
   }
`;

export const PermsissonContentStyle = styled.div`
   transition: max-height 300ms ease-in-out;
   overflow: hidden;

   &.hide {
      max-height: 0px;
   }

   &.animate {
      max-height: 400px;
   }
`;

export const PermissionStyle = styled.div`
   display: flex;
   align-items: center;
   margin: 1.8rem 0 1rem 0;
   position: relative;

   .checkbox {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      outline: none;
      border: 1px solid ${({ theme }) => theme.colors?.grey06};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      top: 2px;
      margin-left: 10px;

      &:before {
         content: '';
         display: block;
         width: 100%;
         height: 120%;
         margin: 20% auto;
         display: flex;
         justify-content: center;
         align-items: center;
         transition: all 300ms ease-in-out;
      }

      &:checked:before {
         content: url(${check});
         background: ${({ theme }) => theme.colors?.orange01};
         position: relative;
         top: -1px;
      }

      &:checked {
         border-color: ${({ theme }) => theme.colors?.orange01};
         border:transparent !important;
      }
   }

   &.side-drawer {
      align-items: flex-start;
   }

   button {
      z-index: 55;
      position: absolute;
      right: 0;
      top: 0;
      border: 0;
      background-color: transparent;
      padding: 1px 10px;
      align-self: center;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-weight: 500;
      transition: background-color 500ms ease;
      cursor: pointer;

      &:hover,
      &.active {
         background-color: #dddada;
         outline: none;
      }

      &:focus {
         outline: none;
      }
   }
`;

export const PermissionsStyle = styled.div``;

export const PermissionIcon = styled.img`
   width: 20px;
   margin-right: 30px;
`;

export const PermissionTitle = styled.label`
   font-weight: 500;
   font-size: 1.5rem;
   position: relative;
   top: -3px;
`;

export const PermissionTextContent = styled.div`
   color: grey;
   margin: 0px 0px 0px 50px;
   display: flex;
   font-size: 1.45rem;

   &.side-drawer {
      margin: 0px !important;
   }

   &.side {
      margin: 0px !important;
   }

   .side {
      margin: 0px !important;
   }
`;
