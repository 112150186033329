/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
import { SideBrandContainer } from 'components/side-brand-container/side-brand-container';
import {
   OrangeBackground,
   GridContainer,
   MainHeading,
   RightContainer,
   HeadingOne,
   Div,
} from 'components/styles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'context/snackbar/snackbar';
import { Button } from 'components/button/button';
import { Modal } from 'components/modal/modal';
import { getConsents, redirectToBankPage, sendConsentComplete } from 'api/bank';
import { Input } from 'components/input/input';
import { DashboardHeader } from 'components/dashboard-header/dashboard-header';
import { ReactComponent as Zoom } from 'assets/images/zoom.svg';
import { useEffect, useState } from 'react';
import BarclaysIcon from 'assets/images/barclays.png';
import MonzoIcon from 'assets/images/bank-logo.png';
import HSBCIcon from 'assets/images/hsbc.png';
import LLOYDSCIcon from 'assets/images/lloyds.jpg';
import LLOYDSC_BUSINESSIcon from 'assets/images/lloyds_business.png';
import SatanderIcon from 'assets/images/satander.png';
import NatwestIcon from 'assets/images/natwest.png';
import StarlingIcon from 'assets/images/starling.png';
import RevolutIcon from "assets/images/revolut.png";
import { getAllUrlParams } from "utils/functions";
import { SpinnerIcon, BanksStyle, BankStyle } from "./bank-selection.style";
import moment from "moment";

interface IBankData {
  id: any;
  icon?: string;
  bank: string;
  title: string;
}

const options = [
  {
    id: 1,
    name: "United Kingdom",
  },
];

const bankList = [
  {
    id: 1,
    bank: "HSBC",
    icon: HSBCIcon,
    title: "",
  },
  {
    id: 6,
    bank: "Lloyds",
    icon: LLOYDSCIcon,
    title: "",
  },
  {
    id: 7,
    bank: "lloyds_business",
    icon: LLOYDSC_BUSINESSIcon,
    title: "Lloyds Business",
  },
  {
    id: 8,
    bank: "starling",
    icon: StarlingIcon,
    title: "Starling",
  },
  {
    id: 9,
    bank: "revolut",
    icon: RevolutIcon,
    title: "Revolut",
  },
  {
    id: 2,
    bank: "Barclays",
    icon: BarclaysIcon,
    title: "",
  },
  {
    id: 3,
    bank: "Monzo",
    icon: MonzoIcon,
    title: "",
  },
  {
    id: 4,
    bank: "Santander",
    icon: SatanderIcon,
    title: "",
  },
  {
    id: 5,
    bank: "NatWest",
    icon: NatwestIcon,
    title: "",
  },
];

const BankSelection = () => {
   const [modalVisibility, setModalVisibility] = useState(false);
   const [selectAnswer, setSelectedAnswer] = useState<string>('');
   const [showSuccess, setShowSuccess] = useState(false);
   const [banks, setBanks] = useState(bankList);
   const history = useHistory();
   const { notify } = useSnackbar();

   useEffect(() => {
      if(!window.localStorage.getItem("consentObj")) {
         notify({
            message: 'Kindly select a permission to proceed',
            duration: 3000,
            variant: 'info',
         });
         history.push('/permission')
      }
   }); 

   async function handleBankSelection() {
      if (!selectAnswer) {
         notify({
            message: 'Kindly select a bank to proceed',
            duration: 3000,
            variant: 'info',
         });
         return;
      }
      setModalVisibility(true);
      // const data = {
      //    Data: {
      //       Permissions: [
      //          'ReadAccountsDetail',
      //          'ReadBalances',
      //          'ReadBeneficiariesDetail',
      //          'ReadDirectDebits',
      //          'ReadProducts',
      //          'ReadStandingOrdersDetail',
      //          'ReadTransactionsCredits',
      //          'ReadTransactionsDebits',
      //          'ReadTransactionsDetail',
      //          'ReadScheduledPaymentsBasic',
      //          'ReadScheduledPaymentsDetail',
      //          'ReadParty',
      //          'ReadStatementsDetail',
      //          'ReadStatementsBasic',
      //       ],
      //       ExpirationDateTime: '2025-06-11T00:00:00+00:00',
      //       TransactionFromDateTime: '1995-07-15T00:00:00+00:00',
      //       TransactionToDateTime: '2037-12-31T23:59:59+00:00',
      //    },
      //    Risk: {},
      // };
      const obj = JSON.parse(window.localStorage.getItem("consentObj") || '{}');
      
      // const newObj = {...obj,bankName: selectAnswer};
      console.log('obj',obj)
      const data = {
         ...obj, 
         bankName: selectAnswer.toLocaleLowerCase(),
         consent: {
            ...obj.consent,
            Data: {
               ...obj.consent.Data,
               ExpirationDateTime: moment().add(3, 'M').format('YYYY-MM-DD') + 'T23:59:59+00:00',
               // TransactionFromDateTime: '1995-07-15T00:00:00+00:00',
               // TransactionToDateTime: '2037-12-31T23:59:59+00:00',
               TransactionFromDateTime: moment(obj.consent.Data.TransactionFromDateTime).format('YYYY-MM-DD') + 'T00:00:00+00:00',
               TransactionToDateTime: moment(obj.consent.Data.TransactionToDateTime).format('YYYY-MM-DD') + 'T23:59:59+00:00'
            },
            Risk: {}
         }
      };
      const result = await redirectToBankPage(data);
      console.log('result', result)
      if (result.ok) {
         
         const urlSearchParams = new URLSearchParams(result.data.data);
         const qsObj = getAllUrlParams(result.data.data);
         // const resultComplete = await sendConsentComplete(qsObj);
         //window.open(result.data?.data, '_blank');
         window.location.href = result.data?.data;
         setShowSuccess(true);
         window.localStorage.removeItem("consentObj") 
         // setTimeout(() => {
         //    setModalVisibility(false);
         //    history.push('/redirection-success');
         // }, 1500);
      }else {
         if(result.data && result.data.message ) {
            notify({
               message: result.data.message,
               duration: 3000,
               variant: 'error',
            });
         }else {
            notify({
               message: "something went wrong!  ",
               duration: 3000,
               variant: 'error',
            });
         }
         setModalVisibility(false);
      }
      
   }

   function handleSearch(e) {
      const valToLowerCase = e.target.value.toLowerCase().trim();
      const filteredList = banks.filter((list: any) =>
         list.bank.toLowerCase().includes(valToLowerCase),
      );

      if (valToLowerCase === '') {
         setBanks([...bankList]);
      } else {
         setBanks([...filteredList]);
      }
   }

   return (
      <>
      <DashboardHeader />
      <OrangeBackground>
         <Modal visible={modalVisibility} handleClose={() => false}>
            <Div className="flex-column h-100 bg-white">
               <SpinnerIcon />
               {showSuccess && <Div className="font-22 font-bold my-20 text-black">Success !</Div>}
               <Div className="text-grey font-22 font-bold my-20 text-black">
                  You are now being redirected to {selectAnswer}!
               </Div>
            </Div>
         </Modal>
         <GridContainer>
            <SideBrandContainer />
            <RightContainer>
               <MainHeading className="center">Select the Bank</MainHeading>
               <HeadingOne className="center">
                  To Select your bank feed we need to know which bank to connect to.
               </HeadingOne>
               <Div className="d-grid grid-1-2 mt-20">
                  <Input label="" id="country" value="United Kingdom" disabled type="text" />
                  <Input
                     label=""
                     placeholder="Search for a bank"
                     id="search"
                     endAdornment={<Zoom />}
                     onChange={handleSearch}
                  />
               </Div>
               <Div className="font-bold text-black my-30">
                  Please select a bank from the List below
               </Div>

               <BanksStyle>
                  {banks.length < 1
                     ? 'No data'
                     : banks.map((element: IBankData) => {
                          return (
                             <BankStyle id={element.id} key={element.id + element.bank}>
                                <Div className="flex-row font-15 ml-20">
                                   <img
                                      src={element.icon as string}
                                      alt={element.bank}
                                      width="35px"
                                   />
                                    {element?.title ? (
                                   <Div className="ml-20 font-regular">{element?.title}</Div>
                                   ):( 
                                    <Div className="ml-20 font-regular">{element.bank}</Div>
                                   )}
                                </Div>

                                <input
                                   type="radio"
                                   value={element.bank}
                                   name="bank"
                                   className="input"
                                   onChange={() => setSelectedAnswer(element.bank)}
                                />
                             </BankStyle>
                          );
                       })}
               </BanksStyle>
               <Div className="text-center text-grey">
                  Are you sure you have selected the right bank , and wish to continue?
               </Div>
               <Div className="text-center w-60 mt-30 flex-row">
                  <Button
                     type="button"
                     variant="secondary"
                     fullWidth
                     onClick={() => history.goBack()}
                  >
                     Go Back
                  </Button>
                  <Div className="ml-20" />
                  <Button onClick={() => handleBankSelection()} variant="primary" type="button" fullWidth>
                     Proceed
                  </Button>
               </Div>
            </RightContainer>
         </GridContainer>
      </OrangeBackground>
      </>
   );
};

export default BankSelection;
