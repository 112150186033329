import styled from '@emotion/styled';
import { Div } from 'components/styles';

const Nav = styled(Div)`
   height: 10rem;
   background-color: #fff;
   padding: 0 10rem;
   line-height: 7rem;

   ${({theme}) => theme.media?.semiMedium} {
      padding: 0 2rem;
      line-height: 5rem;
      flex-direction: column;
      height: auto;
   }
`;

const BrandLogo = styled.img`
   width: 9%;

   ${({theme}) => theme.media?.semiLarge} {
      max-width: 130px;
      width: 100%;
      padding-top: 1rem;
   }
`;

const NavText = styled.div`
   color: ${({ theme }) => theme.colors?.grey04};
`;

export { Nav, BrandLogo, NavText };
