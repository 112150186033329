import styled from '@emotion/styled';

export const HomePageStyle = styled.div`
   background-image: linear-gradient(${({ theme }) => theme.colors?.orange01} 50%, #f7f7f7 50%);
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   position:relative;
   &:after{
      content: "";
    background: url(https://rstheme.com/products/html/braintech/assets/images/bg/project-bg.png);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-size: cover;
    z-index:1;
    -webkit-animation: slide 20s linear infinite;
   }
`;

export const HomePageContainer = styled.div`
   width: 900px;
   font-size: 1.5rem;
   line-height: 2.6rem;
   position:relative;
   z-index:9;
`;

export const TopHomePageCOntainer = styled.div`
   background-color: #fff;
   padding: 30px;
   height: 400px;
   display: grid;
   margin-bottom: 20px;
   text-align: center;
   border-radius: 30px;
   box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);
`;

export const LeftHomePageContainer = styled.div`
   background-color: #fff;
   padding: 30px;
   width: 50%;
   text-align: center;
   border-radius: 30px;
   box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);
   position: relative;
    z-index: 9;
`;

export const RightHomePageContainer = styled.div`
   background-color: #fff;
   margin-left: 20px;
   padding: 30px;
   width: 50%;
   border-radius: 30px;
   box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);
   position: relative;
    z-index: 9;
    .contact_icon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);
      border-radius: 50%;
  }
    h3{
       span{
          color:#ED7D32;
          font-size:20px;
       }
    }
`;

export const CountDownContainer = styled.div`
   color: #fff;
   font-size: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   height: 100%;
   
   .text {
      font-size: 50px;
      margin-bottom: 70px;
      font-weight: 600;
      text-transform: uppercase;
      padding-bottom: 15px;
      position: relative;
      .text_sub{
         color:#ED7D32;
      }
      &:after{
         content: "";
         position: absolute;
         width: 100px;
         background: #ED7D32;
         right: 0;
         height: 3px;
         bottom: 0;
         border-radius: 10px;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }

   .timer {
      .days,
      .hours,
      .minutes,
      .seconds {
         margin: 0 3px;
         display: inline-flex;
         font-size: 35px;
         background-color: #ED7D32;
         width: 60px;
         height: 60px;
         text-align: center;
         border-radius: 5px;
         color: #fff;
         font-weight: 500;
         align-items: center;
         justify-content: center;
      }

      .bottom-text {
         display: block;
         font-size: 14px;
         font-weight: 600;
         text-align: center;
         margin-top: 20px;         
         letter-spacing: 1.2px;
         text-transform:uppercase;
      }
   }
`;
