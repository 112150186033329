/* eslint-disable @typescript-eslint/no-explicit-any */
import { Http } from 'api';
import { ApiResponse } from 'apisauce';
import { ILoginData } from 'pages/auth/login';
import { IRegisterData } from 'pages/auth/register';
// import { IForgotPasswordData } from 'pages/forgot-password/forgot-password';
// import { IPasswordResetData } from 'pages/password-reset/password-reset';

export const loginApi = async (payload: ILoginData): Promise<ApiResponse<any>> => {
   return Http.post(`/auth/login`, payload);
};

export const registerApi = async (payload: IRegisterData): Promise<ApiResponse<any>> => {
   return Http.post(`/user/register`, payload);
};

export const activateAccountApi = async (payload: string): Promise<ApiResponse<any>> => {
   return Http.put(`/auth/activateUser/${payload}`);
};

export const resetPasswordApi = async (token, payload): Promise<ApiResponse<any>> => {
   return Http.put(`/auth/password/reset?token=${token}`, payload);
};

export const requestPasswordResetApi = async (payload): Promise<ApiResponse<any>> => {
   return Http.post(`/auth/password/reset`, payload);
};

export const verifyPasswordToken = async (payload: string): Promise<ApiResponse<any>> => {
   return Http.get(`/auth/password/reset?token=${payload}`);
};

export const sendOtp = async (payload): Promise<ApiResponse<any>> => {
   return Http.post(`/auth/sendOtp`, payload);
};

export const resetPassword = async (token ,payload): Promise<ApiResponse<any>> => {
   return Http.post(`/auth/resetPassword`, payload);
};