import { FC, useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Select from 'react-select';
import { Button } from 'components/button/button';
import { Checkbox } from 'components/checkbox/checkbox';
import { useForm, Controller } from 'react-hook-form';
import { object, SchemaOf, string } from 'yup';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'context/snackbar/snackbar';
import InputRange from 'react-input-range';
import { useAuth } from 'context/auth';
import { DashboardHeader } from 'components/dashboard-header/dashboard-header';
import { getBusinessPartner, getPermission, getProduct, sendBusiness } from 'api/bank';
import { AuthStyle, Form, InputWrapper, FormContainer, HeadingOne, HeadingTwo, Label, InputRang } from './product.style';
import { isTemplateExpression } from 'typescript';
import { Div } from 'components/styles';


export interface ILoginData {
   businessId: string;
}

export interface PermissionOption {
   id: string;
   name: string;
}
const options = [
   { value: 'chocolate', label: 'Chocolate' },
   { value: 'strawberry', label: 'Strawberry' },
   { value: 'vanilla', label: 'Vanilla' }
]

const schema = yup.object().shape({
   businessId: yup.object().shape({
      label: yup.string().required("Is required"), 
      value: yup.string().required("Is required")
    })
});

const proptions = [
   {
       value: 2,
       label: "Balance"
   },
   {
       value: 3,
       label: "Transactions"
   },
   {
       value: 4,
       label: "Statements"
   }
]
const Product: FC = () => {
   // const schema: SchemaOf<ILoginData> = useMemo(
   //    () =>
   //       object().shape({
   //          businessId: string().required('Select business')
   //       }),
   //    [],
   // );

   const { notify } = useSnackbar();
   const history = useHistory();
   const [loading, setLoading] = useState(false);
   const [checkboxValue, setCheckboxValue] = useState(false);
   const [productsOption, setProductsOption] = useState<any[]>([])
   const [partners, setPartners] = useState<any[]>([])
   const [products, setProducts] = useState<any[]>([])
   const [rang, setRang] = useState(30 as any)
   const [permissions, setPermissions] = useState<any[]>([{level: "1", name: "read", label: "Read"}, {level: "2", name: "download", label: "View & Download"}])
   const [permissionData, setPermissionData] = useState<any[]>([])
   const { register, handleSubmit,setValue,getValues, control, formState: {isDirty, isValid, errors}} = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema)
   });

   useEffect(() => {
      
      (async () => {
         const result = await getProduct();
         if (result.status === 200) {
            setProductsOption(result.data.data.map(item => {
               return {...item, label: item.name, value: item.id}
            }));
         }
      })();
      (async () => {
         const result = await getPermission();
         if (result.status === 200) {
            setPermissions(result.data.data.map(item => ({...item, label: item.name})))
         }
      })();
      (async () => {
         const result = await getBusinessPartner();
         if (result.status === 200) {
            setPartners(result.data.map(item => {
               return {...item, value:item.id, label: item.companyName}
            }));
         }
      })();
      
   }, [])

   const { setAuthenticatedUser } = useAuth();

   async function onSubmit(payload) {
      // remove after check page
      setLoading(true);
      let actualpr : any = [];
      permissionData.forEach(p => {
         let obj : any;
         products.forEach((per) => {
            obj = {
               productId: per.value,
               permissionLevel: p.level
            };
            actualpr.push(obj);
         });
         
      })
      const businessObj = {
         businessId: parseInt(payload?.businessId?.value),
         duration : rang,
         products: [...actualpr]
      }
      
      const result = await sendBusiness(businessObj);
      
      setLoading(false);
      if (result.ok) {
         notify({ message: 'success', variant: 'success' });
         setLoading(false);
         history.push('/product');
         return;
      }else {
         notify({
            message: result?.data?.error?.message || "Something went wrong!",
            variant: 'error',
         });
         
      }
      
   }
   const handleCheckBox = (e, value) => {
      const prevPer = [...permissionData];
      const pl = permissions.filter(p =>  p.level == value);
      const index = permissionData.findIndex(pr => pr.level == value)
      
      if(e.target.checked) {
         if(index === -1) {
            setPermissionData(prev => [...prev, ...pl])
         }
      }else {
         prevPer.splice(index, 1);
         setPermissionData(prevPer);
      }
   }
   
   return (
      <>
         <DashboardHeader />
         <AuthStyle className="flex-row">
            <FormContainer className="login">
            <Div className="font-regular font-24">Select Partner</Div>
               <Div className='info font-14 text-black'>
                  <p>You are about to give access to your financial data using E-DocOnline secure service.</p>
                  <p>This means this person/ organisation will have access to your account information (balance, transaction, statements).</p>
                  <p>If you no longer want this person/ organisation to have access, you can revoke this anytime from the View Plan page.</p>
               </Div>
               <Form className='mt-2' onSubmit={handleSubmit(onSubmit)}>
                  <InputWrapper>
                     <Label>Business Partner Name</Label>
                     <Controller
                        as={Select}
                        name="businessId"
                        options={partners}
                        control={control}
                     />
                    
                  </InputWrapper>
                  <InputWrapper>
                     <Label>Select Product</Label>
                     
                     {/* <Controller
                        as={Select}
                        name="product"
                        options={proptions}
                        isMulti
                        control={control}
                        onChange={(value) => {
                           setValue('product', value);
                           setProducts(value);
                        }}
                     /> */}
                     <Select 
                        name="product"
                        id="product"
                        options={productsOption}
                        onChange={(value) => {
                          
                           setProducts(value as any)
                        }}
                        isMulti={true}
                     />
                  </InputWrapper>
                  
                  <InputWrapper>
                        <Label>Duration (Days) </Label>
                        {/* <Controller
                           as={InputRange}
                           maxValue={90}
                           minValue={0}
                           value={rang}
                           control={control}
                        /> */}

                        <InputRange
                           ref={register}
                           maxValue={90}
                           minValue={0}
                           name="rang"
                           value={rang}
                           onChange={value => setRang(value)} 
                        />
                        
                  </InputWrapper>
                  <InputWrapper className="mt-20">
                     {permissions.length > 0 && permissions.map((item) => {
                        
                           return (
                              <Checkbox
                              name={item.name}
                              value={item.level}
                              onChange={(e) => {
                                 handleCheckBox(e, e.target.value);
                              }}
                              
                           >
                              {item.label}
                           </Checkbox>)}
                        )}
                     {/* <Checkbox
                        name="terms"
                        onChange={() => setCheckboxValue(!checkboxValue)}
                        checked={checkboxValue}
                     >
                        View Only
                     </Checkbox>
                     <Checkbox
                        name="terms"
                        onChange={() => setCheckboxValue(!checkboxValue)}
                        checked={checkboxValue}
                     >
                        View and Download
                     </Checkbox>
                     <Checkbox
                        name="terms"
                        onChange={() => setCheckboxValue(!checkboxValue)}
                        checked={checkboxValue}
                     >
                        Transaction
                     </Checkbox>
                     <Checkbox
                        name="terms"
                        onChange={() => setCheckboxValue(!checkboxValue)}
                        checked={checkboxValue}
                     >
                        Account Statement
                     </Checkbox> */}
                  </InputWrapper>
                  <InputWrapper className="mt-20">
                     <Button fullWidth variant="primary" loading={loading} disabled={!isValid || rang == 0 || (permissionData.length == 0) || products.length == 0}>
                        Submit
                     </Button>
                  </InputWrapper>
               </Form>
            </FormContainer>
         </AuthStyle>
      </>
   );
};

export default Product;
