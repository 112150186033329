/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'context/snackbar/snackbar';
import { getCookie } from 'utils/storage';

const BASE_URL = `https://connect.e-doconline.co.uk/api/v1`;
// const BASE_URL = `http://localhost:3006/api/v1`;

const Http = axios.create({
   baseURL: `${BASE_URL}`,
   headers: {
      'Content-Type': 'application/json',
   },
});

interface ResponseError extends AxiosError {
   data: Record<string, unknown>;
   ok: boolean;
   config: any;
   code:any;
}

interface ResponseSuccess extends AxiosResponse {
   data: any;
   ok: boolean;
   config: any;
   code: any
}

const requestHandler = (request) => {
   request.headers.Authorization = `Bearer ${getCookie()}`;
   return request;
};

const responseHandler = (res) => {
   
   const response: ResponseSuccess = { ...res };
   response.ok = response.data.codes === 200 || response.data.code === 500 ;
   // response.data = response?.data?.data;
   delete response.request;
   delete response.config;
   if (res.status === 401) {
      window.location = '/login' as any;
   }
   if (res.code === 401) {
      window.location = '/login' as any;
   }
   return response;
};

const errorHandler = (error) => {
   const responseError: ResponseError = { ...error };
   responseError.data = error.response?.data;
   responseError.ok = false;
   
   if (responseError.response?.status === 401) {
      
      responseError.data = {
         message: 'User session expired.',
      };
      window.location = '/login' as any;
   }

   if (error.response === undefined) {
      responseError.data = {
         message: 'Network Error!. Kindly Try again',
      };
   }
   delete responseError.request;
   delete responseError.response;
   delete responseError.config;
   return responseError;
};

Http.interceptors.request.use(
   (request) => requestHandler(request),
   (error) => errorHandler(error),
);

Http.interceptors.response.use(
   (response) => responseHandler(response),
   (error) => errorHandler(error),
);

export { Http };
export {BASE_URL};
