import styled from "@emotion/styled";
import { Input } from "components/input/input";
import { Div, Span } from "components/styles";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";

export const AuthStyle = styled(Div)`
  position: relative;
  height: 100%;
  width: 100%;

  ${({ theme }) => theme.media?.semiMedium} {
    flex-direction: column;
    padding: 0 2rem;
  }
`;

export const AffordabilityPage = styled(Div)`
  background: #2d2d2d;
`;

export const PageContainer = styled(Div)`
  margin: 0 auto;
  max-width: 1360px;
  padding: 0 40px;

  ${({ theme }) => theme.media?.semiLarge} {
    padding: 0 15px;
  }
`;

export const PageHeader = styled(Div)`
  padding: 30px 0;
`;

export const HeroSection = styled(Div)`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.media?.semiLarge} {
    padding: 10px 0 50px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const HeroTitle = styled(Div)`
  color: #fff;
  font-weight: 900;
  font-size: 48px;
  line-height: 58px;
  flex:2;

  ${({ theme }) => theme.media?.lteMedium} {
    font-size: 38px;
    line-height: 46px;
  }

  ${({ theme }) => theme.media?.semiLarge} {
    font-size: 34px;
    line-height: 44px;
    width: 100%;
    margin-right: 0;

    > br {
      display: none;
    }
  }
`;

export const HeroSubTitle = styled(Span)`
  display: block;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-top: 12px;

  ${({ theme }) => theme.media?.semiLarge} {
    font-size: 20px;

    > br {
      display: none;
    }
  }
`;

export const HeroRightSection = styled(Div)`

  flex:3;
  > img {
    width: 100%;
  }

  ${({ theme }) => theme.media?.semiLarge} {
    width: 100%;

    > img {
      / max-width: 400px; /
      max-width: auto;
      display: block;
      margin: 40px auto 0;
    }
  }
`;

export const TravelInfo = styled.form`
  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .affordability-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    // background: #fc5719;
    border-radius: 12px;
    padding: 10px 18px;
    display: inline-block;
    color: #fff;
    border: 0;
    overflow: hidden;
    z-index: 1;
  
    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: -100%;
      left: 0;
      transition: 0.2s ease-in-out all;
      -webkit-transition: 0.2s ease-in-out all;
      -moz-transition: 0.2s ease-in-out all;
      background-color: #ec774b;
      z-index: -1;
    }
  }

  ${({ theme }) => theme.media?.semiLarge} {
    > div {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const TravelInfoBlock = styled(Div)`
  display: flex;
  flex-direction: column;
`;

export const InfoBlock = styled(Div)`
  > input {
    background: #212120;
    border: 0;
    border-radius: 12px;
    padding: 0px 17px;
    color: #a3a3a3;
    width: 100%;
    height: 60px !important;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const DateInput = styled(Input)`
  > label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fc5719;
    display: block;
    margin-bottom: 6px;
  }
`;

export const InfoBlockWrapper = styled(Div)`
  display: flex;
  align-items: center;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const DateInfoBox = styled(Div)`
  width: 205px;
  margin-right: 32px;

  &:not(:last-child) {
    margin-right: 32px;
  }

  @media (max-width: 485px) {
    width: 170px;
  }

  @media (max-width: 425px) {
    width: 100%;
    margin-right: 0 !important;
  }
`;

export const InfoLabel = styled(Span)`
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  display: block;
  margin-bottom: 20px;

  ${({ theme }) => theme.media?.semiLarge} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const TravelInfoBox = styled(Div)`
  margin-right: 30px;
  width: 310px;
  > .InputWrapper,
  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fc5719;
    display: block;
    margin-bottom: 6px;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const HotelTypeInfoBox = styled(Div)`
  width: 205px;

  > .InputWrapper,
  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fc5719;
    display: block;
    margin-bottom: 6px;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  ${(props) =>
    props.disabled
      ? css`
          background: #f48e67f7;
        `
      : css`
          background: #fc5719;
        `};

  // background: #fc5719;
  border-radius: 12px;
  padding: 10px 18px;
  display: inline-block;
  color: #fff;
  border: 0;
  overflow: hidden;
  z-index: 1;

  &:before,
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    transition: 0.2s ease-in-out all;
    -webkit-transition: 0.2s ease-in-out all;
    -moz-transition: 0.2s ease-in-out all;
    background-color: #ec774b;
    z-index: -1;
  }
`;

export const Disclaimer = styled(Div)`
  margin-top: 50px;
`;

export const DisclaimerTitle = styled(Span)`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #a3a3a3;
  margin-bottom: 7px;

  > img {
    display: inline-block;
    margin-right: 6px;
  }
`;

export const DisclaimerDescription = styled(Span)`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
  padding-bottom: 33px;
`;

export const FooterSection = styled(Div)`
  background: #212120;
  padding: 80px 40px 40px;

  ${({ theme }) => theme.media?.semiLarge} {
  }
`;

export const FooterContent = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${({ theme }) => theme.media?.semiLarge} {
    flex-direction: column;
  }
`;

export const FooterColumn = styled(Div)`
  width: 32%;
  display: flex;
  flex-direction: column;

  > img {
    width: min-content;
  }

  ${({ theme }) => theme.media?.semiLarge} {
    width: 100%;
  }
`;

export const AboutInfo = styled(Span)`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
  margin-top: 30px;
  margin-bottom: 0;
`;

export const FooterLinks = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media?.semiLarge} {
    margin: 30px 0;
    justify-content: flex-start;
  }
`;

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  display: inline-block;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #636363;

  ${({ theme }) => theme.media?.semiLarge} {
    margin: 0 10px 0 0;
  }
`;

export const FooterSocialLinks = styled.ul`
  display: flex;
  margin-bottom: 38px;
  justify-content: flex-end;

  ${({ theme }) => theme.media?.semiLarge} {
    justify-content: flex-start;
  }

  > li {
    margin: 0 16px 0 0;
  }
`;

export const FooterSocialLink = styled(Link)`
  background: #2d2d2d;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 12px;

  &:hover {
    background: #454343;
  }

  ${({ theme }) => theme.media?.semiLarge} {
    margin: 0 10px 0 0;
  }
`;

export const PoweredBy = styled(Span)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #a3a3a3;
  margin-bottom: 13px;

  ${({ theme }) => theme.media?.semiLarge} {
    text-align: left;
  }
`;

export const ReservedRight = styled(Span)`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
  text-align: right;
  margin-bottom: 0;

  ${({ theme }) => theme.media?.semiLarge} {
    text-align: left;
  }
`;
