/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer, createContext, useContext } from 'react';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { clearStorage, getCookie, getUserInfoFromStorage } from 'utils/storage';

interface AuthProps {
   currentUser?: Record<string, any>;
}

const initialState = {
   currentUser: {},
};

interface Action {
   type: 'SET_CURRENT_USER' | 'CLEAR_CURRENT_USER';
   payload: AuthProps;
}

const AuthContext = createContext<{
   state: AuthProps;
   dispatch: React.Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

type authProviderProps = { children: React.ReactNode };

function authReducer(state: AuthProps, action: Action) {
   switch (action.type) {
      case 'SET_CURRENT_USER': {
         return {
            ...state,
            currentUser: action.payload,
         };
      }
      case 'CLEAR_CURRENT_USER': {
         return {
            ...state,
            currentUser: {},
         };
      }
      default: {
         throw new Error(`Unhandled action type: ${action.type}`);
      }
   }
}

function AuthContextProvider({ children }: authProviderProps) {
   const [state, dispatch] = useReducer(authReducer, initialState);

   return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
}

const setCurrentUser = (payload: Record<string, unknown>) => ({
   type: 'SET_CURRENT_USER',
   payload,
});

const clearCurrentUser = () => ({
   type: 'CLEAR_CURRENT_USER',
});

function useAuth() {
   const context = useContext(AuthContext);
   const { state, dispatch } = context;
  
   const history = useHistory();
   const userData = getUserInfoFromStorage();
   const { currentUser } = state;

   const isTokenValid = (token: string) => {
      if (token) {
         const decodedToken = jwtDecode(token) as Record<string, string | number>;
         return Date.now() <= (decodedToken.exp as number) * 1000;
      }
      return false;
   };

   function checkSessionValidity(): boolean {
      try {
         const token = getCookie();
         // if (!token || !userData) {
         //    return false;
         // }
         if (!token) {
            return false;
         }
        
         // return isTokenValid(token);
         return true;
      } catch (err) {
         return false;
      }
   }

   function logout() {
      clearStorage();
      dispatch(clearCurrentUser());
      history.replace('/login?redirected=true');
   }

   function setAuthenticatedUser(result) {
      dispatch(setCurrentUser(result));
   }

   function initUserSession() {
      if (checkSessionValidity()) {
         setAuthenticatedUser(userData?.user_details);
      }
   }

   if (context === undefined) {
      throw new Error('useSnackbar must be used within a SnackbarProvider');
   }

   return {
      currentUser,
      checkSessionValidity,
      initUserSession,
      setAuthenticatedUser,
      logout,
   };
}
export { AuthContextProvider, useAuth };
