/* eslint-disable @typescript-eslint/no-explicit-any */
import { Http } from "api";
import { ApiResponse } from "apisauce";

export const redirectToBankPage = async (
  payload
): Promise<ApiResponse<any>> => {
  return Http.post(`orchestrator/initiateConsent`, payload, {
    headers: {
      "x-fapi-financial-id": "test",
      "x-fapi-interaction-id": "ab49c02c-623a-484c-bbd2-0c0e52c7c58b",
    },
  });
};
export const getAffordability = async (
  payload: any
): Promise<ApiResponse<any>> => {
  console.log("payload", payload);
  return Http.post(`/affordability`, payload);
  // return new Promise<any>((resolve: any, reject: any) => {
  //   resolve({
  //     data: [
  //       {
  //         destination: "MAD",
  //         flightFare: 40.12,
  //         roundTripFare: 40.12,
  //         totalAirFare: 80.24,
  //         mealsPerDay: 110,
  //         mealsTotal: 0,
  //         hotelRate: 118.75,
  //         totalCost: 198.99,
  //         affordability: "Afforable",
  //       },
  //       {
  //         destination: "CDG",
  //         flightFare: 58.12,
  //         roundTripFare: 58.12,
  //         totalAirFare: 116.24,
  //         mealsPerDay: 150,
  //         mealsTotal: 0,
  //         hotelRate: 118.75,
  //         totalCost: 234.99,
  //         affordability: "Manageable",
  //       },
  //       {
  //         destination: "ROM",
  //         flightFare: 59.72,
  //         roundTripFare: 59.72,
  //         totalAirFare: 119.44,
  //         mealsPerDay: 80,
  //         mealsTotal: 0,
  //         hotelRate: 118.75,
  //         totalCost: 238.19,
  //         affordability: "Insufficient",
  //       },
  //     ],
  //     success: true,
  //     code: 200,
  //     id: "93623377-1719-4bf5-82a8-eba7ce7e791d",
  //     message: "Success",
  //   });
  // });
};

export const getSourceCities = () => {
  return new Promise<any>((resolve: any, reject: any) => {
    resolve({
      data: [
        {
          value: "LON",
          label: "London",
        }
      ],
      status: 200,
    });
  });
};
