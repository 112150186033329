/* eslint-disable react/require-default-props */
import { useAuth } from 'context/auth';
import { useEffect, useState } from 'react';
import { Div } from 'components/styles';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { PageLoader } from 'components/page-loader/page-loader';
// import { getUserInfoFromStorage } from 'utils/storage';

interface IProtectedRouteProps extends RouteProps {
   notProtected?: boolean;
}

const ProtectedRoute = ({ notProtected, ...rest }: IProtectedRouteProps) => {
   const [isCheckingAuth, setIsCheckingAuth] = useState(false);
   const { checkSessionValidity } = useAuth();
   const isAuthenticated = checkSessionValidity();
   
   // useEffect(() => {
   //    setIsCheckingAuth(false);
   // }, [isAuthenticated]);
   
   if (isCheckingAuth) {
      return (
         <Div className="d-flex align-items-center justify-content-center vh-100">
            <PageLoader />
         </Div>
      );
   }
   
   
   
   if (!isAuthenticated && !notProtected) {
      return <Redirect to={{ pathname: '/login' }} />;
   }

   // if (!isAuthenticated && notProtected) {
   //    return <Redirect to={{ pathname: '/permission' }} />;
   // }

   return <Route {...rest} />;
};

export { ProtectedRoute };
