/**
 * There are two type of constants exported here:
 * - simple media query strings (to use, for example, with window.matchMedia())
 * - media query strings prefixed with @media (for easier use on template literals)
 *
 * You might ask why we use the `em` unit instead of `rem` or `px` for these and the
 * answer for that is in this article: https://zellwk.com/blog/media-query-units/
 * In short, it's the best way to ensure proper pixel scaling across browsers,
 * mainly Safari.
 *
 * The default font-size for the browsers is 16px, so all of these values in ems are
 * based on that.
 */

/* Simple */

const query = {
   small: '(max-width: 1023px)', // <= 1023px
   mobile: '(max-width: 481px)',
   semiMedium: '(max-width: 600px)',
   medium: '(min-width: 481px) and (max-width: 767px)', // >= 767px && <= 481px
   semiLarge: '(max-width: 876px)', // <= 876px
   large: '(min-width: 1366px) and (max-width: 1919px)', // >= 1366px && <= 1919px
   extraLarge: '(min-width: 1920px)', // >= 1920px
   gteMedium: '(min-width: 1024px)', // >= 1024px
   gteLarge: '(min-width: 1366px)', // >= 1366px
   lteMedium: '(max-width: 1365px)', // <= 1365px
   lteLarge: '(max-width: 1919px)', // <= 1919px
};

const media = {
   small: `@media ${query.small}`, // <= 1023px
   mobile: `@media ${query.mobile}`,
   medium: `@media ${query.medium}`, // >= 1024px && <= 1365px
   semiMedium: `@media ${query.semiMedium}`, // >= 1024px && <= 1365px
   semiLarge: `@media ${query.semiLarge}`, // <= 876px
   large: `@media ${query.large}`, // >= 1366px && <= 1919px
   extraLarge: `@media ${query.extraLarge}`, // >= 1920px
   gteMedium: `@media ${query.gteMedium}`, // >= 1024px
   gteLarge: `@media ${query.gteLarge}`, // >= 1366px
   lteMedium: `@media ${query.lteMedium}`, // <= 1365px
   lteLarge: `@media ${query.lteLarge}`, // <= 1919px
};

export { query, media };
