const colors = {
   white: '#fff',
   grey01: '#f6f6f6',
   grey02: '#D8D8D8',
   grey03: '#999',
   grey04: '#666',
   grey05: '#e8e8e8',
   grey06: '#727384',
   night: '#333',
   black: '#000',
   black01: '#1e1c24',
   black02: '#2d2d2d',
   shadowBlack: '#0000000f',
   redError: '#ed1c24',
   honey: '#b79674',
   transparent: 'transparent',
   orange01: '#ED7D32',
   orange02: '#f2912eb3',
   green01: 'limegreen',
   purple: '#52489C',
   black03: '#6e6e6e;',
};

export { colors };
