import styled from '@emotion/styled';

export const DashboardStyle = styled.div`
   background-color: #fff;
   height: 100%;
   width: 100%;
   margin: 0 auto;
   
   ${({theme}) => theme.media?.semiMedium} {
      padding-bottom: 30px;
   }
`;

export const DashboardContainer = styled.div`
   width: 80%;
   margin: 0 auto;
`;

export const BankDetailsCardStyle = styled.div`
   background: #fff;
   display: flex;
   font-size: 1.5rem;
   border-top: 1px solid #eaeaea;
   box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);
   ${({theme}) => theme.media?.semiMedium} {
      flex-direction: column;
      gap: 30px;
   }
   
   button {
      padding: 7px 15px;
      border-radius: 3px;
   }
`;

export const BankCardStyle = styled.div`
   max-width: 400px;
   width: 400px;
   padding: 40px;
   
   ${({theme}) => theme.media?.semiMedium} {
      max-width: 100%;
      width: 100%;
      border: 1px solid #eaeaea;
   }

   &:nth-child(2) {
      border-right: 1px solid #eaeaea;
      border-left: 1px solid #eaeaea;

      ${({theme}) => theme.media?.semiMedium} {
         border: 1px solid #eaeaea;
      }
   }
`;

export const BankDetailsStyle = styled.div``;

export const DetailsBottomStyle = styled.div`
   border-top: 1px solid #eaeaea;
   border-bottom: 1px solid #eaeaea;
   margin: 30px 0;
`;
