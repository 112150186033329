import { FC, useMemo, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import { Button } from "components/button/button";
import { Checkbox } from "components/checkbox/checkbox";
import { useForm, Controller } from "react-hook-form";
import { object, SchemaOf, string } from "yup";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "context/snackbar/snackbar";
import InputRange from "react-input-range";
import { useAuth } from "context/auth";
import { DashboardHeader } from "components/dashboard-header/dashboard-header";
import { isTemplateExpression } from "typescript";
import { Div } from "components/styles";
import { getAccount, sendBusiness } from "api/bank";
import { useLocation } from "react-router-dom";
import AffordabilityLevel from "assets/images/AffordabilityLevel.svg";
import Affordable from "assets/images/Affordable.svg";
import AirFares from "assets/images/AirFares.svg";
import Cost from "assets/images/Cost.svg";
import Destination from "assets/images/Destination.svg";
import HotelPerDay from "assets/images/HotelPerDay.svg";
import Insufficient from "assets/images/Insufficient.svg";
import Logo from "assets/images/logo-white.png";
import Manageable from "assets/images/Manageable.svg";
import Meals from "assets/images/Meals.svg";
import twitter_orange from "assets/images/twitter_orange.svg";
import instagram_orange from "assets/images/instagram_orange.svg";
import google_orange from "assets/images/google_orange.svg";
import linkedin_orange from "assets/images/linkedin_orange.svg";
import Infoicon from "assets/images/infoicon.svg";
import moment from "moment";
import styles from "./style.module.css";
import {
  AboutInfo,
  AffordabilityPage,
  Disclaimer,
  DisclaimerDescription,
  DisclaimerTitle,
  FooterColumn,
  FooterContent,
  FooterLink,
  FooterLinks,
  FooterSection,
  FooterSocialLink,
  FooterSocialLinks,
  PageContainer,
  PageHeader,
  PoweredBy,
  ReservedRight,
  StyledButton,
} from "../affrdability/affrodability.style";
import {
  AffordableCost,
  AvailableDestinations,
  AvailableDestinationsData,
  AvailableDestinationsInfo,
  AvailableDestinationsInfoTitle,
  AvailableDestinationsSection,
  AvailableDestinationsTable,
  AvailableDestinationsTitle,
  AvailableHeadingBox,
  AvailableHeadingTitle,
  AvailableHotelMeals,
  AvailableHotelPerDay,
  InsufficientCost,
  LevelIndicator,
  LevelIndicatorBox,
  LevelIndicatorIcon,
  LevelIndicatorMainTitle,
  LevelIndicatorTitle,
  ManageableCost,
  TopInfoBox,
  TopInfoData,
  TopInfoDataBox,
  TopInfoLabel,
  TopInfoMainBox,
  TopInfoPart,
  TopInfoTitle,
} from "./affrodability-preview.style";

export interface ILoginData {
  businessId: string;
}

export interface PermissionOption {
  id: string;
  name: string;
}
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const schema = yup.object().shape({
  businessId: yup.object().shape({
    label: yup.string().required("Is required"),
    value: yup.string().required("Is required"),
  }),
});

const proptions = [
  {
    value: 2,
    label: "Balance",
  },
  {
    value: 3,
    label: "Transactions",
  },
  {
    value: 4,
    label: "Statements",
  },
];

const AffordabilityPreview: FC = () => {
  const history = useHistory();
  interface LocationState {
    endDate: string | undefined;
    startDate: string | undefined;
    rating: object | undefined;
    source: object | undefined;
    state: any;
  }
  const location = useLocation<LocationState>();
  const [showData, setShowData] = useState<any>(location.state);
  const filterData = (affordabilityType) => {
    let matchDataCount = showData.response.filter((item, index) => {
      return item.affordability === affordabilityType;
    });
    return matchDataCount;
  };
  const handelClick = () => {
    history.push({
      pathname: `/affordability`,
      state: {
        ...showData.requestsObj,
        country: showData.county,
        rating: showData.rating,
      },
    });
  };
  return (
    <>
      <AffordabilityPage>
        <PageContainer>
          <PageHeader>
            <img src={Logo} alt="logo" />
          </PageHeader>

          <TopInfoPart>
            <TopInfoTitle>Your Travel Affordability</TopInfoTitle>
            <TopInfoMainBox>
              <TopInfoBox>
                <TopInfoDataBox>
                  <TopInfoLabel>From</TopInfoLabel>
                  <TopInfoData>{showData.county.label}</TopInfoData>
                </TopInfoDataBox>

                <TopInfoDataBox>
                  <TopInfoLabel>Going Date </TopInfoLabel>
                  <TopInfoData>
                    {moment(showData.requestsObj.startDate).format(
                      "MMM DD, YYYY"
                    )}
                  </TopInfoData>
                </TopInfoDataBox>

                <TopInfoDataBox>
                  <TopInfoLabel>Return Date</TopInfoLabel>
                  <TopInfoData>
                    {moment(showData.requestsObj.endDate).format(
                      "MMM DD, YYYY"
                    )}
                  </TopInfoData>
                </TopInfoDataBox>

                <TopInfoDataBox>
                  <TopInfoLabel>Hotel Type</TopInfoLabel>
                  <TopInfoData>{showData.rating.label}</TopInfoData>
                </TopInfoDataBox>
              </TopInfoBox>

              <StyledButton onClick={handelClick}>
                Recalculate affordability
              </StyledButton>
            </TopInfoMainBox>
          </TopInfoPart>

          <LevelIndicator>
            <LevelIndicatorBox>
              <LevelIndicatorMainTitle>
                Afforability level indicator
              </LevelIndicatorMainTitle>
            </LevelIndicatorBox>

            <LevelIndicatorBox>
              <LevelIndicatorIcon src={Affordable} alt="" />
              <LevelIndicatorTitle>Affordable</LevelIndicatorTitle>
            </LevelIndicatorBox>

            <LevelIndicatorBox>
              <LevelIndicatorIcon src={Manageable} alt="" />

              <LevelIndicatorTitle>Manageable</LevelIndicatorTitle>
            </LevelIndicatorBox>

            <LevelIndicatorBox>
              <LevelIndicatorIcon src={Insufficient} alt="" />

              <LevelIndicatorTitle>Insufficient</LevelIndicatorTitle>
            </LevelIndicatorBox>
          </LevelIndicator>

          <AvailableDestinations>
            <AvailableDestinationsInfo>
              <AvailableDestinationsTitle>
                {showData.response.length} travel destinations available to you
              </AvailableDestinationsTitle>
              {filterData("Afforable").length && (
                <AvailableDestinationsInfoTitle>
                  {filterData("Afforable").length} affordable travel
                  destinations
                </AvailableDestinationsInfoTitle>
              )}
            </AvailableDestinationsInfo>

            <AvailableDestinationsTable>
              <thead>
                <tr>
                  <th>
                    <AvailableHeadingBox>
                      <img src={Destination} alt="" />
                      <AvailableHeadingTitle>Destination</AvailableHeadingTitle>
                    </AvailableHeadingBox>
                  </th>
                  <th>
                    <AvailableHeadingBox>
                      <img src={AirFares} alt="" />
                      <AvailableHeadingTitle>Air Fares</AvailableHeadingTitle>
                    </AvailableHeadingBox>
                  </th>
                  <th>
                    <AvailableHeadingBox>
                      <img src={HotelPerDay} alt="" />
                      <AvailableHeadingTitle>
                        Hotel Per Day
                      </AvailableHeadingTitle>
                    </AvailableHeadingBox>
                  </th>
                  <th>
                    <AvailableHeadingBox>
                      <img src={Cost} alt="" />
                      <AvailableHeadingTitle>Meals</AvailableHeadingTitle>
                    </AvailableHeadingBox>
                  </th>
                  <th>
                    <AvailableHeadingBox>
                      <img src={Meals} alt="" />
                      <AvailableHeadingTitle>Cost</AvailableHeadingTitle>
                    </AvailableHeadingBox>
                  </th>
                  <th>
                    <AvailableHeadingBox>
                      <img src={AffordabilityLevel} alt="" />
                      <AvailableHeadingTitle>
                        Affordability Level
                      </AvailableHeadingTitle>
                    </AvailableHeadingBox>
                  </th>
                </tr>
              </thead>

              <tbody>
                {filterData("Afforable").map((item, index) => (
                  <tr key={index}>
                    <AvailableDestinationsData>
                      {item.destination}
                    </AvailableDestinationsData>
                    <AvailableDestinationsData>
                      £{item.flightFare}
                    </AvailableDestinationsData>
                    <AvailableDestinationsData>
                      <AvailableHotelPerDay>
                        <span>£{item.hotelRate}</span>
                        <small>4 Star Hotel </small>
                      </AvailableHotelPerDay>
                    </AvailableDestinationsData>

                    <AvailableDestinationsData>
                      <AvailableHotelMeals>
                        <span>£{item.mealsTotal}</span>
                        <small>/£{item.mealsPerDay} per day</small>
                      </AvailableHotelMeals>
                    </AvailableDestinationsData>

                    <AffordableCost>£{item.totalCost}</AffordableCost>

                    <AvailableDestinationsData>
                      <LevelIndicatorBox>
                        <LevelIndicatorIcon src={Affordable} alt="" />
                        <span>Affordable</span>
                      </LevelIndicatorBox>
                    </AvailableDestinationsData>
                  </tr>
                ))}
                {filterData("Manageable").length ? (
                  <AvailableDestinationsSection>
                    <td colSpan={6}>
                      {filterData("Manageable").length} Manageable travel
                      destinations
                    </td>
                  </AvailableDestinationsSection>
                ) : (
                  <tr></tr>
                )}

                {filterData("Manageable").map((item, index) => (
                  <tr key={index}>
                    <AvailableDestinationsData>
                      {item.destination}
                    </AvailableDestinationsData>
                    <AvailableDestinationsData>
                      £{item.flightFare}
                    </AvailableDestinationsData>
                    <AvailableDestinationsData>
                      <AvailableHotelPerDay>
                        <span>£{item.hotelRate}</span>
                        <small>4 Star Hotel </small>
                      </AvailableHotelPerDay>
                    </AvailableDestinationsData>

                    <AvailableDestinationsData>
                      <AvailableHotelMeals>
                        <span>£{item.mealsTotal}</span>
                        <small>/£{item.mealsPerDay} per day</small>
                      </AvailableHotelMeals>
                    </AvailableDestinationsData>

                    <ManageableCost>£{item.totalCost}</ManageableCost>

                    <AvailableDestinationsData>
                      <LevelIndicatorBox>
                        <LevelIndicatorIcon src={Manageable} alt="" />
                        <span>Manageable</span>
                      </LevelIndicatorBox>
                    </AvailableDestinationsData>
                  </tr>
                ))}

                {filterData("Insufficient").length ? (
                  <AvailableDestinationsSection>
                    <td colSpan={6}>
                      {" "}
                      {filterData("Insufficient").length} Insufficient travel
                      destinations
                    </td>
                  </AvailableDestinationsSection>
                ) : (
                  <tr></tr>
                )}
                {filterData("Insufficient").map((item, index) => (
                  <tr key={index}>
                    <AvailableDestinationsData>
                      {item.destination}
                    </AvailableDestinationsData>
                    <AvailableDestinationsData>
                      £150.00
                    </AvailableDestinationsData>
                    <AvailableDestinationsData>
                      <AvailableHotelPerDay>
                        <span>£{item.flightFare}</span>
                        <small>4 Star Hotel </small>
                      </AvailableHotelPerDay>
                    </AvailableDestinationsData>

                    <AvailableDestinationsData>
                      <AvailableHotelMeals>
                        <span>£{item.mealsTotal}</span>
                        <small>/£{item.mealsPerDay} per day</small>
                      </AvailableHotelMeals>
                    </AvailableDestinationsData>

                    <InsufficientCost>£{item.totalCost}</InsufficientCost>

                    <AvailableDestinationsData>
                      <LevelIndicatorBox>
                        <LevelIndicatorIcon src={Insufficient} alt="" />
                        <span>Insufficient</span>
                      </LevelIndicatorBox>
                    </AvailableDestinationsData>
                  </tr>
                ))}
              </tbody>
            </AvailableDestinationsTable>
          </AvailableDestinations>

          <Disclaimer>
            <DisclaimerTitle>
              <img src={Infoicon} alt="img" />
              Disclaimer
            </DisclaimerTitle>
            <DisclaimerDescription>
              Donec vel nulla et dui tincidunt egestas. Mauris vulputate dictum
              arcu, eget dignissim eros porta sodales. Ut ac feugiat augue, et
              sollicitudin orci. Sed eget semper tellus. Cras condimentum, velit
              at laoreet scelerisque, nunc dolor dapibus
            </DisclaimerDescription>
          </Disclaimer>
        </PageContainer>

        <FooterSection className="footersec">
          <FooterContent>
            <FooterColumn>
              <img src={Logo} alt="" />
              <AboutInfo>
                Data Analytics company and authorised <br />
                Account Information Services Provider.
              </AboutInfo>
            </FooterColumn>

            <FooterColumn>
              <FooterLinks>
              <li>
                  <FooterLink to="https://s3.af-south-1.amazonaws.com/e-doconline.ng/documents/E-Doc+ng+Privacy+Policy.pdf">Privacy Policy</FooterLink>
                </li>
                <li>
                  <FooterLink to="https://s3.af-south-1.amazonaws.com/e-doconline.ng/documents/E-Doc+Ng+Terms+%26+Conditions.pdf">Terms & Conditions</FooterLink>
                </li>
              </FooterLinks>
            </FooterColumn>

            <FooterColumn className="footercol">
              <FooterSocialLinks>
                <li>
                  <FooterSocialLink to="" target="_blank">
                    <img src={twitter_orange} alt="" />
                  </FooterSocialLink>
                </li>
                <li>
                  <FooterSocialLink to="" target="_blank">
                    <img src={instagram_orange} alt="" />
                  </FooterSocialLink>
                </li>
                <li>
                  <FooterSocialLink to="" target="_blank">
                    <img src={google_orange} alt="" />
                  </FooterSocialLink>
                </li>
                <li>
                  <FooterSocialLink to="" target="_blank">
                    <img src={linkedin_orange} alt="" />
                  </FooterSocialLink>
                </li>
              </FooterSocialLinks>

              <PoweredBy>Powered by E-Doconline</PoweredBy>
              <ReservedRight>
                © E-doc Online Limited. All rights reserved.
              </ReservedRight>
            </FooterColumn>
          </FooterContent>
        </FooterSection>
      </AffordabilityPage>
    </>
  );
};

export default AffordabilityPreview;
