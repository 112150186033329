import { FC } from 'react';
import { SideDrawerStyle, BackdDrop } from './side-drawer.style';

interface ISideDrawer {
   returnText: string;
   handleClose: () => void;
   children: React.ReactNode;
   visible: boolean;
}
const SideDrawer: FC<ISideDrawer> = ({
   children,
   visible,
   handleClose,
   returnText,
}: ISideDrawer) => {
   return (
      <>
         <BackdDrop onClick={handleClose} className={visible ? 'visible' : ''} />
         <SideDrawerStyle className={visible ? 'visible' : ''}>
            <button type="button" className="close-btn" onClick={handleClose}>
               <span className="close">{'<'}</span>
               <span className="text"> {returnText}</span>
            </button>

            {children}
         </SideDrawerStyle>
      </>
   );
};

export { SideDrawer };
