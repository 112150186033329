import styled from "@emotion/styled";

export const HeaderStyled = styled.div`
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 1;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
  }

  li .menu-item {
    display: block;
    padding: 20px 20px;
    text-decoration: none;
    cursor: pointer;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .active-item {
    color: #fc5719 !important;
    border-bottom: 2px solid #fc5719;
  }

  .btn {
    margin: 10px 30px;
    border-radius: 12px;
  }

  .menu-btn:hover {
    background-color: #f4f4f4;
  }

  .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
  }

  .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }

  .menu-icon .navicon {
    background: #000;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }

  .menu-icon .navicon:before,
  .menu-icon .navicon:after {
    background: #000;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .menu-icon .navicon:before {
    top: 5px;
  }

  .menu-icon .navicon:after {
    top: -5px;
  }

  .menu-btn {
    display: none;
  }

  .menu-btn:checked ~ .menu {
    max-height: 500px;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }

  .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }

  @media (min-width: 993px) {
    li {
      float: left;
    }
    li .menu-item {
      padding: 20px 30px;
    }
    .menu {
      clear: none;
      float: right;
      max-height: none;
    }
    .menu-icon {
      display: none;
    }
  }

  @media (max-width: 1290px) {
    li .menu-item {
      padding: 20px 8px;
    }
  }
`;

export const HeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  gap: 10px;
  padding: 10px 20px;
`;

export const Name = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;

export const UserHistory = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;

  @media (max-width: 999px) {
    max-width: 120px;
  }

  @media (max-width: 768px) {
    max-width: 250px;
  }

  @media (max-width: 460px) {
    max-width: 170px;
  }
`;

export const UserName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const UserDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
