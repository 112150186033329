import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import "./style.css"

export const Div = styled.div`

   &.d-flex {
      display: flex;
   }

   &.d-grid-center {
      display: grid;
      justify-items: center;
      align-items: center;
   }

   &.d-grid {
      display: grid;
   }

   &.grid-1-2 {
      grid-template-columns: 1fr 2fr;
   }

   &.flex-column {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
   }

   &.flex-row {
      display: flex;
      justify-content: center;
      align-items: center;
   }

   &.justify-content-center {
      justify-content: center;
   }

   &.justify-content-start {
      justify-content: start;
   }

   &.justify-content-between {
      justify-content: space-between;
   }

   &.align-items-center {
      align-items: center;
   }

   &.align-items-start {
      align-items: flex-start;
   }

   &.text-center {
      text-align: center;
      margin: 0 auto;
   }

   &.my-auto {
      margin: 0 auto;
   }

   &.mx-20 {
      margin: 0 20px;
   }

   &.mx-10 {
      margin: 0 10px;
   }

   &.my-2 {
      margin: 2rem auto;
   }

   &.my-20 {
      margin: 2rem auto;
   }

   &.my-30 {
      margin: 3rem auto;
   }

   &.my-50 {
      margin: 5rem auto;
   }

   &.my-3 {
      margin: 3rem auto;
   }

   &.mt-10 {
      margin-top: 1rem;
   }

   &.mt-20 {
      margin-top: 2rem;
   }

   &.mt-30 {
      margin-top: 3rem;
   }

   &.mt-40 {
      margin-top: 4rem;
   }

   &.mt-70 {
      margin-top: 7rem;
   }

   &.mt-80 {
      margin-top: 8rem;
   }

   &.mr-30 {
      margin-right: 3rem;
   }

   &.ml-20 {
      margin-left: 2rem;
   }

   &.ml-10 {
      margin-left: 1rem;
   }

   &.ml-40 {
      margin-left: 5rem;
   }

   &.mr-40 {
      margin-right: 4rem;
   }

   &.mr-50 {
      margin-right: 5rem;
   }

   &.mr-70 {
      margin-right: 7rem;
   }

   &.mt-20 {
      margin-top: 2rem;
   }

   &.mb-05 {
      margin-bottom: 0.5rem;
   }

   &.mb-13 {
      margin-bottom: 1.3rem;
   }

   &.mb-15 {
      margin-bottom: 1.5rem;
   }

   &.mb-20 {
      margin-bottom: 2rem;
   }

   &.mb-30 {
      margin-bottom: 3rem;
   }

   &.mb-50 {
      margin-bottom: 6.5rem;
   }

   &.font-40 {
      font-size: 4rem;
   }

   &.font-30 {
      font-size: 4rem;
   }

   &.font-24 {
      font-size: 2.4rem;
   }

   &.font-22 {
      font-size: 2.2rem;
   }

   &.font-20 {
      font-size: 2rem;
   }

   &.font-18 {
      font-size: 1.8rem;
   }

   &.font-17 {
      font-size: 1.7rem;
   }

   &.font-16 {
      font-size: 1.6rem;
   }

   &.font-15 {
      font-size: 1.5rem;
   }

   &.font-14 {
      font-size: 1.4rem;
   }

   &.font-13 {
      font-size: 1.3rem;
   }

   &.font-12 {
      font-size: 1.2rem;
   }

   &.w-50 {
      width: 50%;
   }

   &.w-60 {
      width: 60%;
   }

   &.w-75 {
      width: 75%;
   }

   &.w-80 {
      width: 80%;
   }

   &.w-90 {
      width: 90%;
   }

   &.w-100 {
      width: 100%;
   }

   &.text-orange {
      color: ${({ theme }) => theme.colors?.orange01};
   }

   &.text-green {
      color: ${({ theme }) => theme.colors?.green01};
   }

   &.text-black {
      color: ${({ theme }) => theme.colors?.black03};
   }

   &.text-grey {
      color: ${({ theme }) => theme.colors?.grey06};
   }

   &.text-grey01 {
      color: ${({ theme }) => theme.colors?.black03};
   }

   &.h-500 {
      height: 500px;
   }

   &.h-100 {
      height: 100%;
   }

   &.vh-100 {
      height: 100vh;
   }

   &.font-regular {
      font-weight: 500;
   }

   &.font-bold {
      font-weight: 600;
   }

   &.initial-case {
      text-transform: capitalize;
   }
   &.text-uppercase {
      text-transform: uppercase;
   }
   &.position-relative {
      position: relative;
   }
   &.info {
      
      border-radius: 5px;
      padding: 15px;
      margin-top: 15px;
      p {
         margin-bottom: 10px;
      }
   }
`;

export const Span = styled(Div)`
   display: inline-block;
`;

export const TextGrey = styled(Span)`
   color: ${({ theme }) => theme.colors?.grey06};
`;

export const TextGreen = styled(Span)`
   color: ${({ theme }) => theme.colors?.green01};
`;

export const TextOrange = styled.span`
   color: ${({ theme }) => theme.colors?.orange01};
`;

export const RightContainer = styled.div`
   background-color: #fff;
   padding: 6rem;
   box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);

   .visible & {
      ${({theme}) => theme.media?.mobile} {
         padding: 2rem;
      }
   }
`;

export const Lynk = styled(Link)`
   text-decoration: none;
   color: ${({ theme }) => theme && theme.colors?.grey06};

   &.green {
      color: ${({ theme }) => theme && theme.colors?.green01};
   }

   &:hover {
      text-decoration: underline;
   }

   &.underline {
      text-decoration: underline;
   }
`;

export const Label = styled.label`
   color: ${({ theme }) => theme && theme.colors?.grey06};
`;

export const HeadingOne = styled.div`
   color: ${({ theme }) => theme.colors?.grey03};
   font-size: 1.5rem;
   width: 80%;
   margin: 0 auto;

   &.center {
      text-align: center;
   }

   &.purple {
      color: ${({ theme }) => theme.colors?.purple};
   }

   &.bold {
      font-weight: 600;
   }

   &.regular {
      font-weight: 500;
   }
`;

export const HeadingTwo = styled.div`
   margin-top: 0.5rem;
   color: ${({ theme }) => theme.colors?.black02};
   font-size: 1.7rem;

   &.center {
      text-align: center;
   }

   &.purple {
      color: ${({ theme }) => theme.colors?.purple};
   }

   &.bold {
      font-weight: 600;
   }
`;

export const MainHeading = styled.h1`
   font-size: 2.3rem;
   margin-bottom: 10px;
   letter-spacing: 0.06rem;
   color: ${({ theme }) => theme.colors?.grey06};

   &.center {
      text-align: center;
   }

   &.purple {
      color: ${({ theme }) => theme.colors?.purple};
   }

   &.bold {
      font-weight: 600;
   }
`;

export const OrangeBackground = styled.div`
   // background-color: ${({ theme }) => theme.colors?.orange01};
   height: 100%;
   padding: 5% 0;
   font-size: 1.5rem;
`;

export const GridContainer = styled.div`
   display: grid;
   grid-template-columns: 300px 1fr;
   height: 100%;
   line-height: 2.5rem;
   max-width: 1000px;
   margin: 0 auto;

   
   ${({theme}) => theme.media?.semiMedium} {
      display: block;
   }
`;

export const SuccessContainer = styled.div`
   background: #fff;
   padding: 60px 0px;
   width: 650px;
   height: 500px;
   margin: auto;
   display: grid;
   text-align: center;
   box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

   img {
      width: 70px;
      height: 70px;
   }

   ${({theme}) => theme.media?.semiMedium} {
      width: 100%;
   }
`;
