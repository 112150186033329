import { FC } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Div, TextGrey, TextGreen } from 'components/styles';
import { ProgresBarContainer } from './duration-progress.style';

interface IDurationProgress {
   daysLeft: number;
   status: string;
}

const DurationProgress: FC<IDurationProgress> = ({ daysLeft, status }: IDurationProgress) => {
   return (
      <Div className="d-flex align-items-center mt-20 font-14">
         <ProgresBarContainer>
            <CircularProgressbar strokeWidth={8} value={66} />
         </ProgresBarContainer>
         <Div className="ml-20">
            <Div className="mb-05 font-regular text-grey01">{daysLeft} days left</Div>
            <TextGrey>Status</TextGrey> <TextGreen className="font-bold ml-10">{status}</TextGreen>
         </Div>
      </Div>
   );
};

export { DurationProgress };
