import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ModalStyle = styled.div<{ width?: string; height?: string; background?: string }>`

   ${({ width }) =>
      width &&
      css`
         width: ${width || '550px'};
      `}

   ${({ height }) =>
      height &&
      css`
         height: ${height || '400px'};
      `}

   ${({ background }) =>
      background &&
      css`
         background-color: ${background};
      `}

   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   z-index: 20;
   opacity: 0;
   visibility: hidden;
   overflow:auto;
   BACKGROUND-COLOR:#000;
   height: 400px;
   border-radius:10px;
   background-image: url(https://rstheme.com/products/html/braintech/assets/images/bg/team-bg.png);
   background-size: 100% 100%;

   ${({theme}) => theme.media?.mobile} {
      width: 90vw;
   }
   &.visible {
      opacity: 1;
      visibility: visible;
   }

   /* @keyframes scaleUp {
      0% {
         transform: scale(0.8) translate(0%, 100px);
         opacity: 0;
      }
      100% {
         transform: scale(1) translate(-50%, -50%);
         opacity: 1;
      }
   } */
`;

export const ModalBackDrop = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 10;
   visibility: hidden;
   height: 100vh;
   width: 100vw;
   opacity: 0;
   transition: opacity 200ms ease-in-out;
   
   &.visible {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      display: block;
      opacity: 0.5;
      background-color: #1c1c1c;
      visibility: visible;
   }
`;
