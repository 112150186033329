/* eslint-disable @typescript-eslint/no-explicit-any */
import SnackbarProvider from 'components/snackbar/snackbar';
import { useReducer, createContext, useContext } from 'react';

interface SnackbarProps {
   message: string;
   duration?: number | undefined;
   persist?: boolean;
   variant?: string;
   toggle?: boolean;
}

const initialState = {
   toggle: false,
   message: '',
   persist: false,
   duration: 0,
   variant: 'info',
};

interface Action {
   type: 'TOGGLE_SNACKBAR_OPEN' | 'TOGGLE_SNACKBAR_CLOSE';
   payload: SnackbarProps;
}

const SnackBarContext = createContext<{
   state: SnackbarProps;
   dispatch: React.Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

type SnackbarProviderProps = { children: React.ReactNode };

function snackbarReducer(state: SnackbarProps, action: Action) {
   switch (action.type) {
      case 'TOGGLE_SNACKBAR_OPEN': {
         return {
            ...state,
            toggle: true,
            message: action.payload.message,
            duration: action.payload.duration,
            variant: action.payload.variant,
         };
      }
      case 'TOGGLE_SNACKBAR_CLOSE': {
         return {
            ...state,
            toggle: false,
            message: '',
         };
      }
      default: {
         throw new Error(`Unhandled action type: ${action.type}`);
      }
   }
}

function SnackbarContextProvider({ children }: SnackbarProviderProps) {
   const [state, dispatch] = useReducer(snackbarReducer, initialState);

   return (
      <SnackBarContext.Provider value={{ state, dispatch }}>
         <SnackbarProvider />
         <>{children}</>
      </SnackBarContext.Provider>
   );
}

const toggleSnackbarOpen = (payload: SnackbarProps) => ({
   type: 'TOGGLE_SNACKBAR_OPEN',
   payload,
});

const toggleSnackbarClose = () => ({
   type: 'TOGGLE_SNACKBAR_CLOSE',
});

function useSnackbar() {
   interface INotifyProps {
      message: string;
      duration?: number;
      persist?: boolean;
      variant?: 'success' | 'error' | 'info';
   }

   const context = useContext(SnackBarContext);
   const { state, dispatch } = context;

   function notify({ message, duration, variant }: INotifyProps) {
      dispatch(
         toggleSnackbarOpen({
            duration: duration || 3000,
            message,
            variant,
         }),
      );
   }

   function closeNotify() {
      dispatch(toggleSnackbarClose());
   }

   if (context === undefined) {
      throw new Error('useSnackbar must be used within a SnackbarProvider');
   }

   return { notify, closeNotify, state, dispatch };
}

export { SnackbarContextProvider, useSnackbar };
