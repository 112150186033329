import styled from '@emotion/styled';

import BrandIcon from 'assets/images/logo-active.png';

const Logo = styled.img`
   max-width: 130px;

   &.absolute {
      position: absolute;
      top: 40px;
      left: 50px;
      
      ${({ theme }) => theme.media?.semiMedium} {
         position: relative;
         top: unset;
         left: unset;
      }
   }

   
`;

export const BrandLogo = ({ className, ...rest }) => (
   <Logo className={className} src={BrandIcon} alt="brand-logo" {...rest} />
);
