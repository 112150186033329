import { NotFound } from "components/not-found/not-found";
import { ProtectedRoute } from "hoc/protected-routes";
import EmailConfirmationInfo from "pages/auth/email-confirmation";
import VerifyEmail from "pages/auth/verify-email";
import ForgotPassword from "pages/auth/forgot-password";
import ChangePassword from "pages/auth/change-password";
import Login from "pages/auth/login";
import Register from "pages/auth/register";
import BankSelection from "pages/bank-selection/bank-selection";
import ManagePlan from "pages/dashboard/manage-plan/manage-plan";
import DataView from "pages/data-view/data-view";
import HomePage from "pages/home-page/home-page";
import Permission from "pages/permission/permission";
import { FC } from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import ResetPassword from "pages/auth/reset-user-password";
import VerifyResetPasswordToken from "pages/auth/verify-reset-password-token";
import RedirectionSuccess from "pages/bank-selection/redirection-success";
import RedirectionFailure from "pages/bank-selection/redirection-failure";
import Product from "pages/product/product";
import ResetForgotPassword from "pages/auth/reset-password";
import Affordability from "pages/affrdability/affordability";
import AffordabilityPreview from "pages/affrdability-preview/affordability-preview";
import Graphs from "pages/graphs/graphs";
import Confirmation from "pages/bank-selection/confirmation";
import DashboardOverview from "pages/dashboard-overview/dashboard-overview";
import Demo from "pages/demo";
import IncomeAndJobVerification from "pages/dashboard-overview/IncomeAndJobVerification";
import TransactionList from "pages/transaction-list";
import ProofOfFunds from "pages/dashboard-overview/ProofOfFunds";

const Routes: FC<RouteProps> = () => {
  return (
    <Switch>
      <Route path="/" component={HomePage} exact />
      <Route path="/demo" component={Demo} exact />
      <Route path="/transaction-list" component={TransactionList} exact />
      {/* <ProtectedRoute path="/dashboard" component={ManagePlan} exact /> */}
      <ProtectedRoute path="/manage-plan" component={ManagePlan} exact />
      <ProtectedRoute path="/dashboard" component={DataView} exact />
      <ProtectedRoute notProtected path="/register" component={Register} />
      <ProtectedRoute notProtected path="/login" component={Login} />
      <ProtectedRoute
        notProtected
        path="/forgot-password"
        component={ForgotPassword}
      />
      <ProtectedRoute path="/change-password" component={ChangePassword} />
      <ProtectedRoute path="/reset-user-password" component={ResetPassword} />
      <ProtectedRoute
        notProtected
        path="/reset-forgot-password"
        component={ResetForgotPassword}
      />
      <ProtectedRoute path="/permission" component={Permission} />
      <ProtectedRoute path="/product" component={Product} />
      <ProtectedRoute path="/affordability" component={Affordability} />

      <ProtectedRoute path="/affordability" component={Affordability} />

      <ProtectedRoute path="/charts" component={Graphs} />
      <ProtectedRoute
        path="/affordability-preview"
        component={AffordabilityPreview}
      />
      <ProtectedRoute
        notProtected
        path="/email-confirmation"
        component={EmailConfirmationInfo}
        exact
      />
      <ProtectedRoute
        notProtected
        path="/verify-email/:token"
        component={VerifyEmail}
        exact
      />
      <ProtectedRoute
        notProtected
        path="/activateUser/:token"
        component={VerifyEmail}
        exact
      />
      <ProtectedRoute
        notProtected
        path="/reset-password/:token"
        component={VerifyResetPasswordToken}
        exact
      />
      <ProtectedRoute path="/bank-selection" component={BankSelection} exact />

      <ProtectedRoute
        path="/dashboard-overview"
        component={DashboardOverview}
        exact
      />

      <ProtectedRoute path="/kyc" component={IncomeAndJobVerification} exact />
      <ProtectedRoute path="/proof-of-funds" component={ProofOfFunds} exact />

      <ProtectedRoute path="/confirmation" component={Confirmation} exact />
      <ProtectedRoute
        path="/redirection-success"
        component={RedirectionSuccess}
        exact
      />
      <ProtectedRoute
        path="/redirection-failure"
        component={RedirectionFailure}
        exact
      />
      <ProtectedRoute notProtected component={NotFound} />
    </Switch>
  );
};

export { Routes };
