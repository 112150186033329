import styled from "@emotion/styled";

export const OverviewWrapper = styled.div`
  background: #f0f0f0;
  min-height: "100%";
  flex-grow: 1;
`;

export const MainWrapper = styled.div`
  margin: 120px 100px;
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.22px;

  @media (max-width: 1260px) {
    margin: 120px 20px;
  }
`;

export const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 30px;

  .btn-head {
    border-radius: 12px;
    animation: none;

    @media (max-width: 550px) {
      width: 120px;
    }
  }

  .btn-update {
    border-radius: 12px;
    animation: none;
  }

  ${({ theme }) => theme.media?.semiLarge} {
    flex-direction: column;
  }
`;

export const LastRefresh = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;

  .ref-text {
    color: #ed7d32;
    font-weight: 500;
  }
  ${({ theme }) => theme.media?.semiLarge} {
    flex-direction: column;
  }
`;

export const FilterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

export const OverviewContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  ${({ theme }) => theme.media?.semiLarge} {
    flex-direction: column;
  }
`;

export const ContentCard = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  height: 394px;
  padding: 24px;
`;

export const CardHeading = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
`;

export const HeadingTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 25px 0;
`;

export const CardInfoHeading1 = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
`;

export const CardInfoHeading2 = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const CardInfoHeading3 = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
`;

export const CardInfoHeading4 = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
`;

export const CardListWrapper = styled.div`
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 12px;
  margin-bottom: 18px;
`;

export const CardListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RightCardItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CircularProgressMainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 100%;

  @media (max-width: 350px) {
    gap: 20px;
  }
`;

export const CircularProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
