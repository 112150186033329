/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from "react";
import AccountIcon from "assets/images/account.svg";
import AlarmIcon from "assets/images/notifications-two.svg";
import CaretWhite from "assets/images/caret-white.svg";
import Caret from "assets/images/caret.svg";
import Logo from "assets/images/logo.png";
import LogoActive from "assets/images/logo-active.png";
import { useAuth } from "context/auth";
import { useClickAway } from "react-use";
import { useHistory } from "react-router-dom";
import { getUserInfoFromStorage } from "utils/storage";
import SettingsIcon from "assets/images/settings-two.svg";
import {
  HeaderStyled,
  HeaderContent,
  ImageHolder,
  DropDown,
  DropDownItem,
  LinkView,
} from "./dashboard-header.style";
import { parseJwt } from "utils/functions";

const DashboardHeader = (props) => {
  const { variant } = props;
  const { currentUser, logout }: any = useAuth();
  const [toggle, setToggle] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const navigationMenuRef = useRef(null);
  const productMenuRef = useRef(null);
  useClickAway(navigationMenuRef, () => {
    setToggle(false);
  });
  useClickAway(productMenuRef, () => {
    setIsShow(false);
  });

  const history = useHistory();
  const token = getUserInfoFromStorage();
  console.log("token", token);
  let userData = parseJwt(token);
  console.log("user data", userData);
  return (
    <HeaderStyled className={variant}>
      <div className="logo-box">
        <img src={LogoActive} alt="logo" className="logo" />
      </div>
      <HeaderContent>
        <LinkView href="/dashboard">Transactions</LinkView>
        <LinkView href="/manage-plan">View Plan</LinkView>
        {/* <LinkView href='/product'>
               Product
            </LinkView> */}
        <div
          className="user-section"
          onKeyPress={() => setIsShow(!isShow)}
          role="button"
          tabIndex={0}
          onClick={() => setIsShow(!isShow)}
          ref={productMenuRef}
        >
          <span className="text-white">Product</span>
          {variant === "white" ? (
            <img className="caret-image" src={Caret} alt="caret" />
          ) : (
            <img className="caret-image" src={CaretWhite} alt="caret" />
          )}
        </div>
        <DropDown
          ref={productMenuRef}
          className={`${isShow ? "visible" : ""} product`}
        >
          <DropDownItem onClick={() => history.push("/product")}>
            Business Partner
          </DropDownItem>
          <DropDownItem onClick={() => history.push("/affordability")}>
            Affordability Calculator
          </DropDownItem>
          <DropDownItem onClick={() => history.push("/kyc")}>
            Dashboard
          </DropDownItem>
        </DropDown>
        {/* <ImageHolder>
               <img src={AlarmIcon} alt="notification" />
            </ImageHolder>
            <ImageHolder>
               <img src={SettingsIcon} alt="settings" />
            </ImageHolder> */}
        <div
          className="user-section"
          onKeyPress={() => setToggle(!toggle)}
          role="button"
          tabIndex={0}
          onClick={() => setToggle(!toggle)}
          ref={navigationMenuRef}
        >
          <span className="user-name text-white">
            {userData?.firstName} {userData?.lastName}
          </span>
          <ImageHolder className="user-image-holder no-border">
            <img src={AccountIcon} alt="account" />
          </ImageHolder>
          {variant === "white" ? (
            <img className="caret-image" src={Caret} alt="caret" />
          ) : (
            <img className="caret-image" src={CaretWhite} alt="caret" />
          )}

          {/* <Dropdown overlay={menu} trigger={['click']}>
                  <ImageHolder className="no-border caret-image">
                     <CaretIcon />
                  </ImageHolder>
               </Dropdown> */}
        </div>
        <DropDown
          ref={navigationMenuRef}
          className={`${toggle ? "visible" : ""}`}
        >
          <DropDownItem
            className="mobile"
            onClick={() => history.push("/dashboard")}
          >
            Dashboard
          </DropDownItem>
          <DropDownItem
            className="mobile"
            onClick={() => history.push("/manage-plan")}
          >
            View Plan
          </DropDownItem>
          {/* <DropDownItem
            className="mobile"
            onClick={() => history.push("/product")}
          >
            Product
          </DropDownItem> */}
          <DropDownItem onClick={() => history.push("/change-password")}>
            Change Password
          </DropDownItem>
          <DropDownItem onClick={logout}>Logout</DropDownItem>
        </DropDown>
      </HeaderContent>
    </HeaderStyled>
  );
};

export { DashboardHeader };
