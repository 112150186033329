/* eslint-disable @typescript-eslint/no-unused-vars */
import {
   InputHTMLAttributes,
   forwardRef,
   ChangeEvent,
   ReactNode,
   useCallback,
   useMemo,
   useState,
} from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { ReactComponent as PasswordEyeOpenIcon } from 'assets/images/eye.svg';
import { ReactComponent as PasswordEyeCloseIcon } from 'assets/images/eye-inactive.svg';
import { motion } from 'framer-motion';

const Div = styled.div<{ fullWidth: boolean | undefined }>`
   ${({ fullWidth }) => fullWidth && 'width: 100%;'}
   position: relative;
   display: inline-block;
`;

const Label = styled.label<{
   shrink: boolean | undefined;
   isMobile: boolean | undefined;
}>`
   pointer-events: none;
   color: ${({ theme }) => theme && theme.colors?.grey03};
   white-space: nowrap;

   ${({ isMobile, theme }) =>
      !isMobile &&
      css`
         margin-bottom: 1rem;
         display: block;
         font-size: 1.4rem;
         font-weight: ${theme && theme.fontWeights?.regular};
         color: ${theme && theme && theme.colors?.black01};
      `}

   ${({ isMobile, shrink }) =>
      isMobile &&
      css`ack
         position: absolute;
         top: 0;
         left: 0;
         transition: transform 0.2s ease-out 0ms;
         transform-origin: top left;

         transform: translateY(calc(100% + 0.8rem));

         ${Div}:focus-within & {
            transform: translateY(0) scale(0.85);
         }

         ${shrink && 'transform: translateY(0) scale(0.85);'}
      `}
`;

const AdornmentWrapperDiv = styled.div`
   display: flex;
   align-items: center;
   white-space: nowrap;
   position: absolute;
   padding-left: 10px;
   margin-right: 10px;

   &.end {
      right: 0;
      top: 30%;
   }
`;

const InputFieldWrapperDiv = styled.div<{
   shrink: boolean;
   error: boolean;
   isMobile: boolean | undefined;
}>`
   position: relative;
   display: flex;
   align-items: center;

   ${({ isMobile, shrink, error, theme }) =>
      isMobile &&
      css`
         margin-top: 1.4rem;

         ::before,
         ::after {
            content: '';
            width: 100%;
            height: 1px;

            position: absolute;
            bottom: 0;
            left: 0;
         }

         ::before {
            background-color: ${theme && theme.colors?.grey03};
         }

         ::after {
            background-color: ${error ? theme.colors?.redError : theme.colors?.black};

            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.25s;

            ${Div}:focus-within & {
               transform: scaleX(1);
            }

            ${(shrink || error) && 'transform: scaleX(1);'}
         }
      `}
`;

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
   error?: ReactNode;
   startAdornment?: ReactNode;
   isMobile?: boolean;
}

const InputField = styled.input<IInputProps>`
   width: 100%;
   background-color: ${({ theme }) => theme.colors?.white};
   outline: none;
   font-size: 1.4rem;
   appearance: none;
   border-radius: 0;
   padding: 0 ${({ startAdornment }) => (startAdornment ? '3.8rem' : '1rem')};
   background: transparent;
   box-sizing: border-box;
   border: 1px solid #c1c7d0;
   height: 43px;
   border-radius: 3px;
   border: ${({ theme, error }) =>
      `1px solid ${error ? theme.colors?.redError : theme.colors?.grey02}`};

   ::placeholder {
      color: #b8b6b6;
   }

   ${({ isMobile }) =>
      isMobile &&
      css`
         width: 100%;
         padding: 1.2rem;
         color: yellow;
         border: none;
         outline: none;
         appearance: none;
         border-radius: 0;
         padding: 0.8rem 0;
         background: transparent;
         box-sizing: border-box;
      `}

   &::-ms-clear {
      display: none;
   }

   &::-ms-expand {
      display: none;
   }

   &:-moz-ui-invalid {
      box-shadow: none;
   }
`;

export const ErrorP = styled(motion.p)`
   margin: 0.3rem 0 0 0;
   color: ${({ theme }) => theme.colors?.redError};
   font-size: 1.4rem;
   letter-spacing: -0.06rem;
   font-weight: 500;
`;

const PasswordControlButton = styled.button`
   background: none;
   border: none;
`;

interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'css'> {
   /**
    * ID of an input
    *
    * It is also used for id of label & error text. Should be unique within a page.
    */
   id: string;

   className?: string;

   name?: string;

   /**
    * Input type
    *
    * @example "email"
    *
    * @example "password"
    *
    * @default "text"
    */
   type?: string;

   label: string;

   required?: boolean;

   /**
    * Error for the input.
    *
    * @example
    * <Input error="Invalid value." />
    *
    * @example
    * <Input error={<span>You should enter <b>valid</b> phone number.</span>}
    */
   error?: ReactNode;

   fullWidth?: boolean;

   /**
    * Start adornment component
    *
    * You can use this to add a prefix to an input.
    *
    * @example
    * // Country code of Portugal phone number input
    * <Input startAdornment={<IconComponent />} />
    */
   startAdornment?: ReactNode;

   /**
    * End adornment component
    *
    * You can use this to add a suffix or action to the input.
    *
    * @example
    * // Suffix of weight input
    * <Input endAdornment="kg" />
    *
    * @example
    * // Clear input button
    * <Input endAdornment={<button>clear</button>} />
    */
   endAdornment?: ReactNode;
   isMobile?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
   (
      {
         id,
         name,
         type = 'text',
         label,
         required,
         value,
         error,
         fullWidth,
         className,
         onChange,
         startAdornment,
         endAdornment,
         isMobile,
         ...remainingProps
      },
      ref,
   ) => {
      const labelId = label ? `${id}-label` : undefined;
      const errorId = error ? `${id}-error-text` : undefined;

      const [isFilled, setIsFilled] = useState(!!value);
      const [showPassword, setShowPassword] = useState<boolean>(false);

      const handleChange = useCallback(
         (event: ChangeEvent<HTMLInputElement>) => {
            onChange?.(event);
            setIsFilled(!!event.target.value);
         },
         [onChange],
      );

      const PasswordControl = useMemo(
         () => (
            <PasswordControlButton type="button" onClick={() => setShowPassword(!showPassword)}>
               {showPassword ? (
                  <PasswordEyeOpenIcon width="19" />
               ) : (
                  <PasswordEyeCloseIcon width="19" />
               )}
            </PasswordControlButton>
         ),
         [showPassword],
      );

      const shrink = useMemo(() => (value ? !!value : isFilled) || !!startAdornment, [
         isFilled,
         startAdornment,
         value,
      ]);

      return (
         <Div fullWidth={fullWidth} className={className}>
            {label && (
               <Label id={labelId} htmlFor={id} shrink={shrink} isMobile={isMobile}>
                  {`${label}${required ? '*' : ''}`}
               </Label>
            )}
            <InputFieldWrapperDiv shrink={shrink} error={!!error} isMobile={isMobile}>
               {startAdornment && <AdornmentWrapperDiv>{startAdornment}</AdornmentWrapperDiv>}
               <InputField
                  id={id}
                  ref={ref}
                  name={name}
                  type={showPassword ? 'text' : type}
                  value={value}
                  required={required}
                  aria-invalid={!!error}
                  aria-describedby={errorId}
                  onChange={handleChange}
                  error={error}
                  startAdornment={startAdornment}
                  isMobile={isMobile}
                  {...remainingProps}
               />
               {endAdornment && (
                  <AdornmentWrapperDiv className="end">
                     {type === 'password' ? PasswordControl : endAdornment}
                  </AdornmentWrapperDiv>
               )}
            </InputFieldWrapperDiv>
            {error && (
               <ErrorP
                  initial="pageInitial"
                  animate="pageAnimate"
                  exit="pageExit"
                  variants={{
                     pageInitial: {
                        opacity: 1,
                        x: 0,
                        y: -5,
                     },
                     pageAnimate: {
                        opacity: 1,
                        x: 0,
                        y: 0,
                     },
                     pageExit: {
                        opacity: 0,
                     },
                  }}
                  id={errorId}
               >
                  {error}
               </ErrorP>
            )}
         </Div>
      );
   },
);

Input.displayName = 'input';

export { Input };
