/* eslint-disable camelcase */
import { activateAccountApi } from 'api/auth';
import { useSnackbar } from 'context/snackbar/snackbar';
import { useEffect } from 'react';
import { Div } from 'components/styles';

import { useHistory, useParams } from 'react-router-dom';

const VerifyEmail = () => {
   const { token } = useParams<{ token: string }>();
   const { notify } = useSnackbar();
   const history = useHistory();

   useEffect(() => {
      (async () => {
         const result = await activateAccountApi(token as string);
         if (result.ok) {
            notify({ message: 'Account Activated', variant: 'success', duration: 10000 });
            return history.replace(`/login`);
         }
         notify({
            message: 'An Error occured!. Kindly try again',
            variant: 'error',
            duration: 10000,
         });

         history.replace('/login');
      })();
   }, []);

   return (
      <Div className="d-flex justify-content-center align-items-center vh-100">Redirecting...</Div>
   );
};

export default VerifyEmail;
