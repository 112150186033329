import styled from "@emotion/styled";

export const PermissionSidebarDetails = styled.div`
  line-height: 2.7rem;
  overflow: scroll;
`;

export const MoadalName = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 30px;
`;

export const TableStyled = styled.table`
  border: none !important;
  width: 100%;
`;

export const TableHeadStyled = styled.th`
  padding: 12px;
  border: none;
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  // width: 150px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

export const TableRowStyled = styled.tr`
  background: transparent;
  border-radius: 0;
`;

export const TableDataStyled = styled.td`
  padding: 15px 12px;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid #f1f1f1;
`;

export const THeadStyled = styled.thead``;
export const TBodyStyled = styled.tbody``;

export const TableWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 460px) {
    flex-direction: column;
  }
`;

export const SideCard = styled.div`
  background: #f1f1f1;
  border-radius: 12px;
  width: 246px;
  height: max-content;
  padding: 24px;
  text-align: center;
  width:100%
`;

export const SideCardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SideCardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const YearCount = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #fc5719;
`;

export const TotalAmountCard = styled.div`
  background: #f1f1f1;
  border-radius: 12px;
  // width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin: 16px;
`;

export const AmountCount = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

export const TotalTitle = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #fc5719;
`;

export const TotalAmount = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;
