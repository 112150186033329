/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Div, Span } from 'components/styles';
import { Button } from 'components/button/button';
import Logo from 'assets/images/logo.png';
import Logo_active from 'assets/images/logo-active.png';
import TimeIcon from 'assets/images/time.png';
import Dooted from 'assets/images/dotted.png';
import MailIcon from 'assets/images/mail.png';
import { Modal } from 'components/modal/modal';
import ActiveCallIcon from 'assets/images/active-call.png';
import { useHistory } from 'react-router-dom';
import Countdown from 'react-countdown';
import {
   HomePageStyle,
   HomePageContainer,
   TopHomePageCOntainer,
   LeftHomePageContainer,
   RightHomePageContainer,
   CountDownContainer,
} from './home-page.style';

// Random component
const Completionist = () => <span>You are good to go!</span>;

// Renderer callback with condition
const renderer: any = ({ days, hours, minutes, seconds, completed }) => {
   if (completed) {
      // Render a completed state
      return <Completionist />;
   }
   // Render a countdown
   return (
      <div className="timer">
         <Span className="mx-10">
            {String(days)
               ?.split('')
               .map((s: string) => (
                  <span className="days">{s}</span>
               ))}
            <span className="bottom-text">Days</span>
         </Span>
         <Span className="mx-10">
            {String(hours)
               ?.split('')
               .map((s: string) => (
                  <span className="hours">{s}</span>
               ))}
            <span className="bottom-text">Hours</span>
         </Span>
         <Span className="mx-10">
            {String(minutes)
               ?.split('')
               .map((s: string) => (
                  <span className="minutes">{s}</span>
               ))}
            <span className="bottom-text">Minutes</span>
         </Span>
         <Span className="mx-10">
            {String(seconds)
               ?.split('')
               .map((s: string) => (
                  <span className="seconds">{s}</span>
               ))}
            <span className="bottom-text">Seconds</span>
         </Span>
      </div>
   );
};

const HomePage = () => {
   const history = useHistory();
   const [modalVisibitility, setmodalVisibility] = useState(true);

   return (
      <HomePageStyle>
         <Modal
            background="#1b1b1bc0"
            visible={modalVisibitility}
            handleClose={() => setmodalVisibility(false)}
            height="300px"
            width="700px"
         >
            <CountDownContainer>
               <span className="text">Coming <span className="text_sub">soon</span></span>
               <Countdown date={Date.now() + 11463900000} renderer={renderer} intervalDelay={0} />
            </CountDownContainer>
         </Modal>
         <HomePageContainer>
            <TopHomePageCOntainer>
               <Div className="mx-auto mt-20">
                  <img src={Logo_active} alt="brand" width="150px" />
               </Div>
               <Div className="block_content">
               E-DocOnline facilitates the secure and automation of account information to Decision Makers for 
               financial verification as part of an application process
               </Div>
               <Div>
                  <Button className='btn_animate' onClick={() => history.push('/register')} variant="primary">
                     Connect to your bank
                  </Button>
               </Div>
            </TopHomePageCOntainer>
            <Div className="d-flex">
            <img className='ab_img ab_left' src={Dooted} alt="time" />
               <img className='ab_img ab_right vert-move' src={Dooted} alt="time" />
               <LeftHomePageContainer>                 
                  <Div className="mx-auto">
                     <img src={TimeIcon} alt="time" />
                  </Div>
                  <Div className="block_content">
                     E-Doc is registered and authorized by the Financial Conduct Authority(FCA)
                     under firm reference number 931731
                  </Div>
               </LeftHomePageContainer>
               <RightHomePageContainer>
                  <Div className="mb-20"><h3>Do you have a <span>question ?</span></h3> </Div>
                  <Div className="d-flex align-items-start mb-20">
                     <div className='contact_icon'><img src={MailIcon} alt="mail" /></div>
                     <Div className="ml-20"> info@e-doconline.co.uk</Div>
                  </Div>
                  <Div className="d-flex align-items-start">
                     <div className='contact_icon'><img src={ActiveCallIcon} alt="call" /></div>
                     <Div className="ml-20">02034445555</Div>
                  </Div>
               </RightHomePageContainer>
            </Div>
         </HomePageContainer>
      </HomePageStyle>
   );
};

export default HomePage;
