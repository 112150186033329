/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useState } from 'react';
import { Div } from 'components/styles/index';
import { Input } from 'components/input/input';
import { Button } from 'components/button/button';
import { Checkbox } from 'components/checkbox/checkbox';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { object, string, SchemaOf, ref } from 'yup';
import { Lynk } from 'components/styles';
import { BrandLogo } from 'components/logo/brandlogo';
import PI from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { registerApi } from 'api/auth';
import { useSnackbar } from 'context/snackbar/snackbar';
import { useHistory } from 'react-router-dom';
import { usePhoneNumberValidity } from 'hooks/phone';
import policy from 'assets/images/pdf/e-doc-privacy-policy.pdf';
import term from 'assets/images/pdf/e-doc-terms-conditions.pdf';

import {
   AuthStyle,
   FormContainer,
   InputWrapper,
   BGImage,
   BgWhite,
   BgContainer,
   Form,
   HeadingOne,
   HeadingTwo,
} from './auth.style';

const PhoneInput: any = PI;

export interface IRegisterData {
   firstName: string;
   lastName: string;
   mobileNumber: string;
   email: string;
   password: string;
   confirm_password?: string;
   company?: string;
}

const Register = () => {
   const { phoneNumberValidity, getPhoneNumberValidity } = usePhoneNumberValidity();

   const schema: SchemaOf<IRegisterData> = object().shape({
      email: string().required('Email is required').email('Please enter valid email'),
      firstName: string().required('First name is required'),
      lastName: string().required('Last name is required'),
      mobileNumber: string().required('Mobile number  is required'),
      password: string().required('Password is required').min(12).max(30),
      confirm_password: string()
         .notRequired()
         .oneOf([ref('password'), null], 'passwords does not match'),
      company: string().notRequired(),
   });

   const [loading, setLoading] = useState(false);
   const { register, errors, handleSubmit, reset, control } = useForm<IRegisterData>({
      mode: 'onChange',
      resolver: yupResolver(schema),
   });

   const { notify } = useSnackbar();
   const [checkboxValue, setCheckboxValue] = useState(false);
   const history = useHistory();

   async function onSubmit(payload: IRegisterData) {
      if (!phoneNumberValidity) {
         return notify({
            message: 'Phone number is invalid',
            variant: 'error',
         });
      }
      if (payload.company === '') {
         delete payload.company;
      }
      
      setLoading(true);
      const result = await registerApi({ ...payload});
      console.log('result', result)
      if (result.status==200) {
         setLoading(false);
         reset();
         history.push({
            state: { email: payload.email },
            pathname: '/email-confirmation',
         });
         notify({ message: result.data.message, variant: 'success' });
         return;
      }else {
         setLoading(false);
         notify({
            message: result.data?.message,
            variant: 'error',
         });
      }
   }

   return (
      <AuthStyle className="d-flex justify-content-center align-items-center page_register position-relative">
         <BrandLogo className="absolute" />
         <BgContainer>
            <BgWhite />
            <BGImage />
         </BgContainer>
         <FormContainer className='register'>
            <HeadingOne>Hello</HeadingOne>
            <HeadingTwo>Please register your account</HeadingTwo>
            <Form onSubmit={handleSubmit(onSubmit)} className="flex-column">
               <InputWrapper className="col-2">
                  <Input
                     error={errors.firstName?.message}
                     ref={register}
                     id="first-name"
                     label="First Name"
                     placeholder="John"
                     name="firstName"
                  />
                  <Input
                     error={errors.lastName?.message}
                     ref={register}
                     id="last-name"
                     label="Last Name"
                     placeholder="Stones"
                     name="lastName"
                  />
               </InputWrapper>
               <InputWrapper>
                  <Input
                     error={errors.email?.message}
                     ref={register}
                     id="email"
                     label="Email"
                     placeholder="Yourmail@email.com"
                     name="email"
                  />
               </InputWrapper>
               <InputWrapper>
                  <span className="label">Phone Number</span>
                  <Controller
                     rules={{ required: true }}
                     as={
                        <PhoneInput
                           isValid={getPhoneNumberValidity}
                           onlyCountries={['ng', 'gb']}
                           country="gb"
                           name="mobileNumber"
                           className="phone-input"
                           containerClass="input-container"
                           specialLabel="Phone Number"
                           key={phoneNumberValidity}
                           inputStyle={{
                              paddingLeft: 70,
                              width: '100%',
                              height: '43px',
                           }}
                           buttonStyle={{
                              width: '60px',
                              display: 'flex',
                              justifyContent: 'center',
                              backgroundColor: '#fff',
                           }}
                        />
                     }
                     name="mobileNumber"
                     control={control}
                     defaultValue=""
                  />
                  <span className="error">
                     {errors.mobileNumber?.message ||
                        (!phoneNumberValidity && 'mobile number is invalid')}
                  </span>
               </InputWrapper>
               <InputWrapper>
                  <Input
                     name="company"
                     ref={register}
                     error={errors.company?.message}
                     id="company"
                     label="Company"
                     placeholder="Enter company's name"
                  />
               </InputWrapper>
               <InputWrapper>
                  <Input
                     name="password"
                     ref={register}
                     error={errors.password?.message}
                     id="create-password"
                     label="Create Password"
                     placeholder="Pasword 8+ characters"
                     endAdornment
                     type="password"
                  />
               </InputWrapper>

               <InputWrapper className="mt-20">
                  <Checkbox
                     name="terms"
                     onChange={() => setCheckboxValue(!checkboxValue)}
                     checked={checkboxValue}
                  >
                     By creating an account you agree to accept our
                     <a className="text_orenge text_decoration" href='https://s3.af-south-1.amazonaws.com/e-doconline.ng/documents/E-Doc+ng+Privacy+Policy.pdf' target="_blank">
                        &nbsp; Privacy Policy &nbsp;
                     </a>
                     and
                     <a className="text_orenge text_decoration" href={"https://s3.af-south-1.amazonaws.com/e-doconline.ng/documents/E-Doc+Ng+Terms+%26+Conditions.pdf"} target="_blank">
                        &nbsp; Terms of Service
                     </a>
                  </Checkbox>
               </InputWrapper>

               <InputWrapper className="mt-20">
                  <Button
                     fullWidth
                     variant="primary"
                     disabled={!checkboxValue || loading}
                     loading={loading}
                  >
                     Create Account
                  </Button>
               </InputWrapper>
            </Form>
            <Div className="text-center font-15">
               <Lynk to="/login">Are you an existing user? Login</Lynk>
            </Div>
         </FormContainer>
      </AuthStyle>
   );
};

export default Register;
