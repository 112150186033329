/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { OrangeBackground, SuccessContainer, Div, Span, TextGrey } from 'components/styles/index';
import { Button } from 'components/button/button';
import CheckedIcon from 'assets/images/checked.png';
import { useHistory } from 'react-router-dom';
import { DashboardHeader } from 'components/dashboard-header/dashboard-header';

const RedirectionSuccess = () => {
   const history = useHistory();

   return (<>
      <DashboardHeader />
      <OrangeBackground>
         
         <SuccessContainer>
            <div>
               <img src={CheckedIcon} alt="icon" />
            </div>
            <Div className="font-22 font-bold text-black">Thank You</Div>
            <Div className="font-16 font-regular">
               We have received the requested information from your selected account(s)
            </Div>
            <TextGrey className="font-16 font-regular">
               We will access your information from your accounts until{' '}
               <Span className="text-green">20 November 2020</Span>
            </TextGrey>
            <Div className="d-flex justify-content-center">
               <div>
                  <Button variant="primary" onClick={() => history.push('/dashboard')}>
                     View Dashboard
                  </Button>
               </div>
               <Div className="ml-20">
                  <Button variant="secondary" onClick={() => history.push('/manage-plan')}>
                     View Plan
                  </Button>
               </Div>
            </Div>
         </SuccessContainer>
      </OrangeBackground>
      </>
   );
};

export default RedirectionSuccess;
