import { Div, TextOrange, TextGrey } from 'components/styles/index';
import LockIcon from 'assets/images/lock.svg';
import { BrandLogo } from 'components/logo/brandlogo';
import { PermissionImg, SideBrandContainerStyle } from './style';

const SideBrandContainer = () => {
   return (
      <SideBrandContainerStyle>
         <Div className="d-grid-center h-500">
            <BrandLogo className="" />
            <TextGrey>
               E-DocOnline facilitates the secure and automation of account information to Decision Makers.
            </TextGrey>
            <Div className="flex-column w-75">
               <Div className='vert-move'>
                  <PermissionImg src={LockIcon} />
               </Div>
               <TextOrange>
                  HOW DOES THIS WORK? SECURE CONNECTION <br />
                  FCA AUTHORIZED
               </TextOrange>
            </Div>
         </Div>
      </SideBrandContainerStyle>
   );
};

export { SideBrandContainer };
