import { Div } from 'components/styles';

const NotFound = () => {
   return (
      <Div className="d-flex justify-content-center align-items-center font-regular vh-100 font-18">
         Page Not Found
      </Div>
   );
};

export { NotFound };
