import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useMemo, useState } from "react";
import { Input } from "components/input/input";
import { Navbar } from "components/navbar/navbar";
import { Button } from "components/button/button";
import { useForm } from "react-hook-form";
import { Lynk } from "components/styles/index";
import { Div } from "components/styles";
import { object, SchemaOf, string } from "yup";
import { useHistory } from "react-router-dom";
import { loginApi } from "api/auth";
import { useSnackbar } from "context/snackbar/snackbar";
import { saveCookie, saveUserInfoToStorage } from "utils/storage";
import { useAuth } from "context/auth";
import LogoActive from "assets/images/logo-active.png";
import decodeJwt from "jwt-decode";

import {
  AuthStyle,
  Form,
  InputWrapper,
  FormContainer,
  HeadingOne,
  HeadingTwo,
} from "./auth.style";

export interface ILoginData {
  email: string;
  password: string;
}

const Login: FC = () => {
  const schema: SchemaOf<ILoginData> = useMemo(
    () =>
      object().shape({
        email: string()
          .required("Email address is required")
          .email("Please enter valid email"),
        password: string().required("Password is required"),
      }),
    []
  );

  const { notify } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { register, errors, handleSubmit, reset } = useForm<ILoginData>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { setAuthenticatedUser } = useAuth();

  async function onSubmit(payload: ILoginData) {
    // remove after check page

    setLoading(true);
    const result = await loginApi(payload);
    if (result.ok) {
      saveCookie(result.data?.data?.accessToken);
      // saveCookie("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTIsIm5hbWUiOiJWaXZlayIsImVtYWlsIjoidmpjd29ybGQxQGdtYWlsLmNvbSIsImlhdCI6MTY0MDI2NTI1MiwiZXhwIjoxNjQwMjY2MTUyfQ.25qSaI8LN5jVizKqcpt9HuVnsc29zqYwovgXkhvTylo")
      // setAuthenticatedUser({firstname: "Niraj", lastname: "Patidar"});
      saveUserInfoToStorage(result.data?.data?.accessToken);
      let decodeData = decodeJwt(result?.data?.data?.accessToken);
      localStorage.setItem("<<<user-info>>>", JSON.stringify(decodeData));
      setLoading(false);
      reset();
      // history.push('/');
      notify({ message: "success", variant: "success" });
      history.push("/dashboard");
      return;
    }
    setLoading(false);

    if (result?.data?.code === 401) {
      notify({
        message: result?.data?.message || result.data?.message[0]?.message,
        variant: "error",
      });
    } else {
      notify({
        message:
          result?.data?.error?.message || result.data?.message[0]?.message,
        variant: "error",
      });
    }
  }

  return (
    <>
      <Navbar />
      <AuthStyle className="flex-row">
        <FormContainer className="login">
          <div className="logo-box">
            <a href="/">
              <img src={LogoActive} alt="logo" className="logo" />
            </a>
          </div>
          <HeadingOne className="text-center">Welcome back!</HeadingOne>
          {/* <HeadingTwo>Please Log in...</HeadingTwo> */}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper>
              <Input
                error={errors.email?.message}
                ref={register}
                id="email"
                label="Email Address"
                placeholder="Yourmail@email.com"
                name="email"
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                ref={register}
                error={errors.password?.message}
                id="password"
                label="Password"
                type="password"
                name="password"
                placeholder="password"
                endAdornment
              />
            </InputWrapper>
            <InputWrapper className="mt-20">
              <Button
                fullWidth
                variant="primary"
                loading={loading}
                disabled={loading}
              >
                Log in
              </Button>
            </InputWrapper>
          </Form>
          <Div className="text-center">
            <Lynk to="/forgot-password">Forgot password?</Lynk>
          </Div>
        </FormContainer>
      </AuthStyle>
    </>
  );
};

export default Login;
