// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/images/check.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_list__3QrBY>input[type=\"checkbox\"]+label {\n    display: block;\n    cursor: pointer;\n}\n\n.styles_list__3QrBY>input[type=\"checkbox\"] {\n    display: none;\n}\n\n.styles_list__3QrBY>input[type=\"checkbox\"]+label:before {\n    content: \" \";\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n    border: 0.1em solid #000;\n    border-radius: 0.2em;\n    display: inline-block;\n    width: 1.2em;\n    height: 1.2em;\n    padding-left: 0.1em;\n    margin-right: 2rem;\n    vertical-align: bottom;\n    color: transparent;\n    transition: 0.2s;\n}\n\n.styles_list__3QrBY>input[type=\"checkbox\"]:checked+label:before {\n    background-color: #FC5719;\n    border-color: #FC5719;\n    color: #fff;\n}", "",{"version":3,"sources":["webpack://src/pages/dashboard-overview/IncomeAndJobVerification/styles.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,2EAAyE;IACzE,wBAAwB;IACxB,oBAAoB;IACpB,qBAAqB;IACrB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,WAAW;AACf","sourcesContent":[".list>input[type=\"checkbox\"]+label {\n    display: block;\n    cursor: pointer;\n}\n\n.list>input[type=\"checkbox\"] {\n    display: none;\n}\n\n.list>input[type=\"checkbox\"]+label:before {\n    content: \" \";\n    background: url(../../../assets/images/check.svg) no-repeat center center;\n    border: 0.1em solid #000;\n    border-radius: 0.2em;\n    display: inline-block;\n    width: 1.2em;\n    height: 1.2em;\n    padding-left: 0.1em;\n    margin-right: 2rem;\n    vertical-align: bottom;\n    color: transparent;\n    transition: 0.2s;\n}\n\n.list>input[type=\"checkbox\"]:checked+label:before {\n    background-color: #FC5719;\n    border-color: #FC5719;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "styles_list__3QrBY"
};
export default ___CSS_LOADER_EXPORT___;
