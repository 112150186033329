import styled from "@emotion/styled";
import { Span } from "components/styles";

export const PageWrapper = styled.div`
  margin: auto;
`;

export const ListHeader = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background: #679b9b;
  margin: 0 auto 30px auto;
`;

export const TableCard = styled.div`
  background: white;
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const ThankButtonWrapper = styled.div`
  margin: 30px 0 10px 0;
`;

export const TableStyled = styled.table`
  border: 1px solid #010101 !important;
  width: 100%;
`;

export const TableHeadStyled = styled.th`
  padding: 12px;
  border: none;
  border: 1px solid #010101;
  // border-top: 1px solid #010101;
  width: 150px;
  color: #ed7d32;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
`;

export const TableRowStyled = styled.tr`
  background: transparent;
  border-radius: 0;
`;

export const TableDataStyled = styled.td`
  padding: 15px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  border: 1px solid #010101;
`;

export const THeadStyled = styled.thead``;
export const TBodyStyled = styled.tbody``;

export const TableWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 460px) {
    flex-direction: column;
  }
`;

export const AvailableDestinationsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    padding: 12px 0;
    border: 4px solid #2d2d2d;
  }
  tr {
    background: #212120;
    border-radius: 12px;
  }

  td:first-child,
  th:first-child {
    border-radius: 12px 0 0 12px;
  }

  td:last-child,
  th:last-child {
    border-radius: 0 12px 12px 0;
  }

  td:first-child,
  tr:first-child {
    border-radius: 12px 0 0 12px;
  }

  td:last-child,
  tr:last-child {
    border-radius: 0 12px 12px 0;
  }
`;

export const AvailableHeadingTitle = styled(Span)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fc5719;
  margin-left: 15px;

  ${({ theme }) => theme.media?.semiLarge} {
    margin-left: 0;
  }
`;

export const AvailableDestinationsData = styled.td`
  padding: 12px 12px;
  border: 4px solid #2d2d2d;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
`;