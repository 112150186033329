/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { Div, Span, TextGrey } from "components/styles";
import { Button } from "components/button/button";
import BarclaysLogo from "assets/images/barclays_logo.png";
import StarlingIcon from 'assets/images/starling.png';
import MonzoLogo from "assets/images/monzo_logo.png";
import SantanderLogo from "assets/images/santander_logo.png";
import NatwestLogo from "assets/images/natwest_logo.png";
import HsbcLogo from "assets/images/hsbc_logo.png";
import LLyodsLogo from "assets/images/lloyds.jpg";
import LLOYDSC_BUSINESSIcon from 'assets/images/lloyds_business.png';
import { DurationProgress } from "components/duration-progress/duration-progress";
import { DashboardHeader } from "components/dashboard-header/dashboard-header";
import { getConsents } from "api/bank";
import { PageLoader } from "components/page-loader/page-loader";
import { useHistory } from "react-router-dom";
import { getDifference } from "utils/date-function";
import moment from "moment";
import {
  DashboardStyle,
  DashboardContainer,
  BankDetailsCardStyle,
  BankCardStyle,
  BankDetailsStyle,
  DetailsBottomStyle,
} from "./manage-plan.style";
import "react-circular-progressbar/dist/styles.css";
import { PermissionDetails } from "../permission/permission-details";
import { AddPartner } from "../add-partner/add-partner";

export const logoConfig = {
  hsbc: HsbcLogo,
  barclays: BarclaysLogo,
  monzo: MonzoLogo,
  santander: SantanderLogo,
  natwest: NatwestLogo,
  lloyds: LLyodsLogo,
  lloyds_business: LLOYDSC_BUSINESSIcon,
  starling: StarlingIcon,
};
const data = [
  {
    id: 1,
    logo: BarclaysLogo,
    daysLeft: 10,
    status: "active",
    accountType: "Current Account",
    accountNumber: "35509930843",
    accessGained: "31 June 2020",
    lastAccessRefreshed: "20 May 2020",
    accessExpires: "20 May 2020",
  },
  {
    id: 2,
    logo: MonzoLogo,
    daysLeft: 10,
    status: "active",
    accountType: "Current Account",
    accountNumber: "35509930843",
    accessGained: "31 June 2020",
    lastAccessRefreshed: "20 May 2020",
    accessExpires: "20 May 2020",
  },
  {
    id: 3,
    logo: SantanderLogo,
    daysLeft: 10,
    status: "active",
    accountType: "Current Account",
    accountNumber: "35509930843",
    accessGained: "31 June 2020",
    lastAccessRefreshed: "20 May 2020",
    accessExpires: "20 May 2020",
  },
];

interface CardDetails {
  item: {
    logo: string;
    daysLeft: number;
    status: string;
    accountType: string;
    accountNumber: string;
    accessGained: string;
    lastAccessRefreshed: string;
    accessExpires: string;
  };
}

const BankDetails = (props) => {
  const {
    logo,
    daysLeft,
    status,
    accessExpires,
    accessGained,
    accountNumber,
    accountType,
    lastAccessRefreshed,
    consentExpiry,
    item,
  } = props;
  // const hit

  function daysRemaining(from) {
    const eventdate = moment(from);
    const todaysdate = moment();
    return eventdate.diff(todaysdate, "days");
  }
  const diff = getDifference(item.consentExpiry);
  return (
    <BankDetailsStyle>
      <Div>
        <img
          src={logoConfig[item?.bankName?.toLowerCase()]}
          alt={accountType}
        />
      </Div>
      <DurationProgress
        daysLeft={daysRemaining(item.consentExpiry)}
        status={diff >= 0 ? "Active" : "Expired"}
      />
      <DetailsBottomStyle>
        <Div className="my-20 d-flex justify-content-between">
          <TextGrey>Account Type</TextGrey>
          {/* <Span>{accountType}</Span> */}
          <Span>Current Account</Span>
        </Div>
        <Div className="my-20 d-flex justify-content-between">
          <TextGrey>Account number </TextGrey>
          <Span>{accountNumber}</Span>
        </Div>
        <Div className="my-20 d-flex justify-content-between">
          <TextGrey>Account gained </TextGrey>
          <Span>{accessGained}</Span>
        </Div>
        <Div className="my-20 d-flex justify-content-between">
          <TextGrey>Account Last refreshed </TextGrey>
          <Span>{moment(item.updatedAt).format("DD MMM YYYY")}</Span>
        </Div>
        <Div className="my-20 d-flex justify-content-between">
          <TextGrey>Access expires</TextGrey>
          <Span>{moment(item.consentExpiry).format("DD MMM YYYY")}</Span>
        </Div>
      </DetailsBottomStyle>
    </BankDetailsStyle>
  );
};

const ManagePlan = () => {
  const history = useHistory();
  const [consents, setConsents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [consent, setConsent] = useState("");

  const [sidebarVisibility, setSidebarVisibility] = useState({
    permission: false,
    addPartner: false,
  });

  function closeSidebar() {
    setSidebarVisibility({
      addPartner: false,
      permission: false,
    });
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getConsents({ bankName: "all" });
      if (result.ok) {
        setIsLoading(false);
        setConsents(result?.data?.data);
        return;
      }
      setIsLoading(false);
    })();
  }, []);

  const getSingleConsent = (bankName) => {
    (async () => {
      const result = await getConsents({ bankName: bankName || "" });

      if (result.ok) {
        setConsent(result?.data?.data[0]);

        setSidebarVisibility({
          permission: true,
          addPartner: false,
        });
      }
    })();
  };

  return (
    <>
      <DashboardHeader />
      <DashboardStyle>
        <PermissionDetails
          daysLeft={23}
          visible={sidebarVisibility.permission}
          handleClose={closeSidebar}
          status="Active"
          consentExpiry={consent}
        />
        <AddPartner
          visible={sidebarVisibility.addPartner}
          handleClose={closeSidebar}
        />
        <DashboardContainer>
          <Div className="my-50">
            <Div className="d-flex justify-content-between">
              {/* <TextGrey className="font-16 mt-10">
                        We, E-doconline, have your permission to access your data to offer financial
                        services
                     </TextGrey> */}
              <Div className="font-regular font-24">Manage Plan</Div>
              <Div>
                <Button
                  variant="primary"
                  onClick={() =>
                    // setSidebarVisibility({
                    //    permission: false,
                    //    addPartner: true,
                    // })
                    history.push("/permission")
                  }
                >
                  Add an Account
                </Button>
              </Div>
            </Div>
          </Div>

          <BankDetailsCardStyle>
            {/* {isLoading ? (
                     <Div
                        style={{ height: '100px' }}
                        className="font-regular font-18 text-grey d-flex align-items-center justify-content-center w-100"
                     >
                        <PageLoader />
                     </Div>
                  ) : (
                     <>
                        {data?.length < 1 ? (
                           <Div
                              style={{ height: '100px' }}
                              className="font-regular font-18 text-grey d-flex align-items-center justify-content-center w-100"
                           >
                              No Consent yet
                           </Div>
                        ) : (
                           data.map((item) => {
                              return (
                                 <BankCardStyle>
                                    <BankDetails item={item} />
                                    <button
                                       type="button"
                                       onClick={() =>
                                          setSidebarVisibility({
                                             permission: true,
                                             addPartner: false,
                                          })
                                       }
                                    >
                                       Manage
                                    </button>
                                 </BankCardStyle>
                              );
                           })
                        )}
                     </>
                  )} */}
            {isLoading ? (
              <Div
                style={{ height: "100px" }}
                className="font-regular font-18 text-grey d-flex align-items-center justify-content-center w-100"
              >
                <PageLoader />
              </Div>
            ) : (
              <>
                {consents?.length < 1 ? (
                  <Div
                    style={{ height: "100px" }}
                    className="font-regular font-18 text-grey d-flex align-items-center justify-content-center w-100"
                  >
                    No Consent yet
                  </Div>
                ) : (
                  consents.map((item, index) => {
                    return (
                      <BankCardStyle>
                        <BankDetails item={item} />
                        <Button
                          variant="primary"
                          type="button"
                          onClick={() => getSingleConsent("all")}
                        >
                          Manage
                        </Button>
                      </BankCardStyle>
                    );
                  })
                )}
              </>
            )}
          </BankDetailsCardStyle>
        </DashboardContainer>
      </DashboardStyle>
    </>
  );
};

export default ManagePlan;
