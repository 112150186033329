import React, { useRef, useState } from "react";
import { Input } from "components/input/input";
import "./Demo.css";
import { useHistory } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { PageLoader } from "components/page-loader/page-loader";
import { ButtonLoader } from "components/button-loader";
import { Div } from "components/styles";
import { AccordionTitle } from "pages/permission/permission.style";

const Demo = () => {
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [file, setFile]: any = useState();
  const [isLoader, setLoader] = useState(false);
  const ref = React.createRef<HTMLInputElement>()
  const [photoLoader, setPhotoLoader] = useState(false)
  const handleClick = () => {
    setLoader(true)
    setPage(page + 1);
    setTimeout(() => {
      setLoader(false)
    }, 2000);
  }
  return (
    <>
      {/* <!-- multistep form --> */}
      <form id="multistepsform">
        {/* <!-- progressbar --> */}
        <ul id="progressbar">
          <li className={`${page >= 2 && "active"}`}>BVN Number</li>
          <li className={`${page >= 2 && "active"}`}>Address Verification</li>
          <li className={`${page >= 3 && "active"}`}>Passport Verification</li>
        </ul>
        {/* <!-- fieldsets --> */}
        {!isLoader ? (
          <>
            {page === 1 && (
              <fieldset>
                <h2 className="fs-title">11 Digit BVN Number</h2>

                <div className="input-grp">
                  <label>11 Digit BVN Number</label>
                  <input
                    type="text"
                    name="bvn-number"
                    placeholder="Enter 11 Digit BVN Number"
                    id={""}
                  />
                </div>

                <div className="btn-grp">
                  <div />
                  <input
                    type="button"
                    name="next"
                    className="next action-button"
                    value="Next"
                    onClick={handleClick}
                  />
                </div>
              </fieldset>
            )}
            {page === 2 && (
              <fieldset>
                <h2 className="fs-title">Personal Details</h2>
                <div className="pr-detail">
                  <div className="input-grp">
                    <label>First Name</label>
                    <input type="text" name="fname" placeholder="First Name" />
                  </div>

                  <div className="input-grp">
                    <label>Last Name</label>
                    <input type="text" name="lname" placeholder="Last Name" />
                  </div>
                </div>

                <div className="pr-detail">
                  <div className="input-grp">
                    <label>DOB</label>
                    <input type="text" name="dob" placeholder="DOB" />
                  </div>

                  <div className="input-grp">
                    <label>Gender</label>
                    <input type="text" name="gender" placeholder="Gender" />
                  </div>
                </div>

                <div className="input-grp">
                  <label>Adress</label>
                  <textarea name="address" placeholder="Address"></textarea>
                </div>

                <div className="btn-grp">
                  <input
                    type="button"
                    name="previous"
                    className="previous action-button"
                    value="Previous"
                    onClick={() => {
                      setPage(page - 1);
                    }}
                  />

                  <input
                    type="button"
                    name="next"
                    className="next action-button"
                    value="Next"
                    onClick={handleClick}
                  />
                </div>
              </fieldset>
            )}
            {page === 3 && (
              <fieldset>
                <h2 className="fs-title">Passport Verification</h2>

                <div className="input-grp-img">
                  <label>Passport Upload</label>
                  <input
                    type="file"
                    style={{
                      display: "none",
                    }}
                    ref={ref}
                    name="address"
                    onChange={(e) => {
                      setPhotoLoader(true)
                      setTimeout(() => {
                        setPhotoLoader(false)
                        setFile(e.target.files && e.target.files[0])
                      }, 5000);
                    }
                    }
                    placeholder="Address"
                  />
                  {!photoLoader ? (
                    <>
                      {file ? (
                        <>
                        <div className="passport-img-wrapper">
                          <img
                            src={URL.createObjectURL(file)}
                            alt=""
                            onClick={() => ref?.current && ref?.current.click()}
                            className="passport-img"
                          />
                        </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="img-upload"
                            onClick={() => ref?.current && ref?.current.click()}
                            >
                            <div>
                              <MdAdd color="white" size={80} />
                            </div>
                          </div>
                        </>
                      )}

                    </>
                  ) : (
                    
                    <div className="passport-img-wrapper" style={{height: "200px",width: "200px"}}>
                    <ButtonLoader/>
                    </div>
                  )}
                </div>
                {file && (
                <div style={{color:"green"}}>
                  <label>Passport upload successfully.</label>
                </div>
                )}

                <div className="btn-grp">
                  <input
                    type="button"
                    name="previous"
                    className="previous action-button"
                    value="Previous"
                    onClick={() => {
                      setPage(page - 1);
                    }}
                  />
                  <input
                    type="button"
                    name="submit"
                    className="submit action-button"
                    value="Submit"
                    onClick={() => {
                      setLoader(true)
                      setTimeout(() => {
                        // setLoader(false)
                        // eslint-disable-next-line no-restricted-globals
                        location.replace("https://connect.e-doconline.co.uk/login");
                      }, 2000);
                    }}
                  />
                </div>
              </fieldset>
            )}
          </>
        ) : (
          <Div
          style={{ height: "100px" }}
          className="font-regular font-18 text-grey d-flex align-items-center justify-content-center w-100"
        >
          <PageLoader />
        </Div>
        )}

      </form>
    </>
  );
};

export default Demo;
