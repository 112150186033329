import { useEffect, useState } from 'react';

function usePhoneNumberValidity() {
   const [phoneNumberValidity, setPhoneNumberValidity] = useState<boolean>(true);

   useEffect(() => {
      setPhoneNumberValidity(true);
   }, []);

   function getPhoneNumberValidity(value: string, country: Record<string, string>) {
      let isValid = true;

      if (!value?.match(/^(234)[1-9][0-9]{9}$/i) && country.iso2 === 'ng') {
         isValid = false;
      } else if (!value?.match(/^(44)[1-9][0-9]{9}$/i) && country.iso2 === 'gb') {
         isValid = false;
      } else {
         isValid = true;
      }
      setPhoneNumberValidity(isValid);
   }

   return { phoneNumberValidity, setPhoneNumberValidity, getPhoneNumberValidity };
}

export { usePhoneNumberValidity };
