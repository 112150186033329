/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { css, keyframes } from '@emotion/react';
import { FiX } from 'react-icons/fi';
import styled from '@emotion/styled';
import { useSnackbar } from 'context/snackbar/snackbar';

const fadein = keyframes`
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 2.5rem;
      opacity: 1;
    }
`;

const fadeout = keyframes`
    from {
      top: 2.5rem;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
`;

type variantType = { variant: any };

function variantColor({ variant }: variantType) {
   switch (variant) {
      case 'success':
         return css`
            background-color: #2aa22a;
         `;
      case 'error':
         return css`
            background-color: #ea4242;
         `;
      default:
      case 'info':
         return css`
            background-color: #f2912e;
         `;
   }
}

const Container = styled.div<{ duration: string; variant: any }>`
   position: fixed;
   z-index: 1000;
   top: 2.5rem;
   left: 50%;
   transform: translateX(-50%);
   height: auto;
   padding: 1.3rem 2.3rem;
   border-radius: 3px;
   border: transparent;

   color: white;
   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
   font-size: 1.6rem;
   width: 420px;
   font-weight: 500;
   white-space: pre-line;

   @media (max-width: 480px) {
      width: 93%;
      font-size: 1.3rem;
      padding: 1rem 2rem;
      white-space: pre;
   }

   ${variantColor};

   display: flex;
   justify-content: space-between;
   align-items: center;

   animation: ${fadein} 0.5s, ${fadeout} 0.5s ${({ duration }) => duration};
`;

const Button = styled.button`
   display: flex;
   justify-content: center;
   align-items: center;
   margin-left: 0.875rem;
   padding: 0;
   margin-left: 0.8rem;
   height: 1.75rem;
   width: 1.75rem;
   text-align: center;
   border: none;
   border-radius: 50%;
   background-color: transparent;
   color: white;
   cursor: pointer;

   &:hover {
      background-color: hsl(210.0000000000001, 1.9230769230769231%, 20.392156862745097%);
   }
`;

const SnackbarProvider = () => {
   const { state, closeNotify } = useSnackbar();

   const { message, persist, variant, duration, toggle: show } = state;

   const time = `${((duration as number) - 500) / 1000}s`;

   let TIMER: any;

   function handleTimeout() {
      TIMER = persist
         ? true
         : setTimeout(() => {
              closeNotify();
           }, duration);
   }

   function handleClose() {
      clearTimeout(TIMER);
      closeNotify();
   }

   useEffect(() => {
      if (show) {
         handleTimeout();
      }
      return () => {
         clearTimeout(TIMER);
      };
   }, [show, TIMER]);

   return show ? (
      <Container duration={time} variant={variant}>
         <p>{message}</p>
         <Button onClick={handleClose}>
            <FiX />
         </Button>
      </Container>
   ) : (
      <span />
   );
};

export default SnackbarProvider;
