import { getAccountData, getClientConsents, getUserData } from "api/bank";
import { Button } from "components/button/button";
import { DashboardOverviewHeader } from "components/dashboard-overview/dashoard-overview-header";
import { ProgresBarContainer } from "components/duration-progress/duration-progress.style";
import { PageLoader } from "components/page-loader/page-loader";
import { useSnackbar } from "context/snackbar/snackbar";
import moment from "moment";
import { PermissionDetails } from "pages/dashboard/permission/permission-details";
import React, { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { MdChevronRight } from "react-icons/md";
import { getUserInfoFromStorage } from "utils/storage";
import {
  CardHeading,
  CardInfo,
  CardInfoHeading1,
  CardInfoHeading2,
  CardInfoHeading3,
  CardInfoHeading4,
  CardListItem,
  CardListWrapper,
  CircularProgressMainWrapper,
  CircularProgressWrapper,
  ContentCard,
  FilterSection,
  HeaderTop,
  HeadingTitle,
  LastRefresh,
  MainWrapper,
  OverviewContent,
  OverviewWrapper,
  RightCardItem,
} from "./dashboard.overview.style";
import { MonthlyAffordability } from "./tabs/monthly-affordability";
import { MonthlyExpenses } from "./tabs/monthly-expenses";
import { MonthlyIncome } from "./tabs/monthly-income";

const DashboardOverview = () => {
  const [incomeTab, setIncomeTab] = useState(false);
  const { notify } = useSnackbar();
  const [data, setData]: any = useState();
  const [incomeAmount, setIncomeAmount] = useState(0);
  const [expensesAmount, setExpensesAmount] = useState(0);
  const [expensesData, setExpensesData]: any = useState([]);
  const [incomesData, setIncomesData]: any = useState([]);
  const [affordabilityData, setAffordabilityData]: any = useState([]);
  const [loader, setLoader] = useState(false);

  const [monthlyTransaction, setMonthlyTransaction] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const userData = JSON.parse(
          localStorage.getItem("<<<user-info>>>") as string
        );
        setLoader(true);
        const result = await getClientConsents(userData?.id);
        if (result.ok) {
          let bankData = {
            bankName: result?.data?.data[0]?.bankName,
            userId: userData?.id,
          };
          let accountData = await getAccountData(bankData);
          if (accountData.ok) {
            let payload = {
              accountId: accountData?.data?.data.Data.Account[0].AccountId,
              bankName: result?.data?.data[0]?.bankName,
              durationInMonths: 6,
              userId: userData?.id,
            };
            let res = await getUserData(payload);
            if (res.ok) {
              setLoader(false);
              setIncomeAmount(res?.data?.data?.TotalIncome);
              setExpensesAmount(res?.data?.data?.TotalExpense);
              setMonthlyTransaction(res?.data?.data?.MonthlyTransactions);
            } else {
              setLoader(false);
              notify({
                message:
                  res?.data?.data.data.message || "Something went wrong!",
                variant: "error",
              });
            }
          }
        } else {
          setLoader(false);
          notify({
            message: result?.data?.data.data.message || "Something went wrong!",
            variant: "error",
          });
        }
      } catch (err) {
        console.log("get errror", err);
      }
    })();
  }, []);
  useEffect(() => {
    let incomesData: any = [];
    let expensesData: any = [];
    let affordabilityData: any = [];
    Object.keys(monthlyTransaction).length &&
      Object.keys(monthlyTransaction).map((item) => {
        let creditData = monthlyTransaction[item].filter((v) => {
          return v.CreditDebitIndicator === "Credit";
        });
        let debitData = monthlyTransaction[item].filter((v) => {
          return v.CreditDebitIndicator === "Debit";
        });
        let incomes =
          creditData.length &&
          creditData.reduce(
            (a, value) => a + parseFloat(value.Amount.Amount),
            0
          );
        let expenses =
          debitData.length &&
          debitData.reduce(
            (a, value) => a + parseFloat(value.Amount.Amount),
            0
          );
        incomesData.push({
          month: moment(item, "M-YYYY").format("MMMM"),
          amount: incomes.toFixed(2),
        });
        expensesData.push({
          month: moment(item, "M-YYYY").format("MMMM"),
          amount: expenses.toFixed(2),
        });
        affordabilityData.push({
          month: moment(item, "M-YYYY").format("MMMM"),
          amount: (incomes - expenses).toFixed(2),
        });
      });
    incomesData.reverse();
    expensesData.reverse();
    affordabilityData.reverse();
    setIncomesData(incomesData);
    setExpensesData(expensesData);
    setAffordabilityData(affordabilityData);
  }, [monthlyTransaction]);
  useEffect(() => {
    console.log("data", data);
  }, [setData]);

  const handleIncomeTab = () => {
    setIncomeTab(true);
  };
  const closeIncomeTab = () => {
    setIncomeTab(false);
  };

  const [expenses, setExpenses] = useState(false);
  const handleExpensesTab = () => {
    setExpenses(true);
  };
  const closeExpenses = () => {
    setExpenses(false);
  };

  const [affordability, setAffordability] = useState(false);
  const handleAffordabilityTab = () => {
    setAffordability(true);
  };
  const closeAffordability = () => {
    setAffordability(false);
  };

  return (
    <>
      {!loader ? (
        <>
          {" "}
          <OverviewWrapper>
            <DashboardOverviewHeader
              incomesData={incomesData}
              incomeAmount={incomeAmount}
              expensesData={expensesData}
              expensesAmount={expensesAmount}
              affordabilityData={affordabilityData}
              affordabilityAmount={incomeAmount - expensesAmount}
            />

            <MonthlyIncome
              visible={incomeTab}
              incomesData={incomesData}
              incomeAmount={incomeAmount}
              handleClose={closeIncomeTab}
            />

            <MonthlyExpenses
              visible={expenses}
              expensesData={expensesData}
              expensesAmount={expensesAmount}
              handleClose={closeExpenses}
            />

            <MonthlyAffordability
              visible={affordability}
              affordabilityData={affordabilityData}
              affordabilityAmount={incomeAmount - expensesAmount}
              handleClose={closeAffordability}
            />

            <MainWrapper>
              {/* <HeaderTop>
          <LastRefresh>
            <div>
              <span className="ref-text">This page was last refreshed on</span>{" "}
              Aug 04, 2022 at 3:15 PM
            </div>
            <Button className="btn-update">Update consent</Button>
          </LastRefresh>

          <FilterSection>
            <span>Filter Period:</span>
            <Button className="btn-head" variant="secondary">
              1 Month
            </Button>
            <Button className="btn-head" variant="secondary">
              2 Months
            </Button>
            <Button className="btn-head" variant="secondary">
              3 Months
            </Button>
            <Button className="btn-head" variant="secondary">
              4 Months
            </Button>
          </FilterSection>
        </HeaderTop> */}

              <OverviewContent>
                <ContentCard>
                  <CardHeading onClick={() => handleIncomeTab()}>
                    <HeadingTitle>Income</HeadingTitle>
                    <MdChevronRight size={24} />
                  </CardHeading>
                  <CardInfo>
                    <CardInfoHeading3>Monthly Income</CardInfoHeading3>
                    <CardInfoHeading1>
                      £ {incomeAmount.toFixed(2)}
                    </CardInfoHeading1>
                  </CardInfo>

                  <CardInfo>
                    <CardInfoHeading2>Income Stability</CardInfoHeading2>
                    <CardInfoHeading4>
                      Based on 2 sources: 2 recurring incomes
                    </CardInfoHeading4>
                  </CardInfo>

                  <CircularProgressMainWrapper>
                    <CircularProgressWrapper>
                      <CardInfoHeading2>
                        Amount
                        <br />
                        Consistency
                      </CardInfoHeading2>
                      <ProgresBarContainer>
                        <CircularProgressbar
                          strokeWidth={8}
                          styles={buildStyles({
                            textColor: "#000",
                            pathColor: "#40EA8F",
                            trailColor: "#DEDEDE",
                          })}
                          value={99}
                          text="99"
                        />
                      </ProgresBarContainer>
                    </CircularProgressWrapper>

                    <CircularProgressWrapper>
                      <CardInfoHeading2>Regularity</CardInfoHeading2>
                      <ProgresBarContainer>
                        <CircularProgressbar
                          strokeWidth={8}
                          styles={buildStyles({
                            textColor: "#000",
                            pathColor: "#40EA8F",
                            trailColor: "#DEDEDE",
                          })}
                          value={99}
                          text="99"
                        />
                      </ProgresBarContainer>
                    </CircularProgressWrapper>

                    <CircularProgressWrapper>
                      <CardInfoHeading2>Longevity</CardInfoHeading2>
                      <ProgresBarContainer>
                        <CircularProgressbar
                          strokeWidth={8}
                          styles={buildStyles({
                            textColor: "#000",
                            pathColor: "#ED7D32",
                            trailColor: "#DEDEDE",
                          })}
                          value={55}
                          text="55"
                        />
                      </ProgresBarContainer>
                    </CircularProgressWrapper>
                  </CircularProgressMainWrapper>
                </ContentCard>

                <ContentCard>
                  <CardHeading onClick={() => handleExpensesTab()}>
                    <HeadingTitle>Expenses</HeadingTitle>
                    <MdChevronRight size={24} />
                  </CardHeading>

                  <CardInfo>
                    <CardInfoHeading3>Monthly Expenses</CardInfoHeading3>
                    <CardInfoHeading1>
                      £ {expensesAmount.toFixed(2)}
                    </CardInfoHeading1>
                  </CardInfo>

                  <CardListWrapper>
                    <CardListItem>
                      <CardInfoHeading2>Expenses</CardInfoHeading2>

                      <RightCardItem>
                        <CardInfoHeading2>
                          {" "}
                          £ {expensesAmount.toFixed(2)}
                        </CardInfoHeading2>
                        <CardInfoHeading4>Monthly</CardInfoHeading4>
                      </RightCardItem>
                    </CardListItem>
                  </CardListWrapper>
                </ContentCard>

                <ContentCard>
                  <CardHeading onClick={() => handleAffordabilityTab()}>
                    <HeadingTitle>Affordability</HeadingTitle>
                    <MdChevronRight size={24} />
                  </CardHeading>

                  <CardInfo>
                    <CardInfoHeading3>Monthly Income</CardInfoHeading3>
                    <CardInfoHeading1>
                      £ {(incomeAmount - expensesAmount).toFixed(2)}
                    </CardInfoHeading1>
                  </CardInfo>

                  <CardListWrapper>
                    <CardListItem>
                      <CardInfoHeading2>Income</CardInfoHeading2>

                      <RightCardItem>
                        <CardInfoHeading2>
                          {" "}
                          £ {incomeAmount.toFixed(2)}
                        </CardInfoHeading2>
                        <CardInfoHeading4>Monthly</CardInfoHeading4>
                      </RightCardItem>
                    </CardListItem>
                  </CardListWrapper>

                  <CardListWrapper>
                    <CardListItem>
                      <CardInfoHeading2>Expenses</CardInfoHeading2>

                      <RightCardItem>
                        <CardInfoHeading2>
                          {" "}
                          £ {expensesAmount.toFixed(2)}
                        </CardInfoHeading2>
                        <CardInfoHeading4>Monthly</CardInfoHeading4>
                      </RightCardItem>
                    </CardListItem>
                  </CardListWrapper>
                </ContentCard>
              </OverviewContent>
            </MainWrapper>
          </OverviewWrapper>
        </>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default DashboardOverview;
