/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { useState } from 'react';
import {
   MainHeading,
   HeadingTwo,
   Div,
   Lynk,
   TextGrey,
   HeadingOne,
   OrangeBackground,
   GridContainer,
   RightContainer,
   Label,
} from 'components/styles/index';
import AccountIcon from 'assets/images/credit-card.svg';
import CalculatorIcon from 'assets/images/calculator.svg';
import { useAuth } from 'context/auth';
import FileIcon from 'assets/images/file.svg';
import { useSnackbar } from 'context/snackbar/snackbar';
import BalanceIcon from 'assets/images/balance.svg';
import { SideBrandContainer } from 'components/side-brand-container/side-brand-container';
import { Button } from 'components/button/button';
import moment from 'moment';
import { DashboardHeader } from 'components/dashboard-header/dashboard-header';
import {
   AccordionTitle,
   AccordionTextContent,
   AccordionContainer,
   PermissionTitle,
   PermissionTextContent,
   PermissionsStyle,
   PermissionIcon,
   PermissionStyle,
   AccordionsContainer,
   PermsissonContentStyle,
} from './permission.style';
import { PermissionModal } from './permission-modal';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from 'components/input/input';



interface IAccordionProps {
   textContent: string;
   title: string;
}

export const permissonContent = [
   {
      id: 1,
      icon: AccountIcon,
      title: 'Your accounts',
      brief:
         'For you to use this service E-DocOnline needs to access information from your accounts at your bank.',
      permissions: ['ReadAccountsDetail'],
   },
   {
      id: 2,
      icon: CalculatorIcon,
      title: 'Your transactions',
      brief:
         'For you to use this service E-DocOnline needs to access information from your accounts at your bank.',
      permissions: ['ReadTransactionsCredits', 'ReadTransactionsDebits', 'ReadTransactionsDetail'],
   },
   {
      id: 3,
      icon: FileIcon,
      title: 'Your statement',
      brief:
         'For you to use this service E-DocOnline needs to access information from your accounts at your bank.',
      permissions: ['ReadStatementsDetail', 'ReadStatementsBasic'],
   },
   {
      id: 4,
      icon: BalanceIcon,
      title: 'Your balances',
      brief:
         'For you to use this service E-DocOnline needs to access information from your accounts at your bank.',
      permissions: ['ReadBalances', 'ReadAccountsDetail'],
   },
];

export const productContent = [
   {
      id: 1,
      icon: AccountIcon,
      title: 'KYC',
      brief: '',
      permissions: ['ReadAccountsDetail'],
   },
];

const Accordion = ({ textContent, title }: IAccordionProps) => {
   const [toggle, setToggle] = useState(true);

   return (
      <AccordionContainer>
         <AccordionTitle onClick={() => setToggle(!toggle)}>
            <span>{title}</span>
            <button type="button" className={toggle ? 'animate' : ''}>
               {toggle ? '-' : '+'}
            </button>
         </AccordionTitle>
         <AccordionTextContent className={`hide ${toggle ? 'animate' : ''}`}>
            {textContent}
         </AccordionTextContent>
      </AccordionContainer>
   );
};

export const PermissionAccordion = ({ permission, handlePermissions, permissions,setCheckKYC }: any) => {

   return (
      <>
         <PermissionStyle>
            <Div className="d-flex justify-content-between">
               <PermissionIcon src={permission.icon} />
               <PermissionTitle htmlFor={permission.title}>{permission.title}</PermissionTitle>
               <input
                  id={permission.title}
                  type="checkbox"
                  value={permission.title}
                  name="permission"
                  className="checkbox"
                  onChange={(e) => {
                     if(setCheckKYC){
                        setCheckKYC(e.target.checked);
                     }
                     if(handlePermissions){
                        handlePermissions(e, permission.permissions);
                     }
                  }}
               />
            </Div>
         </PermissionStyle>

         <PermsissonContentStyle>
            <PermissionTextContent>{permission.brief}</PermissionTextContent>
         </PermsissonContentStyle>
      </>
   );
};

const Permission = () => {
   const updateConsent = JSON.parse(window.localStorage.getItem('updateConsent') || '{}');

   let accountRequest;
   if (Object.keys(updateConsent).length !== 0) {
      accountRequest = JSON.parse(updateConsent.accountRequest);

   }

   const [transactionFromDateTime, setTransactionFromDateTime] = useState(accountRequest ? new Date(accountRequest.TransactionFromDateTime) : new Date());
   const [transactionToDateTime, setTransactionToDateTime] = useState(accountRequest ? new Date(accountRequest.TransactionToDateTime) : new Date());
   const [inputChecked, setInputChecked] = useState(false);
   const [isProceeding, setIsProceeding] = useState(false);
   const [checkKYC,setCheckKYC] = useState(false)
   const [houseNumber,setHouseNumber] = useState()
   const [permissionModalVisibility, setPermissionVisibility] = useState(false);
   const [permissions, setPermissions] = useState<string[]>(accountRequest ? accountRequest.Permissions : []);
   const { notify } = useSnackbar();
   const { logout } = useAuth();

   async function handleProceed() {
      if (!inputChecked) {
         notify({
            message: 'Kindly select a permission to proceed',
            duration: 3000,
            variant: 'info',
         });
         return;
      }

      setPermissionVisibility(true);
      setIsProceeding(true);
      setIsProceeding(false);
   }

   function permissionOperations(arr, isChecked) {
      const otherPermissions = permissions.filter((item) => !arr.includes(item));
      setInputChecked(isChecked);

      if (isChecked) {
         setPermissions([...permissions, ...arr]);
      } else {
         setPermissions([...otherPermissions]);
      }
   }

   function handlePermissions(e: any, perms) {
      switch (e.target.value) {
         case 'Your statement':
            permissionOperations(perms, e.target.checked);
            break;
         case 'Your transactions':
            permissionOperations(perms, e.target.checked);
            break;
         case 'Your balances':
            permissionOperations(perms, e.target.checked);
            break;
         case 'Your accounts':
            permissionOperations(perms, e.target.checked);

            break;
         default:
            console.log('An error just occured');
      }
   }
const handleChange =(e)=>{
   setHouseNumber(e.target.value);
}

   return (
      <>
         <DashboardHeader />
         {!permissionModalVisibility ? <OrangeBackground>
            <GridContainer>
               <SideBrandContainer />
               <RightContainer>
                  <MainHeading className="center">Permission to request access</MainHeading>
                  <HeadingOne className="center">
                     For you to use this service E-DocOnline needs to access information from your
                     accounts at your bank.
                  </HeadingOne>
                  <AccordionsContainer>
                     <Accordion
                        title="Why we need your data"
                        textContent="E-DocOnline will securely access Open Banking gateways for some banks to access account information. E-DocOnline is a Financial Conduct Authority (FCA) authorised Account Information Service Provider (AISP)."
                     />
                     <Accordion
                        title="How we will use the data we collect"
                        textContent="E-DocOnline can share your Account Information to Decision Makers you authorise us to for financial verification as part of an application process.  There’s no cost to these connections and you can get up to 12 months of historical transactions. As an authorised AISP, E-DocOnline has read-only access to your bank account information and we are unable to move your money or see your credentials."
                     />
                  </AccordionsContainer>

                  <HeadingTwo className="purple">E-DocOnline needs your permission to read</HeadingTwo>
                  <PermissionsStyle>
                     {permissonContent.map((permission) => (
                        <>
                           <PermissionAccordion
                              transactionFromDateTime={transactionFromDateTime}
                              transactionToDateTime={transactionToDateTime}
                              setTransactionFromDateTime={setTransactionFromDateTime}
                              setTransactionToDateTime={setTransactionToDateTime}
                              permission={permission}
                              handlePermissions={handlePermissions}
                              permissions={permissions}
                           />
                        </>
                     ))}
                  </PermissionsStyle>
                  <PermissionsStyle>
                     {productContent.map((permission) => (
                        <>
                           <PermissionAccordion
                              transactionFromDateTime={transactionFromDateTime}
                              transactionToDateTime={transactionToDateTime}
                              setTransactionFromDateTime={setTransactionFromDateTime}
                              setTransactionToDateTime={setTransactionToDateTime}
                              permission={permission}
                              // handlePermissions={handlePermissions}
                              setCheckKYC={setCheckKYC}
                           />
                        </>
                     ))}
                  </PermissionsStyle>
                  {checkKYC ? (
                  <Div style={{margin: "0 0 0 50px"}}>
                     <Input
                        // error={errors.email?.message}
                        // ref={register}
                        id="text"
                        label="Company House Number"
                        placeholder=""
                        name="text"
                        onChange={handleChange}
                     />

                  </Div>
                           ):(
                              <></>
                           )}

                  <Div className="flex-row justify-content-start my-20 ml-40">
                     <Div className="mr-70">
                        <Div className="text-grey">You are sharing &nbsp; your data for</Div>
                        <Div className="text-orange">90 days</Div>
                     </Div>
                     <Div>
                        <Div className="text-grey">We will add your data until</Div>
                        <Div className="text-orange">
                           {moment().add(89, 'days').format('Do MMM YYYY')}
                        </Div>
                     </Div>
                  </Div>

                  {/* <HeadingTwo className="purple">E-DocOnline Product</HeadingTwo> */}

                 

             

                  <Div className="mt-80 text-center text-grey">
                     <p>Continue to your bank to allow to access this data under these terms?</p>
                  </Div>
                  <Div className="text-center w-60 mt-30 flex-row">
                     <Button type="button" variant="secondary" fullWidth onClick={() => logout()}>
                        Cancel
                     </Button>
                     <Div className="ml-20" />
                     <Button
                        variant="primary"
                        type="button"
                        fullWidth
                        onClick={handleProceed}
                        loading={isProceeding}
                        disabled={isProceeding}
                     >
                        Allow
                     </Button>
                  </Div>
                  <TextGrey>
                     <Div className="text-center w-80 mt-40 font-15">
                     By clicking &rdquo;Allow&rdquo;  you are permitting E-DocOnline to access 
                     and share account information from your bank account.
                      You also agree to our 
                      <Lynk to="https://s3.af-south-1.amazonaws.com/e-doconline.ng/documents/E-Doc+Ng+Terms+%26+Conditions.pdf"> End User Terms of use</Lynk> and
                        <Lynk to="https://s3.af-south-1.amazonaws.com/e-doconline.ng/documents/E-Doc+ng+Privacy+Policy.pdf">Privacy Policy</Lynk>.


                        {/* By clicking &rdquo;Allow&rdquo; you are permitting E-DocOnline to access
                        account Information from your bank account and share it with E-DocOnline. You
                        also agree to our
                        <Lynk to="/terms"> End User Terms of use</Lynk> and
                        <Lynk to="/privacy">Privacy Policy</Lynk> */}
                     </Div>
                  </TextGrey>
               </RightContainer>
            </GridContainer>
         </OrangeBackground> :
            <OrangeBackground>

               <PermissionModal
                  visible={permissionModalVisibility}
                  handleClose={() => setPermissionVisibility(false)}
                  inputChecked={inputChecked}
                  permissions={permissions}
                  houseNumber={houseNumber}
               />

            </OrangeBackground>}
      </>
   );
};

export default Permission;
