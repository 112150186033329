import { useEffect, useState, useRef } from "react";
import { Div, Span } from "components/styles";
import { Button } from "components/button/button";
import AccountIcon from "assets/images/account.svg";
import PersonIcon from "assets/images/person.png";
import Frame from "assets/images/frame.svg";
import Delete from "assets/images/delete.png";
import moment from "moment";
import { PageLoader } from "components/page-loader/page-loader";
import { useAuth } from "context/auth";
import { useClickAway } from "react-use";
import { useSnackbar } from "context/snackbar/snackbar";
import { useHistory } from "react-router-dom";
import "./style.css";
import CaretWhite from "assets/images/caret-white.svg";
import Caret from "assets/images/caret.svg";
import {
  getConsents,
  getAccount,
  getBalance,
  getTransaction,
  sendConsentData,
  getStatement,
  getAccountById,
} from "api/bank";
import {
  CancelPermission,
  ConfirmCancellation,
} from "pages/dashboard/permission/permission-details";
import {
  DataViewStyle,
  DataViewHeaderStyle,
  DataViewHeaderContainer,
  DataViewSideBarStyle,
  MainDataViewStyle,
  SideBarContent,
  BankNameStyle,
  TransactionsCardStyle,
  TableHeader,
  TableBody,
  BalanceHeader,
  LogoClickButton,
  RemoveBank,
  ImageHolder,
  DropDown,
  DropDownItem,
  PagintationWrapper,
  TransactionsCardHead,
} from "./data-view.style";
import { DashboardHeader } from "components/dashboard-header/dashboard-header";
import { logoConfig } from "pages/dashboard/manage-plan/manage-plan";
import { setLocale } from "yup";
import ReactPaginate from "react-paginate";
const DataViewHeader = (props) => {
  const { consent, account } = props;
  const { currentUser, logout }: any = useAuth();
  const [toggle, setToggle] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => setToggle(false));
  const history = useHistory();
  return (
    <DataViewHeaderStyle>
      <Div>&nbsp;</Div>
      <DataViewHeaderContainer>
        <Div>
          {/* Hello {currentUser?.firstname} {currentUser?.lastname} */}
        </Div>
        <Div className="d-flex">
          {/* <Div>BARCLAYS</Div>
               <Div>sort code: 40-02-44</Div>
               <Div>Accoount number: 7234567</Div> */}

          <Div className="position-relative">
            <div
              className="user-section"
              onKeyPress={() => setToggle(!toggle)}
              role="button"
              tabIndex={0}
              onClick={() => setToggle(!toggle)}
              ref={ref}
            >
              <ImageHolder className="user-image-holder no-border">
                <img src={AccountIcon} alt="account" />
              </ImageHolder>
              {/* <Dropdown overlay={menu} trigger={['click']}>
                        <ImageHolder className="no-border caret-image">
                           <CaretIcon />
                        </ImageHolder>
                     </Dropdown> */}
            </div>

            <DropDown ref={ref} className={`${toggle ? "visible" : ""}`}>
              <DropDownItem onClick={() => history.push("/manage-plan")}>
                Manage Plan
              </DropDownItem>
              <DropDownItem onClick={() => history.push("/product")}>
                Product
              </DropDownItem>
              <DropDownItem onClick={logout}>Logout</DropDownItem>
            </DropDown>
          </Div>
          <Div>
            <Div className="d-flex">
              <span>Hello : &nbsp; </span>
              <Div className="text-uppercase"> {consent?.bankName}</Div>
            </Div>
            {/* <Div>sort code: {consent?.sortCode}</Div> */}
            <Div>Accoount number: {account?.AccountId}</Div>
          </Div>
        </Div>
      </DataViewHeaderContainer>
    </DataViewHeaderStyle>
  );
};

const DataViewSideBar = (props) => {
  const { consents, handleSelect, handleRemove, selectedConsent, account } =
    props;
  const history = useHistory();

  return (
    <DataViewSideBarStyle>
      <SideBarContent className="font-regular">
        You have <Span className="text-orange">{consents.length}</Span> account
        linked to E-Doc Online
      </SideBarContent>
      <SideBarContent className="no-border">
        <Div>
          <Div className="font-regular mb-20 text-center">ADD BANK ACCOUNT</Div>
          {/* <Button onClick={() => history.push('/bank-selection')}>Connect your bank</Button> */}
          <Button onClick={() => history.push("/permission")}>
            Connect your bank
          </Button>
        </Div>
      </SideBarContent>
      {
        <>
          {consents.length > 0 &&
            consents.map((consent) => {
              const { bankName } = consent;
              return (
                <>
                  <BankNameStyle>
                    <LogoClickButton
                      onClick={() => handleSelect(consent)}
                      type="button"
                    >
                      <img
                        src={logoConfig[consent?.bankName?.toLowerCase()]}
                        alt="logo"
                        height="50"
                      />
                    </LogoClickButton>
                    <RemoveBank
                      type="button"
                      onClick={() => handleRemove(consent)}
                    >
                      <DeleteIcon />
                    </RemoveBank>
                  </BankNameStyle>
                  {selectedConsent.id === consent.id ? (
                    <Div>
                      <SideBarContent className="d-flex justify-content-center single-border text-center">
                        <img width="20" src={Frame} alt="account" />
                        <Div className="ml-20">
                          {" "}
                          {account?.Account
                            ? account.Account[0].Identification
                            : ""}
                        </Div>
                      </SideBarContent>
                      <SideBarContent className="d-flex justify-content-center reduced-padding-no-border">
                        <img src={PersonIcon} alt="call" />
                        <Div className="ml-20">
                          {" "}
                          {account?.Account ? account.Account[0].Name : ""}
                        </Div>
                      </SideBarContent>
                    </Div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
        </>
      }
      {/* <Div>
            <button className="remove" type="button">
               REMOVE ALL ACCOUNTS
            </button>
         </Div> */}

      {/* <Div className="mt-30">
            <button className="remove" type="button" onClick={logout}>
               LOGOUT
            </button>
         </Div> */}
    </DataViewSideBarStyle>
  );
};

const DataView = () => {
  const { notify } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [consents, setConsents] = useState([]);
  const [selectedConsent, setSelectedConsent] = useState({ bankName: "" });
  const [account, setAccount] = useState({ AccountId: "" });
  const [transactions, setTransactions] = useState([]);
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [balance, setBalance] = useState();
  const [cancelScreenvisibility, setcancelScreenVisibility] = useState(false);
  const [consentExpiry, setConsentExpiry] = useState();
  const [confirmScreenVisibility, setConfirmScreenVisibility] = useState(false);
  const [transactionDate, setTransactionDate] = useState({
    fromDate: moment().subtract(3, "month").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  });
  const isEmpty = (obj: any) => Object.keys(obj).length === 0;

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      // setIsLoading(true);
      const result = await getConsents({ bankName: "all" });

      if (result.ok) {
        // setIsLoading(false);
        if (result?.data?.data?.length != 0) {
          setConsents(result.data.data);
          setSelectedConsent(result.data?.data[0]);
        } else {
          setIsLoading(false);
          setConsents([]);
        }
        // setIsLoading(false);
        // return;
      } else {
        notify({
          message: result.data?.error?.message || result?.data?.message,
          variant: "error",
        });
        setIsLoading(false);
      }
      // setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await getAccount({ bankName: selectedConsent?.bankName });
      if (result.ok) {
        setAccount(result?.data?.data?.Data?.Account[0]);
      } else {
        //   setIsLoading(false);
      }
    })();
  }, [selectedConsent]);

  useEffect(() => {
    if (selectedConsent?.bankName !== "" && consents.length > 0) {
      if (account && account?.AccountId != "") {
        (async () => {
          try {
            const result = await getBalance({
              bankName: selectedConsent?.bankName,
              AccountId: account?.AccountId,
            });
            if (result.ok) {
              //   setIsLoading(false);
              setBalance(result?.data?.data?.Data?.Balance[0]);
            } else {
              //   setIsLoading(false);
            }
          } catch (err) {
            console.log("get errror", err);
          }
        })();
      }
    }
    if (account && account?.AccountId != "") {
      (async () => {
        const result = await getTransaction({
          bankName: selectedConsent?.bankName,
          AccountId: account?.AccountId,
          fromDate: transactionDate.fromDate,
          toDate: transactionDate.toDate,
        });
        if (result.ok) {
          setIsLoading(false);
          console.log("result", result);
          if (!isEmpty(result?.data?.data?.Data)) {
            const pending = result?.data?.data?.Data?.Transaction.filter(
              (item) => item.Status === "Pending"
            );
            const Booked = result?.data?.data?.Data?.Transaction.filter(
              (item) => item.Status === "Booked"
            );
            setTransactions(Booked);
            setPendingTransactions(pending);
          } else {
            setTransactions([]);
            setPendingTransactions([]);
          }
        } else {
          setIsLoading(false);
        }
      })();
      (async () => {
        const result = await getStatement({
          bankName: selectedConsent?.bankName,
          AccountId: account?.AccountId,
        });
        if (result.ok) {
          console.log("get statement", result);
        }
      })();
      (async () => {
        const result = await getAccountById(
          { bankName: selectedConsent?.bankName },
          account?.AccountId
        );
        if (result.ok) {
          console.log("get statement", result);
        }
      })();
    }
  }, [account, transactionDate]);
  const handleSelect = (data) => {
    setSelectedConsent(data);
  };

  const handleRemove = (consent) => {
    setConsentExpiry(consent);
    setcancelScreenVisibility(true);
    const newData = JSON.parse(consent.accountRequest);

    const data = {
      bankName: consent.bankName,
      consentType: "DELETE",
      consent: {
        Data: {
          Permissions: newData.Permissions,
          ExpirationDateTime: newData.ExpirationDateTime,
          TransactionFromDateTime: newData.TransactionFromDateTime,
          TransactionToDateTime: newData.TransactionToDateTime,
        },
        Risk: {},
      },
    };

    // (async () => {
    //    const result = await sendConsentData(data);
    //    if (result.ok) {
    //       notify({ message: 'success', variant: 'success' });
    //       window.location.reload();
    //    }else {
    //       notify({ message: result.data.error.message, variant: 'error' });
    //    }

    // })();
  };
  return (
    <>
      <ConfirmCancellation
        visible={confirmScreenVisibility}
        cancel={() => setConfirmScreenVisibility(false)}
        consent={consentExpiry}
      />
      <CancelPermission
        visible={cancelScreenvisibility}
        cancel={() => setcancelScreenVisibility(false)}
        consent={consentExpiry}
      />
      <DataViewStyle>
        {/* <DataViewHeader consent={selectedConsent} account={account}/> */}
        <DashboardHeader />
        <MainDataViewStyle>
          <DataViewSideBar
            consents={consents}
            selectedConsent={selectedConsent}
            account={account}
            handleSelect={(data) => handleSelect(data)}
            handleRemove={handleRemove}
          />

          {isLoading ? (
            <Div
              style={{ height: "100vh", display: "flex", alignItems: "center" }}
              className="content font-regular font-18 text-grey d-flex align-items-center justify-content-center w-100"
            >
              <PageLoader />
            </Div>
          ) : consents.length != 0 ? (
            <Div className="content">
              <BalanceCardView balance={balance} />
              <TransactionsCard
                title="Pending Transactions"
                transactions={pendingTransactions}
                setTransactionDate={setTransactionDate}
              />
              <TransactionsCard
                title="Transactions"
                transactions={transactions}
                setTransactionDate={setTransactionDate}
              />
              {/* <TransactionsCard
                title="Statement"
                transactions={transactions}
                setTransactionDate={setTransactionDate}
              /> */}
            </Div>
          ) : (
            <Div className="content">
              <NoConsentCard title="No Consent" />{" "}
            </Div>
          )}
        </MainDataViewStyle>
      </DataViewStyle>
    </>
  );
};

const TransactionsCard = (props) => {
  const { transactions, title, setTransactionDate } = props;
  const [selectMonth, setSelectMonth] = useState("3");
  const [pagePerData, setPagePerData] = useState("30");
  const [startRecordCount, setStartRecordCount] = useState(0);
  const [endRecordCount, setEndRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  let pageCount = Math.ceil(transactions.length / parseInt(pagePerData));
  useEffect(() => {
    setStartRecordCount(1);
    setEndRecordCount(
      parseInt(pagePerData) >= transactions.length
        ? transactions.length
        : pagePerData
    );
  }, [transactions]);
  const handleDateFilter = (event) => {
    let filterObj = {
      fromDate: moment()
        .subtract(event.target.value, "month")
        .format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    };
    setTransactionDate(filterObj);
    setSelectMonth(event.target.value);
  };
  const options = [
    { label: "3 month", value: "3" },
    { label: "6 month", value: "6" },
    { label: "12 month", value: "12" },
  ];

  const pageData = [
    { label: "30", value: "30" },
    { label: "40", value: "40" },
    { label: "50", value: "50" },
  ];

  const handlePage = (event) => {
    setInitialPage(0);
    setCurrentPage(0);
    setStartRecordCount(1);
    setEndRecordCount(
      parseInt(event.target.value) > transactions.length
        ? transactions.length
        : event.target.value
    );

    setPagePerData(event.target.value);
  };
  function handlePageClick({ selected: selectedPage }) {
    setInitialPage(selectedPage);
    setStartRecordCount(selectedPage * parseInt(pagePerData) + 1);
    setEndRecordCount(
      parseInt(selectedPage + 1) >=
        Math.ceil(transactions.length / parseInt(pagePerData))
        ? transactions.length
        : selectedPage * parseInt(pagePerData) + parseInt(pagePerData)
    );
    setCurrentPage(selectedPage);
  }
  const offSet = currentPage * parseInt(pagePerData);

  const MonthSelect = ({ value, options, onChange }) => {
    return (
      <label>
        <select value={selectMonth} onChange={onChange}>
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
    );
  };

  const PagePerData = ({ value, options, onChange }) => {
    return (
      <label>
        <select value={value} onChange={onChange}>
          {pageData.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
    );
  };

  if (transactions.length === 0) {
    return (
      <TransactionsCardStyle>
        <Div className="d-flex justify-content-between my-20 font-15 text-grey">
          <Div className="font-regular font-20 font-bold">{title}</Div>
          {/* <Div>Updated a few seconds ago</Div> */}
        </Div>
        <Div className="d-flex justify-content-between text-center">
          <Div>
            <Div className="font-bold text-grey mt-10">No Data Found</Div>
          </Div>
        </Div>
      </TransactionsCardStyle>
    );
  }
  return (
    <>
      <TransactionsCardStyle>
        <TransactionsCardHead>
          <Div style={{ display: "flex", gap: "1rem" }}>
            <Div className="font-regular font-20 font-bold">{title}</Div>
            {props.title === "Transactions" && (
              <MonthSelect
                options={options}
                value={selectMonth}
                onChange={handleDateFilter}
              />
            )}
          </Div>
          {props.title === "Transactions" && (
            <>
              <PagintationWrapper>
                <Div className="d-flex justify-content-between mr-2">
                  <PagePerData
                    options={options}
                    value={pagePerData}
                    onChange={handlePage}
                  />

                  <Div>
                    {" "}
                    Results: {startRecordCount || 1} - {endRecordCount} of{" "}
                    {transactions.length}{" "}
                  </Div>
                </Div>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  forcePage={initialPage}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination_link--disabled"}
                  activeLinkClassName={"pagination__link--active"}
                  breakLabel="..."
                  marginPagesDisplayed={1}
                />
              </PagintationWrapper>
            </>
          )}
        </TransactionsCardHead>
        <Div className="d-flex justify-content-between my-20 font-15 text-grey">
          {/* <Div>Updated a few seconds ago</Div> */}
        </Div>
        <TableHeader>
          <Div>TIME</Div>
          <Div>DESCRIPTIONS</Div>
          <Div>STATUS</Div>
          <Div>AMOUNT</Div>
        </TableHeader>
        {transactions.slice(offSet, offSet + pagePerData).map((item, index) => {
          return (
            <TableBody>
              <Div>{moment(item.BookingDateTime).format("DD MMM YYYY")}</Div>
              <Div>{item.TransactionInformation}</Div>
              <Div>{item.Status}</Div>
              <Div>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: item.Amount.Currency,
                }).format(item.Amount.Amount)}
              </Div>
            </TableBody>
          );
        })}
      </TransactionsCardStyle>
    </>
  );
};
const NoConsentCard = (props) => {
  const { title } = props;

  return (
    <TransactionsCardStyle>
      <Div className="d-flex justify-content-between my-20 font-15 text-grey">
        <Div className="font-regular font-20 font-bold">{title}</Div>
      </Div>
    </TransactionsCardStyle>
  );
};

const BalanceCardView = (props) => {
  const { balance } = props;
  if (balance === undefined) {
    return (
      <TransactionsCardStyle>
        <BalanceHeader className="d-flex justify-content-between my-20 font-15 text-grey">
          <Div className="font-regular font-20 font-bold">Balance</Div>
          {/* <Div>Updated a few seconds ago</Div> */}
        </BalanceHeader>
        <Div className="d-flex justify-content-between text-center">
          <Div>
            <Div className="font-bold text-grey mt-10">No Data Found</Div>
          </Div>
        </Div>
      </TransactionsCardStyle>
    );
  }
  return (
    <TransactionsCardStyle>
      <BalanceHeader className="d-flex justify-content-between my-20 font-15 text-grey">
        <Div className="font-regular font-20 font-bold">Balance</Div>
        {/* <Div>Updated a few seconds ago</Div> */}
      </BalanceHeader>
      <Div className="d-flex justify-content-between w-80 mx-auto text-center">
        <Div>
          <Div className="font-bold font-22 text-grey">
            {new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: balance?.Amount.Currency,
            }).format(balance?.Amount.Amount)}
          </Div>
          <Div className="font-14 text-grey mt-10">Available Balance</Div>
        </Div>
        <Div>
          <Div className="font-bold font-22 text-grey">
            {new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: balance?.Amount.Currency,
            }).format(balance?.Amount.Amount)}
          </Div>
          <Div className="font-14 text-grey mt-10">Current Balance</Div>
        </Div>
      </Div>
    </TransactionsCardStyle>
  );
};

export default DataView;

const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      viewBox="0 0 31 30"
    >
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g transform="translate(-1226 -944) translate(168 339) translate(1058 605)">
              <rect width="31" height="30" fill="none" rx="5" />
              <g
                stroke="#202020"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width=".95"
              >
                <g>
                  <path
                    d="M12.345 2.985l-1.083 12.359c-.063.73-.576 1.285-1.188 1.286H3.86c-.612 0-1.125-.555-1.189-1.286L1.588 2.985M.094 2.985L13.839 2.985M4.277 2.985V.831c0-.397.268-.718.598-.718h4.183c.33 0 .598.321.598.718v2.154M6.967 6.217L6.967 13.757M9.656 6.217L9.357 13.757M4.277 6.217L4.576 13.757"
                    transform="translate(6 5) translate(2.375 1.615)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
