import { useEffect, useLayoutEffect } from 'react';
import { Global, ThemeProvider } from '@emotion/react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { Routes } from 'routes';
// import { Routes } from 'routesCopy';
import { theme } from 'theme';
import { globalStyles } from 'theme/global';
import { SnackbarContextProvider } from 'context/snackbar/snackbar';
import { useAuth } from 'context/auth';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history'
const TRACKING_ID = "UA-212590691"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const browserHistory = createBrowserHistory()
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search)
})

function App() {
   const { initUserSession } = useAuth();

   useEffect(() => {
      initUserSession();
      ReactGA.pageview(window.location.pathname + window.location.search);
   }, []);

   return (
      <BrowserRouter>
         <SnackbarContextProvider>
            <ScrollToTop />
            <Global styles={globalStyles} />
            <ThemeProvider theme={theme}>
               <Routes />
            </ThemeProvider>
         </SnackbarContextProvider>
      </BrowserRouter>
   );
}

const ScrollToTop: React.FC = () => {
   const { pathname } = useLocation();

   useLayoutEffect(() => {
      window.scrollTo(0, 0);
   }, [pathname]);

   return null;
};

export default App;
