import styled from "@emotion/styled";

const HeaderStyled = styled.div`
  /* position: fixed; */
  // height: 7vh;
  width: 100vw;
  grid-area: header;
  padding: 1rem 2rem;
  background: #ec7d31;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.white {
    background: #fff;
  }
  &.white a {
    color: #000;
  }

  .logo {
    width: 100px;
  }
  .logo-box {
    width: 120px;
    border-radius: 5px;
    background: #fff;
    padding: 5px 10px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-right: 3rem;

  ${({ theme }) => theme.media?.semiMedium} {
    margin-right: 0rem;
  }

  .back {
    border: 1px solid #1366f4;
    font-size: 1.2rem;
    padding: 5px 0;
    width: 16%;
    text-align: center;
    cursor: pointer;
    color: #1e46bd;
    text-transform: capitalize;

    &:hover {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(1);
      animation: pulse 2s infinite;
    }
  }

  .user-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .user-image-holder {
      width: 25px;
      height: 25px;
      margin-left: 1rem;

      ${({ theme }) => theme.media?.semiMedium} {
        margin-left: 0rem;
      }
      &:after {
        border: none;
      }
    }

    .no-border {
      border: none;

      &:after {
        border: none;
      }
    }

    .caret-image {
      width: 8px;
      height: 100%;
      cursor: pointer;
      align-self: center;
      margin-left: 1rem;
    }

    .user-name {
      font-size: 1.3rem;
      color: #8e8e8e;
      font-weight: 500;
      margin-left: 2rem;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.97);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.97);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

const ImageHolder = styled.div`
  width: 15px;

  margin-left: 5rem;
  position: relative;

  ${({ theme }) => theme.media?.semiMedium} {
    margin-left: 3rem;
  }

  img {
    height: 100%;
    width: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    margin-left: 3rem;
    border-right: 2px solid var(--primary-grey-color);
    height: 100%;
  }
`;

export const DropDown = styled.div`
  background-color: #fff;
  position: absolute;
  top: 48px;
  right: 50px;
  box-shadow: 0 3px 5px rgba(69, 80, 101, 0.2);
  // max-height: 100px;
  border: 1px solid #edebeb;
  width: 150px;
  text-align: center;
  border-radius: 4px;
  transition: top 300ms ease-out, opacity 0.1s ease-out;
  opacity: 0;
  visibility: hidden;
  &.product {
    right: 160px;
    width: 160px;
  }
  &.visible {
    top: 55px;
    opacity: 1;
    visibility: visible;
    transition: top 300ms ease-out, opacity 0.1s ease-out;
    z-index: 99;
  }

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 30px;
    height: 1rem;
    width: 1rem;
    background-color: #fff;
    border-top: 1px solid #edebeb;
    border-left: 1px solid #edebeb;
    transform: rotate(45deg);
    z-index: 98;
    opacity: 1;
    transition-delay: 0.3s;
  }
`;

export const DropDownItem = styled.div`
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  &:hover {
    color: #ed7d32;
  }
  &.mobile {
    display: none;
    ${({ theme }) => theme.media?.semiMedium} {
      display: block;
    }
  }
`;
export const LinkView = styled.a`
  color: #fff;
  text-decoration: none;
  padding: 0 10px;
  margin: 0 10px;

  ${({ theme }) => theme.media?.semiMedium} {
    display: none;
  }
`;

export { HeaderStyled, HeaderContent, ImageHolder };
