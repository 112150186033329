import styled from "@emotion/styled";

export const IncomePageWrapperMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const IncomePageWrapperTop = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const BoxCard = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  max-heigh: 600px;
  padding: 24px 44px;
  @media (max-width: 992px) {
    height: auto;
  }

  @media (max-width: 380px) {
    padding: 16px;
  }
`;

export const BoxCardTable = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  overflow: auto;
  display: block;
  padding: 24px;
  height: 250px;

  @media (max-width: 992px) {
    height: auto;
  }
`;

export const CircularProgressWrapperMain = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 0;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 992px) {
    gap: 20px;
  }

  @media (max-width: 380px) {
    justify-content: center;
  }
`;

export const CircularProgressWrapper = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 380px) {
    width: 100%;
  }
`;

export const IncomeCardHead = styled.span`
    font-family: Inter !important;
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 24px;
color: black
`;

export const RatingType = styled.span`
  text-align: center;
  font-family: Inter !important;
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.22px;
`;

export const ListWrapper = styled.div`
  margin: 16px 0;

  & label {
font-family: Inter !important;
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 24px;
color: black
  }
`;

export const CompanyDetails = styled.div`

& ul {
   margin: 16px;
    list-style: none;
& li {
  font-family: Inter !important;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: black;
  margin: 8px 0;

&::before {
    content: "•";
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
}

`;


export const RiskInsights = styled.div`

& ul {
   margin: 16px;
    list-style: none;
& li {
  font-family: Inter !important;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: black;
  margin: 8px 0;

&::before {
    content: "•";
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
}

`;

export const IncomeTable = styled.table`
  width: 100%;
  margin: 16px 0;
  border-collapse: collapse;

  th {
    padding: 12px 0;
    // border-bottom: 1px solid #e1e1ea;
    // border-top: 1px solid #e1e1ea;
    background-color: transparent;
  }

  td {
    text-align: left;
    // padding: 16px;
    padding: 12px 0;
    // border-bottom: 1px solid #e1e1ea;
font-family: Inter !important;
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 24px;
color: black
  }
`;
export const MatrixTable = styled.table`
  width: 100%;
  margin: 16px 0;
  border-collapse: collapse;

  th {
    padding: 12px;
    border: 1px solid #e1e1ea;
    // border-top: 1px solid #e1e1ea;
    background-color: transparent;
  }

  td {
    text-align: left;
    padding: 16px;
    // padding: 12px 0;
    border: 1px solid #e1e1ea;
font-family: Inter !important;
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 24px;
color: black
  }
`;
