/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { OrangeBackground, SuccessContainer, Div, TextGrey } from 'components/styles/index';
import { Button } from 'components/button/button';
import CheckedIcon from 'assets/images/cancel.svg';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'context/auth';

const RedirectionFailure = () => {
   const history = useHistory();
   const { logout } = useAuth();

   return (
      <OrangeBackground>
         <SuccessContainer>
            <div>
               <img src={CheckedIcon} alt="icon" />
            </div>
            <Div className="font-22 font-bold text-black">Oops!</Div>
            <Div className="font-16 font-regular">
               We couldn&apos;t retrieve the requested information from your selected account(s)
            </Div>
            <TextGrey className="font-16 font-regular">
               Kindly wait for some minutes and try again or reach out to the support team.
            </TextGrey>
            <Div className="d-flex justify-content-center">
               <div>
                  <Button variant="primary" onClick={() => history.push('/bank-selection')}>
                     Go Back
                  </Button>
               </div>
               <Div className="ml-20">
                  <Button variant="secondary" onClick={logout}>
                     Logout
                  </Button>
               </Div>
            </Div>
         </SuccessContainer>
      </OrangeBackground>
   );
};

export default RedirectionFailure;
