import { FC, useState } from 'react';
import { SideDrawer } from 'components/side-drawer/side-drawer';
import { Checkbox } from 'components/checkbox/checkbox';
import { Button } from 'components/button/button';
import { Div, Span } from 'components/styles';
import { Input } from 'components/input/input';
import InputRange from 'react-input-range';
import { InputWrapper } from './add-partner.style';
import 'react-input-range/lib/css/index.css';

interface IPermissionDetails {
   visible: boolean;
   handleClose: () => void;
}

const AddPartner: FC<IPermissionDetails> = ({ visible, handleClose }: IPermissionDetails) => {
   const [rangeValue, setRangeValue] = useState(0);
   function changeRangeValue(val) {
      setRangeValue(val);
   }

   return (
      <>
         <SideDrawer visible={visible} handleClose={handleClose} returnText="Back to Dashboard">
            <Div className="mb-30 font-bold text-black">Select Business Partner</Div>
            <InputWrapper>
               <Input id="code" type="text" label="Enter unique code" placeholder="Enter Code" />
            </InputWrapper>

            <InputWrapper>
               <Input id="name" type="text" label="Name" placeholder="Name of partner" />
            </InputWrapper>

            <InputWrapper>
               <Input id="country" type="text" label="Country" placeholder="Country" />
            </InputWrapper>
            <InputWrapper>
               <Input
                  id="Email Address"
                  type="text"
                  label="Email Address"
                  placeholder="Youremail@email.com"
               />
            </InputWrapper>

            <Div className="my-30 font-16">Select Permissions</Div>

            <Div>
               <InputWrapper>
                  <Checkbox name="permission" value="view-only">
                     View Only
                  </Checkbox>
               </InputWrapper>
               <InputWrapper>
                  <Checkbox name="permission" value="view-and-download">
                     View and download
                  </Checkbox>
               </InputWrapper>
               <InputWrapper>
                  <Checkbox name="permission" value="transactions">
                     Transactions
                  </Checkbox>
               </InputWrapper>
               <InputWrapper>
                  <Checkbox name="permission" value="account-statement">
                     Account Statement
                  </Checkbox>
               </InputWrapper>
            </Div>

            <Div className="mt-20 text-center mx-auto">
               <InputRange
                  formatLabel={(value) => `${value} days`}
                  css={{ fontSize: '30px' }}
                  onChange={changeRangeValue}
                  maxValue={31}
                  minValue={0}
                  value={rangeValue}
               />
            </Div>

            <Div className="mx-auto mt-30 text-center">
               <Button type="button" variant="secondary">
                  Cancel
               </Button>
               <Span className="ml-20">
                  <Button type="button">I Confirm</Button>
               </Span>
            </Div>
         </SideDrawer>
      </>
   );
};

export { AddPartner };
