import { BASE_URL } from "api";
import { sendConsentComplete } from "api/bank";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RedirectionFailure from "./redirection-failure";
import RedirectionSuccess from "./redirection-success";


const Confirmation = () => {
    const history = useHistory();
    const [showSuccess, setShowSuccess] = useState(true);
    useEffect( () => {
        (async () => {
        try {
            if(window.location.href.includes('error')){
                setShowSuccess(false);
            } else {
                const params = (window.location.hash || window.location.search).substring(1)
                const requestOptions = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: null
                };
                
                const result = await fetch( BASE_URL + "/orchestrator/completeJourney?"+params, requestOptions);
               // const result = await sendConsentComplete(params,{});
                setShowSuccess(true);
             }
        } catch(ex){
            console.error('Error Saving Consent', ex);
            setShowSuccess(false);
        }
        })();
    },[])
 
    return (<>
        {showSuccess && <RedirectionSuccess></RedirectionSuccess>}
        {showSuccess || <RedirectionFailure></RedirectionFailure>}
       </>
    );
 };
 
 export default Confirmation;