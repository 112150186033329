import styled from "@emotion/styled";

export const SideDrawerStyle = styled.div`
  background-color: #fff;
  width: 650px;
  position: fixed;
  right: -1000px;
  top: 0;
  bottom: 0;
  padding: 80px 40px 20px 40px;
  transition: right 500ms ease-in-out;
  z-index: 9999;
  overflow: auto;

  ${({ theme }) => theme.media?.semiMedium} {
    width: 100%;
  }
  &.visible {
    right: 0px;
  }

  button.close-btn {
    border: none;
    background: none;
    border-radius: 50%;
    font-size: 1.1rem;
    position: absolute;
    top: 30px;
    left: 15px;
    font-weight: 500;
  }

  .close {
    border-radius: 50%;
    font-size: 1.1rem;
    padding: 2px 6px;
    font-weight: 600;
    outline: transparent;
    cursor: pointer;
    border: 1px solid green;
  }

  .text {
    font-size: 1.4rem;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors?.black03};
  }
`;

export const BackdDrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #80808014;
  opacity: 1;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  visibility: hidden;
  transition: right 200ms ease-in-out;

  &.visible {
    display: block;
    visibility: visible;
  }
`;
