import styled from '@emotion/styled';
import { ReactComponent as LoadingIcon } from 'assets/images/loading.svg';
import check from 'assets/images/radio-check.svg';

export const SpinnerIcon = styled(LoadingIcon)`
   animation: rotate 4s linear infinite;
   margin: 20px 0;

   @keyframes rotate {
      100% {
         transform: rotate(360deg);
      }
   }
`;

export const BanksStyle = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-direction: column;
   height: 340px;
   overflow: scroll;
`;

export const BankStyle = styled.label`
   width: 100%;
   display: flex;
   justify-content: space-between;
   margin-bottom: 10px;
   border-bottom: 1px solid #e4e4e4;
   padding: 10px 0;

   .input {
      -webkit-appearance: none;
      width: 23px;
      height: 23px;
      outline: none;
      border: 1px solid ${({ theme }) => theme.colors?.grey06};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 50%;
      overflow: hidden;

      &:before {
         content: '';
         display: block;
         width: 100%;
         height: 120%;
         margin: 20% auto;
         display: flex;
         justify-content: center;
         align-items: center;
         transition: all 300ms ease-in-out;
      }

      &:checked:before {
         content: url(${check});
         background: ${({ theme }) => theme.colors?.orange01};
         position: relative;
         top: -1px;
      }

      &:checked {
         border-color: ${({ theme }) => theme.colors?.orange01};
      }
   }
`;
