import moment from "moment";

function getDifference (date) {
    const todayDate = moment();
    const pastDate = moment(date);
    const dDiff = pastDate.diff(todayDate);
    if (dDiff > 0) {
        console.log('Date is not past');
    }else{
        console.log('Date is past');
    }
    return dDiff;
}
export {getDifference};