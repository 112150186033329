import { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled/macro';
import check from 'assets/images/radio-check.svg';

export const RadioOption = styled.div`
   display: flex;
   align-items: center;
   font-size: 1.4rem;
   padding: 0.8em 0;
`;

const Label = styled.label`
   color: ${({ theme }) => theme.colors?.black01};
   text-transform: initial;
   font-size: 1.4rem;
   display: flex;
   justify-items: center;
   align-items: flex-start;
   color: #7e7e7e;
   line-height: 2rem;
   margin-bottom: 10px;
`;

const CheckBoxStyle = styled.input`
   margin-right: 1em;
   -webkit-appearance: none;
   width: 20px;
   height: 20px;
   outline: none;
   border: 1px solid ${({ theme }) => theme.colors?.grey06};
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;

   &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      margin: 20% auto;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   &:checked:before {
      content: url(${check});
      background: ${({ theme }) => theme.colors?.orange01};
   }

   &:checked {
      border-color: ${({ theme }) => theme.colors?.orange01};
      border: transparent;
   }
`;

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
   children: React.ReactNode;
   name: string;
}

const Checkbox = ({ children, name, ...rest }: IProps) => (
   <Label htmlFor={name}>
      <CheckBoxStyle id={name} type="checkbox" name={name} {...rest} /> <div>{children}</div>
   </Label>
);

export { Checkbox };
