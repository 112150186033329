import { DashboardHeader } from "components/dashboard-header/dashboard-header";
import { useAuth } from "context/auth";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { parseJwt } from "utils/functions";
import {  getCookie, getUserInfoFromStorage } from 'utils/storage'
import { FrameContainer } from "./graphs.style";

const Graphs = () => {
    const { currentUser }: any = useAuth();
    const token = getUserInfoFromStorage();
    const history = useHistory();
    const [dashboardUrl, setDashboardUrl] = useState('');
    const [sidebarVisibility, setSidebarVisibility] = useState({
       permission: false,
       addPartner: false,
    });

    useEffect(() =>{
      
  let userData = parseJwt(token);
        setDashboardUrl('https://business.e-doconline.co.uk/client/viewOnly/'+ userData.id + '?jwt=' + token);
    });
 
    function closeSidebar() {
       setSidebarVisibility({
          addPartner: false,
          permission: false,
       });
    }
 
    return (
       <>
      <DashboardHeader />
      <FrameContainer>
          <iframe className="charts-frame" src={dashboardUrl}>
          </iframe> 
      </FrameContainer>
       </>
    );
 };
 
 export default Graphs;
 