import styled from '@emotion/styled';
import { Div } from 'components/styles';
import { ReactComponent as SVG } from 'assets/images/bg-orange.svg';

export const AuthStyle = styled(Div)`
   position: relative;
   height: 100%;
   width: 100%;

   ${({ theme }) => theme.media?.semiMedium} {
      flex-direction: column;
      padding: 0 2rem;
   }
`;

export const EmailConfimation = styled.div`
   display: flex;
   justify-content: start;
   align-items: center;
   flex-direction: column;
   padding-top: 5%;
   line-height: 2.5rem;
`;

export const BgContainer = styled.div`
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   width: 100%;
   z-index: -1;

   ${({ theme }) => theme.media?.semiMedium} {
      display: none;
   }
`;

export const BGImage = styled(SVG)`
   height: 100%;
   position: absolute;
   right: -47px;
   max-width: 100%;
`;

export const BgWhite = styled.div`
   height: 100vh;
   background: ${({ theme }) => theme.colors?.white};
   position: relative;
   z-index: 10;

   &.orange {
      width: 100%;
      background-color: ${({ theme }) => theme.colors?.orange01};
   }
`;

export const FormContainer = styled(Div)`
   background-color: ${({ theme }) => theme.colors?.white};
   width: 420px;
   padding: 3rem;
   // box-shadow: 0 3px 5px rgba(69, 80, 101, 0.2);  
   box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);

   ${({theme}) => theme.media?.mobile} {
      box-shadow: none;
      width: 100%;
      padding: 1rem;
   }
`;

export const Form = styled.form`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   margin-top: 5rem;

   &.mt-2 {
      margin-top: 2rem;
   }
`;
export const InputWrapper = styled(Div)`
   display: grid;
   grid-template-columns: 1fr;
   width: 100%;
   margin-bottom: 1.5rem;

   &.col-2 {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;
   }

    
   input[type="range"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      margin: 0;
      padding: 0;
      background-color: #ED7D32;
      border-radius: 4px;
   }
   input[type="range"]:focus {
      outline: none;
   }
    
    /* Thumb */
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-radius: 50%;
      border: none;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      margin-top: calc(((18px - 8px) / 2) * -1);
      cursor: pointer;
    }
    input[type="range"]::-moz-range-thumb {
      -moz-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-radius: 50%;
      border: none;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      margin-top: calc(((18px - 8px) / 2) * -1);
      cursor: pointer;
    }
    input[type="range"]::-ms-thumb {
      -ms-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-radius: 50%;
      border: none;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      margin-top: calc(((18px - 8px) / 2) * -1);
      cursor: pointer;
    }
    
    /* Track */
    input[type="range"]::-webkit-slider-runnable-track {
      height: 8px;
      background-image: linear-gradient(
        90deg,
        #ED7D32 0%,
        #f2f2f2) 0%
      );
      border-radius: calc(8px / 2);
    }
    input[type="range"]::-moz-range-track {
      height: 8px;
      background-color: #f2f2f2;
      border-radius: calc(8px / 2);
    }
    input[type="range"]::-ms-track {
      height: 8px;
      background-color: #f2f2f2;
      border-radius: calc(8px / 2);
    }
    
    /* Progress */
    input[type="range"]::-moz-range-progress {
      background-color: #ED7D32;
      border-radius: calc(8px / 2) 0 0 calc(8px / 2);
    }
   input[type="range"]::-ms-fill-lower {
      height: 8px;
      background-color: #ED7D32;
      border-radius: calc(8px / 2) 0 0 calc(8px / 2);
   }
`;

export const HeadingOne = styled.div`
   color: ${({ theme }) => theme.colors?.grey03};
   font-size: 1.6rem;
`;

export const HeadingTwo = styled.div`
   margin-top: 0.5rem;
   color: ${({ theme }) => theme.colors?.black02};
   font-size: 1.7rem;
   font-weight: 580;
`;

export const NavContainer = styled.div`
   display: flex;
`;

export const Logo = styled.img`
   width: 8%;
   position: absolute;
   top: 40px;
   left: 50px;
`;
export const Label = styled.label`
    pointer-events: none;
    color: #999;
    white-space: nowrap;
    margin-bottom: 1rem;
    display: block;
    font-size: 1.4rem;
    font-weight: 400;
    color: #1e1c24;
`;
export const InputRang = styled.div`
    pointer-events: none;
    color: #999;
    white-space: nowrap;
    margin-bottom: 1rem;
    display: block;
    font-size: 1.4rem;
    font-weight: 400;
    color: #1e1c24;
`;
