import styled from '@emotion/styled';

export const PermissionSidebarDetails = styled.div`
   line-height: 2.7rem;
   overflow: scroll;
`;

export const ConfirmCancellationStyle = styled.div`
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100vw;
   min-height: 100vh;
   background: #fff;
   display: flex;
   overflow-x: hidden;
   overflow-y: scroll;
   align-items: center;
   flex-direction: column;
   // padding-top: 6rem;
   line-height: 3rem;
   opacity: 0;
   visibility: hidden;
   transition: all 500ms ease-in-out;
   z-index: 3;
   ${({theme}) => theme.media?.semiMedium} {
      padding: 20px;
      box-sizing: border-box;
   }
   
   &.visible {
      visibility: visible;
      opacity: 1;
      transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
   }
`;

export const CancelPermissionStyle = styled.div`
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100vw;
   min-height: 100vh;
   overflow-y: scroll;
   overflow-x: hidden;
   background: #fff;
   display: flex;
   align-items: center;
   flex-direction: column;
   // padding-top: 13rem;
   line-height: 3rem;
   opacity: 0;
   visibility: hidden;
   transition: all 500ms ease-in-out;
   z-index: 2;
   &.visible {
      visibility: visible;
      opacity: 1;
      transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
   }
`;

export const CancelPermissionContainerStyle = styled.div`
   width: 512px;
   padding: 20px;
   margin: 20px 0;
   box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);

   ${({theme}) => theme.media?.semiMedium} {
      width: 100%;
      padding: 20px;
   }
`;

export const ConfirmPermissionContainerStyle = styled.div`
   width: 512px;
   padding: 20px;
   margin: 20px 0;
   box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);

   ${({theme}) => theme.media?.semiMedium} {
      width: 100%;
      padding: 20px;
   }

   .text-center {
      text-align: center;
      display: block;
   }
`;
export const ConfirmSuccessStyle = styled.div`
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100vw;
   height: 100vh;
   background: #fff;
   display: flex;
   align-items: center;
   flex-direction: column;
   padding-top: 0rem;
   line-height: 3rem;
   opacity: 0;
   visibility: hidden;
   transition: all 500ms ease-in-out;
   text-align: center;
   margin: 0 auto;
   z-index: 3;
   &.visible {
      visibility: visible;
      opacity: 1;
      transition: all 500ms ease-in-out;
   }
`;

export const ConfirmCancellationContainerStyle = styled.div`
   width: 400px;
   margin: 0 auto;

   img {
      width: 70px;
   }
`;

export const ConfirmSuccessContainer = styled.div`
   width: 410px;
   display: grid;
   height: 300px;
   margin: 20px;
   padding: 20px;
   box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);
   img {
      width: 80px;
   }
`;

export const GreyBackground = styled.div`
   background-color: ${({ theme }) => theme.colors?.grey01};
   width: 100vw;
   height: 35vh;
   position: relative;
   top: 40px;
   margin-top: 40px;
   padding-top: 30px;
   text-align: center;
`;
export const GreyWhiteBackground = styled.div`
   background-color: #fff;
  
   position: relative;
   padding-top: 30px;
   text-align: center;
`;