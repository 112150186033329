import { FC, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { object, SchemaOf } from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { Label } from "components/styles";
import Logo from "assets/images/logo-white.png";
import affordabilitybanner from "assets/images/affordability.png";
import twitter_orange from "assets/images/twitter_orange.svg";
import instagram_orange from "assets/images/instagram_orange.svg";
import google_orange from "assets/images/google_orange.svg";
import linkedin_orange from "assets/images/linkedin_orange.svg";
import { InputWrapper } from "pages/auth/auth.style";
import { getSourceCities, getAffordability } from "api/dashboard";
import moment from "moment";
import Infoicon from "assets/images/infoicon.svg";
import {
  AboutInfo,
  AffordabilityPage,
  DateInfoBox,
  DateInput,
  Disclaimer,
  DisclaimerDescription,
  DisclaimerTitle,
  FooterColumn,
  FooterContent,
  FooterLink,
  FooterLinks,
  FooterSection,
  FooterSocialLink,
  FooterSocialLinks,
  HeroRightSection,
  HeroSection,
  HeroSubTitle,
  HeroTitle,
  HotelTypeInfoBox,
  InfoBlock,
  InfoBlockWrapper,
  InfoLabel,
  PageContainer,
  PageHeader,
  PoweredBy,
  ReservedRight,
  StyledButton,
  TravelInfo,
  TravelInfoBox,
} from "./affrodability.style";
import { useSnackbar } from "context/snackbar/snackbar";
import { Button } from "components/button/button";
import { ButtonLoader } from "components/button-loader";

export interface IAffordabilityRequest {
  startDate: Date;
  endDate: Date;
  //    rating: number;
  //    source: string;
}
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const today = new Date();
const maxDate = new Date();
maxDate.setMonth(today.getMonth() + 1);
const schema: SchemaOf<IAffordabilityRequest> = object().shape({
  startDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Going Date is required")
    .min(new Date(), "Going date should be in future")
    .max(maxDate, "Travel Dates should be with in a month"),
  endDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Return Date is required")
    .min(yup.ref("startDate"), "Return date can't be before Going date")
    .max(maxDate, "Travel Dates should be with in a month"),
});

const proptions = [
  {
    value: 2,
    label: "Balance",
  },
  {
    value: 3,
    label: "Transactions",
  },
  {
    value: 4,
    label: "Statements",
  },
];

const hotelRating = [
  {
    value: 1,
    label: "1 Star",
  },
  {
    value: 2,
    label: "2 Star",
  },
  {
    value: 3,
    label: "3 Star",
  },
  {
    value: 4,
    label: "4 Star",
  },
  {
    value: 5,
    label: "5 Star",
  },
];
const Affordability: FC = () => {
  const { notify } = useSnackbar();
  const history = useHistory();
  interface LocationState {
    endDate: string | undefined;
    startDate: string | undefined;
    rating: object | undefined;
    country: object | undefined;
    state: any;
  }
  const location = useLocation<LocationState>().state;
  const { register, errors, handleSubmit, reset } =
    useForm<IAffordabilityRequest>({
      mode: "onChange",
      resolver: yupResolver(schema),
    });

  const [sourceCities, setSourceCities] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [county, setCounty] = useState<any>(location?.country || {});
  const [rating, setRating] = useState<any>(location?.rating || {});
  const [startDate, setStartDate] = useState<any>(location?.startDate);
  const [endDate, setEndDate] = useState<any>(location?.endDate);
  const [isDisabled, setIsDisabled] = useState<any>(true);

  useEffect(() => {
    (async () => {
      const result = await getSourceCities();
      if (result.status === 200) {
        setSourceCities(
          result.data.map((item) => {
            return { ...item, label: item.label, value: item.value };
          })
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (rating.value && county.value) {
      setIsDisabled(false);
    }
  }, [county, rating]);
  const onSubmit = async (data, e) => {
    let requestsObj = {
      startDate: moment(data.startDate).format("YYYY-MM-DD"),
      endDate: moment(data.endDate).format("YYYY-MM-DD"),
      rating: rating.value,
      source: county.value,
    };

    setLoading(true);
    const result = await getAffordability(requestsObj);
    setLoading(false);
    if (result?.data?.data?.data?.length) {
      // if (result.status === 200) {
      history.push({
        pathname: `/affordability-preview`,
        state: {
          response: result.data.data.data,
          requestsObj,
          county: county,
          rating: rating,
        },
      });
    } else {
      notify({
        message: result?.data?.data.data.message || "Something went wrong!",
        variant: "error",
      });
    }

    // }
    // }
  };

  return (
    <>
      <AffordabilityPage>
        <PageContainer>
          <PageHeader>
            <a href="/">
              <img src={Logo} alt="logo" />
            </a>
          </PageHeader>

          <HeroSection>
            <HeroTitle>
              Travel <br />
              Affordability <br />
              Calculator
              <HeroSubTitle>
                Easy way to plan your travel <br /> destinations ahead of time
              </HeroSubTitle>
            </HeroTitle>
            <HeroRightSection>
              <img src={affordabilitybanner} alt="" />
            </HeroRightSection>
          </HeroSection>

          <TravelInfo onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <InfoBlock>
                <InfoLabel>Travel Dates</InfoLabel>
                <InfoBlockWrapper>
                  <DateInfoBox>
                    <InputWrapper>
                      <DateInput
                        error={errors.startDate?.message}
                        ref={register}
                        id="startDate"
                        label="Going Date"
                        placeholder="Select Date"
                        name="startDate"
                        type="date"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.currentTarget.value);
                        }}
                      />
                    </InputWrapper>
                  </DateInfoBox>

                  <DateInfoBox>
                    <InputWrapper>
                      <DateInput
                        error={errors.endDate?.message}
                        ref={register}
                        id="endDate"
                        label="Return Date"
                        placeholder="Select Date"
                        name="endDate"
                        type="date"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.currentTarget.value);
                        }}
                      />
                    </InputWrapper>
                  </DateInfoBox>
                </InfoBlockWrapper>
              </InfoBlock>

              <InfoBlock>
                <InfoLabel>Travelling</InfoLabel>

                <TravelInfoBox className="styled__select">
                  <InputWrapper>
                    <Label>From</Label>

                    <Select
                      name="source"
                      id="source"
                      ref={register}
                      value={county?.label ? { label: county.label } : ""}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={sourceCities}
                      onChange={(event) => setCounty(event)}
                    />
                  </InputWrapper>
                </TravelInfoBox>
              </InfoBlock>

              <InfoBlock>
                <InfoLabel>Hotel Type</InfoLabel>

                <HotelTypeInfoBox className="styled__select">
                  <InputWrapper>
                    <label>Select Type</label>
                    <Select
                      name="rating"
                      id="rating"
                      ref={register}
                      value={rating?.label && { label: rating.label }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      placeholder="Select Hotel"
                      onChange={(event) => setRating(event)}
                      options={hotelRating}
                    />
                  </InputWrapper>
                </HotelTypeInfoBox>
              </InfoBlock>
            </div>
            <StyledButton disabled={isDisabled}>
              {loading ? <ButtonLoader /> : "Calculate my travel affordability"}
            </StyledButton>
          </TravelInfo>

          <Disclaimer>
            <DisclaimerTitle>
              <img src={Infoicon} alt="img" />
              Disclaimer
            </DisclaimerTitle>
            <DisclaimerDescription>
              Donec vel nulla et dui tincidunt egestas. Mauris vulputate dictum
              arcu, eget dignissim eros porta sodales. Ut ac feugiat augue, et
              sollicitudin orci. Sed eget semper tellus. Cras condimentum, velit
              at laoreet scelerisque, nunc dolor dapibus
            </DisclaimerDescription>
          </Disclaimer>
        </PageContainer>

        <FooterSection className="footersec">
          <FooterContent>
            <FooterColumn>
              <img src={Logo} alt="" />
              <AboutInfo>
                Data Analytics company and authorised <br />
                Account Information Services Provider.
              </AboutInfo>
            </FooterColumn>

            <FooterColumn>
              <FooterLinks>
                <li>
                  <FooterLink to="https://s3.af-south-1.amazonaws.com/e-doconline.ng/documents/E-Doc+ng+Privacy+Policy.pdf">Privacy Policy</FooterLink>
                </li>
                <li>
                  <FooterLink to="https://s3.af-south-1.amazonaws.com/e-doconline.ng/documents/E-Doc+Ng+Terms+%26+Conditions.pdf">Terms & Conditions</FooterLink>
                </li>
              </FooterLinks>
            </FooterColumn>

            <FooterColumn className="footercol">
              <FooterSocialLinks>
                <li>
                  <FooterSocialLink to="" target="_blank">
                    <img src={twitter_orange} alt="" />
                  </FooterSocialLink>
                </li>
                <li>
                  <FooterSocialLink to="" target="_blank">
                    <img src={instagram_orange} alt="" />
                  </FooterSocialLink>
                </li>
                <li>
                  <FooterSocialLink to="" target="_blank">
                    <img src={google_orange} alt="" />
                  </FooterSocialLink>
                </li>
                <li>
                  <FooterSocialLink to="" target="_blank">
                    <img src={linkedin_orange} alt="" />
                  </FooterSocialLink>
                </li>
              </FooterSocialLinks>

              <PoweredBy>Powered by E-Doconline</PoweredBy>
              <ReservedRight>
                © E-doc Online Limited. All rights reserved.
              </ReservedRight>
            </FooterColumn>
          </FooterContent>
        </FooterSection>
      </AffordabilityPage>
    </>
  );
};

export default Affordability;
