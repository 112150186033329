import styled from "@emotion/styled";
import { Div } from "components/styles";

export const DataViewStyle = styled.div`
  background-color: #f7f7f7;
  height: 100%;
  z-index: 1;
`;

export const LogoClickButton = styled.button`
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
export const RemoveBank = styled.button`
  position: absolute;
  right: 15px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 20px;
  z-index: 9;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: none;
  }
`;
export const DataViewHeaderStyle = styled.div`
  background-color: ${({ theme }) => theme.colors?.orange01};
  width: 100vw;
  height: 150px;
  color: ${({ theme }) => theme.colors?.white};
  line-height: 2.6rem;
  display: grid;
  grid-template-columns: 30vw 70vw;

  ${({ theme }) => theme.media?.semiLarge} {
    display: flex;
  }

  ${({ theme }) => theme.media?.semiMedium} {
    display: flex;
  }
`;

export const DataViewHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  position: relative;
  left: 65px;

  .caret-white {
    width: 20px;
    margin-right: 10px;
  }
`;

export const DataViewSideBarStyle = styled.div`
  background-color: #fff;
  width: 300px;
  padding: 20px 0;
  padding-top: 0px;
  font-size: 1.45rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  top: 0px;
  overflow: hidden;
  box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);

  ${({ theme }) => theme.media?.semiLarge} {
    min-height: 100vh;
    top: 0;
  }
  ${({ theme }) => theme.media?.semiMedium} {
    width: 100%;
    top: 0;
  }

  button.remove {
    height: 60px;
    font-weight: bold;
    font-size: 1.3rem;
    background-color: ${({ theme }) => theme.colors?.orange01};
    width: 100%;
    box-sizing: border-box;
    color: #fff;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0 54px;
    width: 100%;
    border-radius: 30px;
  }
`;

export const MainDataViewStyle = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  margin: 0 auto;
  // width: 80%;

  ${({ theme }) => theme.media?.semiLarge} {
    width: 95%;
  }

  ${({ theme }) => theme.media?.semiMedium} {
    display: block;
  }

  .content {
    padding: 30px;
  }
`;

export const SideBarContent = styled(Div)`
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  padding: 30px 30px;

  &.no-border {
    border: none;
  }

  &.single-border {
    border-top: none;
    padding: 20px 0px;
    padding-top: 0px;
  }

  &.reduced-padding-no-border {
    border-top: none;
    border-bottom: none;
    padding: 20px 30px;
  }

  &.w-100 {
    width: 100%;
  }
`;

export const BankNameStyle = styled.div`
  position: relative;
  background-color: #ededed;
  width: 100%;
  height: 70px;
  line-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

export const TransactionsCardStyle = styled.div`
  background-color: #fff;
  padding: 20px;
  margin: 0;
  margin-bottom: 30px;
  box-shadow: 0 0 5px 5px hsl(0deg 0% 40% / 8%);
`;

export const TableHeader = styled.div`
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: ${({ theme }) => theme.colors?.grey03};
  font-size: 1.4rem;
  font-weight: 500;
`;

export const TableBody = styled.div`
  display: grid;
  padding: 30px 0;
  grid-template-columns: repeat(4, 1fr);
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors?.grey04};
`;

export const BalanceHeader = styled(Div)`
  border-bottom: 1px solid #ededed;
  padding-bottom: 20px;
`;

export const ImageHolder = styled.div`
  width: 70px;
  border: 5px solid #ffffff73;
  border-radius: 100%;
  margin-right: 25px;
  position: relative;
  display: flex;
  img {
    height: 100%;
    width: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    margin-left: 3rem;
    border-right: 2px solid var(--primary-grey-color);
    height: 100%;
  }
`;
export const DropDown = styled.div`
  background-color: #f3f2f2;
  position: absolute;
  top: 48px;
  right: -10px;
  color: #252525;
  border: 1px solid #edebeb;
  width: 150px;
  text-align: center;
  border-radius: 4px;
  transition: top 300ms ease-out, opacity 0.1s ease-out;
  opacity: 0;
  visibility: hidden;

  &.visible {
    top: 45px;
    opacity: 1;
    visibility: visible;
    transition: top 300ms ease-out, opacity 0.1s ease-out;
  }

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 15px;
    height: 1rem;
    width: 1rem;
    background-color: #f3f2f2;
    border-top: 1px solid #edebeb;
    border-left: 1px solid #edebeb;
    transform: rotate(45deg);
    z-index: 98;
    opacity: 1;
    transition-delay: 0.3s;
  }
`;

export const DropDownItem = styled.div`
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
`;

export const PagintationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const TransactionsCardHead = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  ${({ theme }) => theme.media?.semiLarge} {
    justify-content: center;
  }
`;
