import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Div } from 'components/styles';
import { FC } from 'react';

interface IPageLoader {
   isAbsolute?: boolean;
   className?: string;
}

const WrapperDiv = styled.div<IPageLoader>`
   font-size: px;
   margin: 0px auto;
   text-indent: -9999em;
   width: 3em;
   height: 3em;
   border-radius: 50%;
   background: #fb5151;
   background: -moz-linear-gradient(left, #d8d8d8 10%, rgba(255, 255, 255, 0) 42%);
   background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
   background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
   background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
   background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
   position: relative;
   -webkit-animation: load3 1.4s infinite linear;
   animation: load3 1s infinite linear;
   -webkit-transform: translateZ(0);
   -ms-transform: translateZ(0);
   transform: translateZ(0);

   ${({ isAbsolute }) =>
      isAbsolute &&
      css`
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      `}

   &::before {
      width: 50%;
      height: 50%;
      background: ${({ theme }) => theme.colors?.orange01};
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
   }
   &::after {
      background: #ffffff;
      width: 75%;
      height: 75%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
   }
   @-webkit-keyframes load3 {
      0% {
         -webkit-transform: rotate(0deg);
         transform: rotate(0deg);
      }
      100% {
         -webkit-transform: rotate(360deg);
         transform: rotate(360deg);
      }
   }
   @keyframes load3 {
      0% {
         -webkit-transform: rotate(0deg);
         transform: rotate(0deg);
      }
      100% {
         -webkit-transform: rotate(360deg);
         transform: rotate(360deg);
      }
   }
`;

const PageLoader: FC<IPageLoader> = ({ isAbsolute, className }: IPageLoader) => {
   return (
      <Div className="flex-row h-100">
         <WrapperDiv isAbsolute={isAbsolute} className={className}>
            Loading...
         </WrapperDiv>
      </Div>
   );
};

PageLoader.defaultProps = {
   isAbsolute: false,
   className: '',
};

export { PageLoader };
