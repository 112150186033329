/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { resetPassword } from 'api/auth';
import { DashboardHeader } from 'components/dashboard-header/dashboard-header';
import { SchemaOf, string, ref, object } from 'yup';
import { Input } from 'components/input/input';
import { Button } from 'components/button/button';
import { useSnackbar } from 'context/snackbar/snackbar';
import { useAuth } from 'context/auth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthStyle, Form, InputWrapper, FormContainer, HeadingTwo, OtpHint } from './auth.style';


export interface IResetPassword {
   password: string;
   confirm_password: any;
   otp: any;
}

const schema: SchemaOf<IResetPassword> = object().shape({
   password: string().required('password field is required').min(12).max(30),
   otp: string().required('enter otp code'),
   confirm_password: string()
      .notRequired()
      .oneOf([ref('password'), null], 'passwords does not match'),
});

const ResetPassword = () => {
   const location: any = useLocation();
   const history = useHistory();
   const search = useLocation().search;
   const email = new URLSearchParams(search).get('email');
   const { register, errors, handleSubmit, reset } = useForm<IResetPassword>({
      mode: 'onChange',
      resolver: yupResolver(schema),
   });
   const { logout }: any = useAuth();
   const { notify } = useSnackbar();
   const [isLoading, setIsLoading] = useState(false);

   async function onSubmit(value) {
      setIsLoading(true);
      // const result = await resetPasswordApi(location.state?.token, value);
      delete value.confirm_password;
      value['email'] = email;
      const result = await resetPassword(location.state?.token, value);
     
      if (result.data.code == 500) {
         setIsLoading(false);

         notify({
            message: result?.data?.message,
            variant: 'error',
            duration: 10000,
         });
         
         return;
      }
      if (result.ok) {
         setIsLoading(false);

         notify({
            message: 'Success! Kindly Login below to gain access to your account',
            variant: 'success',
            duration: 10000,
         });
         logout();
         reset();
         setTimeout(() => {
            history.replace(`/login`);
         },1000)
        
         return;
      }
      notify({ message: 'Unknown error occured!', variant: 'error', duration: 10000 });
      setIsLoading(false);
   }

   return (
      <>
      <DashboardHeader />
      <AuthStyle className="flex-row">
         <FormContainer className="login">
            <HeadingTwo>Reset your Password</HeadingTwo>
            <Form onSubmit={handleSubmit(onSubmit)}>
               <InputWrapper>
                  <Input
                     error={errors.password?.message}
                     ref={register}
                     id="password"
                     label="Password"
                     placeholder="password"
                     name="password"
                     type="password"
                  />
               </InputWrapper>
               <InputWrapper>
                  <Input
                     error={errors.confirm_password?.message}
                     ref={register}
                     id="confirm_password"
                     label="Confirm Password"
                     placeholder="confirm password"
                     name="confirm_password"
                     type="password"
                  />
               </InputWrapper>
               <InputWrapper>
                  <Input
                     error={errors.otp?.message}
                     ref={register}
                     id="otp"
                     label="OTP"
                     placeholder="otp"
                     name="otp"
                     type="text"
                  />
                  {/* <OtpHint className='otp-hint'>We send verification code on email</OtpHint> */}
               </InputWrapper>
               <InputWrapper className="mt-20">
                  <Button fullWidth variant="primary" loading={isLoading} disabled={isLoading}>
                     Reset
                  </Button>
               </InputWrapper>
            </Form>
         </FormContainer>
      </AuthStyle>
      </>
   );
};

export default ResetPassword;
