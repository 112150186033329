import styled from "@emotion/styled";
import { Div, Span } from "components/styles";

export const AuthStyle = styled(Div)`
  position: relative;
  height: 100%;
  width: 100%;

  ${({ theme }) => theme.media?.semiMedium} {
    flex-direction: column;
    padding: 0 2rem;
  }
`;

export const TopInfoPart = styled(Div)`
  padding: 10px 0;
  margin-bottom: 20px;
`;

export const TopInfoTitle = styled(Span)`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
`;

export const TopInfoMainBox = styled(Div)`
  margin: 28px 0 20px 0;
  border: none;
  background: #212120;
  border-radius: 24px;
  padding: 22px 30px 30px 28px;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.media?.semiLarge} {
    margin: 28px 0 20px 0;
    padding: 22px 28px 30px 30px;
    display: flex;
    flex-direction: column;
  }
`;

export const TopInfoBox = styled(Div)`
  display: flex;

  ${({ theme }) => theme.media?.semiLarge} {
    margin: 0 0 20px 0;
    flex-direction: column;
  }
`;

export const TopInfoDataBox = styled(Div)`
  display: flex;
  flex-direction: column;
  margin-right: 48px;

  ${({ theme }) => theme.media?.semiLarge} {
    margin-right: 24px;
  }
`;

export const TopInfoLabel = styled(Span)`
  color: #fc5719;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 3px;

  ${({ theme }) => theme.media?.semiLarge} {
    font-size: 16px;
    margin-bottom: 3px;
  }
`;

export const TopInfoData = styled(Span)`
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;

  ${({ theme }) => theme.media?.semiLarge} {
    font-size: 28px;
    line-height: 39px;
  }
`;

export const LevelIndicator = styled(Div)`
  margin: 0 0 54px 0;
  width: max-content;
  border: none;
  background: #212120;
  border-radius: 24px;
  padding: 14px 20px 15px 21px;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.media?.semiLarge} {
    flex-wrap: wrap !important;
    flex-direction: column;
  }
`;

export const LevelIndicatorBox = styled(Div)`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 65px;
  }

  ${({ theme }) => theme.media?.semiLarge} {
    margin-right: 40px;
  }
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const LevelIndicatorMainTitle = styled(Span)`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  width: 148px;
  text-align: right;

  ${({ theme }) => theme.media?.semiLarge} {
    width: 100%;
    text-align: center;
  }
`;

export const LevelIndicatorIcon = styled.img`
  height: 26px;
  width: 26px;
  margin-right: 15px;
`;

export const LevelIndicatorTitle = styled(Span)`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
`;

export const AvailableDestinations = styled(Div)`
  margin-bottom: 80px;
  ${({ theme }) => theme.media?.semiLarge} {
    overflow-x: auto;
  }
`;

export const AvailableDestinationsInfo = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  ${({ theme }) => theme.media?.semiLarge} {
    flex-direction: column;
    align-items: start;
  }
`;

export const AvailableDestinationsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    padding: 12px 0;
    border: 4px solid #2d2d2d;
  }
  tr {
    background: #212120;
    border-radius: 12px;
  }

  td:first-child,
  th:first-child {
    border-radius: 12px 0 0 12px;
  }

  td:last-child,
  th:last-child {
    border-radius: 0 12px 12px 0;
  }

  td:first-child,
  tr:first-child {
    border-radius: 12px 0 0 12px;
  }

  td:last-child,
  tr:last-child {
    border-radius: 0 12px 12px 0;
  }
`;

export const AvailableHeadingBox = styled(Div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media?.semiLarge} {
    flex-direction: column;
  }
`;

export const AvailableHeadingTitle = styled(Span)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fc5719;
  margin-left: 15px;

  ${({ theme }) => theme.media?.semiLarge} {
    margin-left: 0;
  }
`;

export const AvailableDestinationsData = styled.td`
  padding: 12px 12px;
  border: 4px solid #2d2d2d;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
`;

export const AffordableCost = styled(AvailableDestinationsData)`
  color: #40ea8f;
`;

export const ManageableCost = styled(AvailableDestinationsData)`
  color: #ffbb36;
`;

export const InsufficientCost = styled(AvailableDestinationsData)`
  color: #ff5959;
`;

export const AvailableHotelPerDay = styled(Div)`
  display: flex;
  flex-direction: column;

  > small {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #a3a3a3;
  }
`;

export const AvailableHotelMeals = styled(Div)`
  display: flex;
  flex-direction: column;

  > small {
    font-weight: 300;
    font-size: 18px;
    line-height: 34px;
    color: #ffffff;
  }

  ${({ theme }) => theme.media?.semiLarge} {
    > small {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const AvailableDestinationsTitle = styled(Span)`
  font-weight: 300;
  font-size: 24px;
  line-height: 38px;
  color: #ffffff;
`;

export const AvailableDestinationsInfoTitle = styled(Span)`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
`;

export const AvailableDestinationsSection = styled.tr`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;

  background-color: transparent;
  text-align: right;

  > td {
    padding-top: 78px;
    padding-bottom: 22px;
  }

  ${({ theme }) => theme.media?.semiLarge} {
    text-align: left;
  }
`;
