/* eslint-disable @typescript-eslint/no-explicit-any */
import { Http } from "api";
import { ApiResponse } from "apisauce";

export const redirectToBankPage = async (
  payload
): Promise<ApiResponse<any>> => {
  return Http.post(`orchestrator/initiateConsent`, payload, {
    headers: {
      "x-fapi-financial-id": "test",
      "x-fapi-interaction-id": "ab49c02c-623a-484c-bbd2-0c0e52c7c58b",
    },
  });
};

export const registerApi = async (payload: any): Promise<ApiResponse<any>> => {
  return Http.post(`/auth/register`, payload);
};

export const sendConsentData = async (
  payload: any
): Promise<ApiResponse<any>> => {
  return Http.post(`/orchestrator/initiateConsent`, payload);
};

export const getConsents = async (payload: any): Promise<ApiResponse<any>> => {
  return Http.post(`/orchestrator/getExistingConsent`, payload);
};

export const getAccount = async (payload: any): Promise<ApiResponse<any>> => {
  return Http.post(`/banking`, payload);
};
export const getAccountById = async (
  payload: any,
  id: any
): Promise<ApiResponse<any>> => {
  return Http.post(`/banking/${id}`, payload);
};

export const getTransaction = async (
  payload: any
): Promise<ApiResponse<any>> => {
  return Http.post(`/banking/${payload.AccountId}/transactions`, payload);
};
export const getKyc = async (
  payload: any
): Promise<ApiResponse<any>> => {
  return Http.post(`/dashboard/get-kyc`, payload);
};

export const getBalance = async (payload: any): Promise<ApiResponse<any>> => {
  return Http.post(`/banking/${payload.AccountId}/balances`, payload);
};

export const getStatement = async (payload: any): Promise<ApiResponse<any>> => {
  return Http.post(`/banking/${payload.AccountId}/statements`, payload);
};

export const sendConsentComplete = async (
  params: any,
  payload: any
): Promise<ApiResponse<any>> => {
  return Http.put(`/orchestrator/completeJourney?` + params, payload);
};

export const getProduct = async () => {
  return Http.get(`/products`);
};
export const getPermission = async () => {
  return Http.get(`/permissions`);
};
export const getBusinessPartner = async () => {
  return Http.get(`/businessPartner/getBusinessPartners`);
};
export const sendBusiness = async (payload: any): Promise<ApiResponse<any>> => {
  return Http.post(`/entitlement/business`, payload);
};
export const getClientConsents = async (
  params: any
): Promise<ApiResponse<any>> => {
  return Http.get(`/partner-banking/clientConsents/` + params);
};
export const getAccountData = async (
  payload: any
): Promise<ApiResponse<any>> => {
  return Http.post(`/partner-banking/accounts`, payload);
};
export const getUserData = async (payload: any): Promise<ApiResponse<any>> => {
  return Http.post(`/partner-dashboard/user`, payload);
};
