import styled from '@emotion/styled';

export const SideBrandContainerStyle = styled.div`
   background-color: #f5f5f5;
   text-align: center;
   display: flex;
   align-items: center;
   flex-direction: column;
   padding: 0 20px;
   padding-top: 40%;

   ${({theme}) => theme.media?.semiMedium} {
      padding-top: 0;
   }
`;

export const PermissionImg = styled.img`
   width: 30px;
`;
