import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React, { ButtonHTMLAttributes } from 'react';
import { ButtonLoader } from '../button-loader';

interface IButtonProp extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'css'> {
   fullWidth?: boolean;
   variant?: 'primary' | 'secondary';
   loading?: boolean;
   variantType?: 'grey' | 'black';
   children: React.ReactNode;
}

type EmotionProps = IButtonProp & { theme: Theme };

function variantButtonStyles({ theme, variant, variantType }: EmotionProps) {
   switch (variant) {
      case 'secondary':
         return css`
            color: ${variantType ? theme.colors?.grey06 : theme.colors?.black};
            background-color: ${theme.colors?.transparent};
            border: 1px solid ${theme.colors?.grey03};

            &:hover,
            &:active {
               color: ${theme.colors?.white};
               background-color: ${theme.colors?.orange01};
               border: 1px solid transparent;
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors?.grey02};
               border-color: ${variantType ? theme.colors?.grey06 : theme.colors?.grey02};
               background-color: ${theme.colors?.transparent};
               cursor: not-allowed;
            }
         `;
      case 'primary':
      default:
         return css`
            background-color: ${theme.colors?.orange01};
            border: 1px solid none;
            color: ${theme.colors?.white};

            ${theme.media?.gteLarge} {
               &:hover {
                  color: #ED7D32;
                  opacity: 0.8;
               }

               &:active {
                  background-color: ${theme.colors?.orange02};
               }
            }

            &:active {
               background-color: ${theme.colors?.orange02};
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               background-color: ${theme.colors?.grey02};
               border-color: ${theme.colors?.grey02};
               color: ${theme.colors?.white};
               cursor: not-allowed;
            }
         `;
   }
}

const ButtonComponent = styled.button`
   border: 0;
   padding: 0;
   cursor: pointer;
   box-sizing: border-box;
   display: inline-block;
   max-width: 100%;
   text-align: center;
   padding: 1.2rem 2.4rem;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   font-size: 1.6rem;
   letter-spacing: 0;
   line-height: 1.6rem;
   font-weight: ${({ theme }) => theme.fontWeights?.medium};
   border-radius: 30px;
   text-transform: capitalize;
   transition: transform 300ms ease-in-out;
   animation: shadow-pulse-blue 2s infinite;
   border: 1px solid transparent;
   &:hover{
      background-color: transparent;
      border-color:#ED7D32;
      color:#ED7D32;
   }

   ${variantButtonStyles};

   ${({ fullWidth }) =>
      fullWidth &&
      css`
         width: 100%;
      `};

   ${({ theme }) => theme.media?.semiMedium} {
      font-size: 1.2rem;
      padding: 1rem 1.6rem;
   }

   &.animate {
      -webkit-animation: scaling 1s ease-in-out;
      animation: scaling 1s ease-in-out;
   }

   @keyframes scaling {
      0% {
         transform: scale(1);
      }
      50% {
         transform: scale(1.05);
      }
      100% {
         transform: scale(1);
      }
   }
`;

const Button: React.FC<IButtonProp> = ({ loading, variant, variantType, children, ...rest }) => (
   <ButtonComponent variant={variant} variantType={variantType} loading={loading} {...rest}>
      {loading ? <ButtonLoader /> : children}
   </ButtonComponent>
);

export { Button };
