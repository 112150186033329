/* eslint-disable camelcase */
import { verifyPasswordToken } from 'api/auth';
import { useSnackbar } from 'context/snackbar/snackbar';
import { useEffect } from 'react';
import { Div } from 'components/styles';

import { useHistory, useParams } from 'react-router-dom';

const VerifyResetPasswordToken = () => {
   const { token } = useParams<{ token: string }>();
   const { notify } = useSnackbar();
   const history = useHistory();

   useEffect(() => {
      (async () => {
         const result = await verifyPasswordToken(token as string);
         if (result.ok) {
            notify({
               message: 'Success!, Kindly Reset your password below',
               variant: 'success',
               duration: 10000,
            });
            return history.replace({
               pathname: `/reset-user-password`,
               state: {
                  token,
               },
            });
         }
         notify({
            message: 'An Error occured!. Kindly try again',
            variant: 'error',
            duration: 10000,
         });

         history.replace('/login');
      })();
   }, []);

   return (
      <Div className="d-flex justify-content-center align-items-center vh-100">Redirecting...</Div>
   );
};

export default VerifyResetPasswordToken;
