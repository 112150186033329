import React from "react";
import {
  PageWrapper,
  TableWrapper,
  TableStyled,
  TableHeadStyled,
  TableRowStyled,
  TableDataStyled,
  THeadStyled,
  TBodyStyled,
  AvailableDestinationsTable,
  AvailableHeadingTitle,
  AvailableDestinationsData,
  TableCard,
  ThankButtonWrapper,
  ListHeader,
} from "./list.style";
import { Button } from "components/button/button";
import { useHistory } from "react-router-dom";
import { AvailableHeadingBox } from "pages/affrdability-preview/affrodability-preview.style";

const TransactionList = () => {
  const history  = useHistory()
  return (
    <PageWrapper>
      <AvailableHeadingBox>
      <AvailableHeadingTitle style={{marginBottom:"10rem",fontSize:"30px"}}>
        KYC Report
      </AvailableHeadingTitle>
      </AvailableHeadingBox>
      <ListHeader />
      <TableCard>
        <TableWrapper>
          <TableStyled>
            <THeadStyled>
              <TableRowStyled>
                <TableHeadStyled>Matching Criteria</TableHeadStyled>
                <TableHeadStyled>BVN</TableHeadStyled>
                <TableHeadStyled>Passport</TableHeadStyled>
                <TableHeadStyled>Open Banking</TableHeadStyled>
              </TableRowStyled>
            </THeadStyled>

            <TBodyStyled>
              <TableRowStyled>
                <TableDataStyled>First Name</TableDataStyled>
                <TableDataStyled>Match</TableDataStyled>
                <TableDataStyled>Match</TableDataStyled>
                <TableDataStyled>Match</TableDataStyled>
              </TableRowStyled>
              <TableRowStyled>
                <TableDataStyled>Last Name</TableDataStyled>
                <TableDataStyled>Partial Match</TableDataStyled>
                <TableDataStyled>Match</TableDataStyled>
                <TableDataStyled>Match</TableDataStyled>
              </TableRowStyled>
              <TableRowStyled>
                <TableDataStyled>DOB</TableDataStyled>
                <TableDataStyled>No Match</TableDataStyled>
                <TableDataStyled>Partial Match</TableDataStyled>
                <TableDataStyled>Match</TableDataStyled>
              </TableRowStyled>
            </TBodyStyled>
          </TableStyled>
        </TableWrapper>

        <ThankButtonWrapper>
          <Button variant="primary"  onClick={() => history.push('/login')}>Thank you!!</Button>
        </ThankButtonWrapper>
      </TableCard>

      {/* <AvailableDestinationsTable>
        <thead>
          <tr>
            <th>
              <AvailableHeadingTitle>Destination</AvailableHeadingTitle>
            </th>
            <th>
              <AvailableHeadingTitle>Air Fares</AvailableHeadingTitle>
            </th>
            <th>
              <AvailableHeadingTitle>Hotel Per Day</AvailableHeadingTitle>
            </th>
            <th>
              <AvailableHeadingTitle>Meals</AvailableHeadingTitle>
            </th>
            <th>
              <AvailableHeadingTitle>Cost</AvailableHeadingTitle>
            </th>
            <th>
              <AvailableHeadingTitle>Affordability Level</AvailableHeadingTitle>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <AvailableDestinationsData>512</AvailableDestinationsData>
            <AvailableDestinationsData>120</AvailableDestinationsData>
          </tr>
        </tbody>
      </AvailableDestinationsTable> */}
    </PageWrapper>
  );
};

export default TransactionList;