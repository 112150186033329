import { SideDrawer } from "components/side-drawer/side-drawer";

import {
  AmountCount,
  MoadalName,
  PermissionSidebarDetails,
  SideCard,
  SideCardDetails,
  SideCardDetailsWrapper,
  TableDataStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
  TableWrapper,
  TBodyStyled,
  THeadStyled,
  TotalAmount,
  TotalAmountCard,
  TotalTitle,
  YearCount,
} from "./tabs.style";

const MonthlyExpenses = ({
  visible,
  expensesData,
  expensesAmount,
  handleClose,
}) => {
  return (
    <>
      <SideDrawer returnText="Back" visible={visible} handleClose={handleClose}>
        <PermissionSidebarDetails>
          <MoadalName>Expenses</MoadalName>

          <TableWrapper>
              <div style={{width:'100%'}}>
              <TableStyled>
                <THeadStyled>
                  <TableRowStyled>
                    <TableHeadStyled>Monthly</TableHeadStyled>
                    <TableHeadStyled>Amount</TableHeadStyled>
                  </TableRowStyled>
                </THeadStyled>

                <TBodyStyled>
                  {expensesData.map((row) => (
                    <TableRowStyled key={row.month}>
                      <TableDataStyled>{row.month}</TableDataStyled>
                      <TableDataStyled>£ {row.amount}</TableDataStyled>
                    </TableRowStyled>
                  ))}
                </TBodyStyled>
              </TableStyled>

              <TotalAmountCard>
                <TotalTitle>Total</TotalTitle>
                <TotalAmount>£ {expensesAmount.toFixed(2)}</TotalAmount>
              </TotalAmountCard>
            </div>
            {/* <SideCard>
              <span>Yearly</span>
              <hr />

              <SideCardDetailsWrapper>
                <SideCardDetails>
                  <YearCount>2022</YearCount>
                  <AmountCount>£ 4,800,000.00</AmountCount>
                </SideCardDetails>

                <SideCardDetails>
                  <YearCount>2021</YearCount>
                  <AmountCount>£ 3,450,000.00</AmountCount>
                </SideCardDetails>

                <SideCardDetails>
                  <YearCount>2020</YearCount>
                  <AmountCount>£ 5,100,000.00</AmountCount>
                </SideCardDetails>
              </SideCardDetailsWrapper>
            </SideCard>{" "} */}
          </TableWrapper>
        </PermissionSidebarDetails>
      </SideDrawer>
    </>
  );
};

export { MonthlyExpenses };
