import { FC } from 'react';
import { Navbar } from 'components/navbar/navbar';
import { Div } from 'components/styles';
import { useLocation } from 'react-use';
import LogoActive from 'assets/images/logo-active.png';
import { EmailConfimation, AuthStyle } from './auth.style';

export interface ILoginData {
   email: string;
   password: string;
}

const EmailConfirmationInfo: FC = () => {
   const location = useLocation();
   const { email } = location?.state?.state || {};

   return (
      <>
         <Navbar />
         <AuthStyle className="flex-row">
            
            <EmailConfimation>
               <div className='logo-box'>
                  <a href="/"><img src={LogoActive} alt="logo" className='logo'/></a>
               </div>
               <Div className="heading">Check your Inbox</Div>
               <Div className="text-grey font-20 mt-20">{email}</Div>
               <Div className="font-15 mt-30 text-center">
                  A confirmation email has been sent to the email address above. Please click the{' '}
                  <br />
                  verification link in the email to sign in. If you don’t see the email, check other
                  places it might be, like <br /> your junk, spam, social, or other folders
               </Div>
            </EmailConfimation>
         </AuthStyle>
      </>
   );
};

export default EmailConfirmationInfo;
