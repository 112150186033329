/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useMemo, useState } from 'react';
import { Input } from 'components/input/input';
import { Navbar } from 'components/navbar/navbar';
import { Button } from 'components/button/button';
import { useForm } from 'react-hook-form';
import { Lynk } from 'components/styles/index';
import { useSnackbar } from 'context/snackbar/snackbar';
import { Div } from 'components/styles';
import { requestPasswordResetApi, sendOtp } from 'api/auth';
import { object, SchemaOf, string } from 'yup';
import { AuthStyle, Form, InputWrapper, FormContainer, HeadingOne, HeadingTwo } from './auth.style';
import { useHistory } from 'react-router-dom';
import { DashboardHeader } from 'components/dashboard-header/dashboard-header';

export interface IForgotPasswordData {
   email: string;
}

const ChangePassword: FC = () => {
   const [isLoading, setIsLoading] = useState(false);
   const { notify } = useSnackbar();
   const history = useHistory();
   const schema: SchemaOf<IForgotPasswordData> = useMemo(
      () =>
         object().shape({
            email: string().required('email field is required').email('Please enter valid email'),
         }),
      [],
   );

   const { register, errors, handleSubmit, reset } = useForm<IForgotPasswordData>({
      mode: 'onChange',
      resolver: yupResolver(schema),
   });

   async function onSubmit(payload: IForgotPasswordData) {
      setIsLoading(true);
      // const result = await requestPasswordResetApi(payload);
      const result = await sendOtp(payload);
      if (result.ok) {
         setIsLoading(false);
         reset();
         history.push(`/reset-user-password?email=${payload.email}`);
         return notify({
            message: result?.data?.data,
            variant: 'success',
            duration: 10000,
         });
      }

      setIsLoading(false);
      notify({
         message: result?.data?.message || result?.data?.error?.message,
         variant: 'error',
         duration: 10000,
      });
   }

   return (
      <>
         <DashboardHeader />
         <AuthStyle className="flex-row">
            <FormContainer className="login">
               {/* <HeadingOne>Forgot your password ?</HeadingOne> */}
               <HeadingTwo>Please enter your email</HeadingTwo>
               <Form onSubmit={handleSubmit(onSubmit)}>
                  <InputWrapper>
                     <Input
                        error={errors.email?.message}
                        ref={register}
                        id="email"
                        label="Email Address"
                        placeholder="Yourmail@email.com"
                        name="email"
                     />
                  </InputWrapper>
                  <InputWrapper className="mt-20">
                     <Button type="submit" fullWidth variant="primary" loading={isLoading} disabled={isLoading}>
                        Send
                     </Button>
                  </InputWrapper>
               </Form>
               {/* <Div className="text-center">
                  I Remember my password.
                  <Lynk to="/login">&nbsp; Login</Lynk>
               </Div> */}
            </FormContainer>
         </AuthStyle>
      </>
   );
};

export default ChangePassword;
