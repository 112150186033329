/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useMemo, useState } from 'react';
import { Input } from 'components/input/input';
import { Navbar } from 'components/navbar/navbar';
import { Button } from 'components/button/button';
import { useForm } from 'react-hook-form';
import { Lynk } from 'components/styles/index';
import { useSnackbar } from 'context/snackbar/snackbar';
import { Div } from 'components/styles';
import LogoActive from 'assets/images/logo-active.png';
import { requestPasswordResetApi, sendOtp } from 'api/auth';
import { object, SchemaOf, string } from 'yup';
import { AuthStyle, Form, InputWrapper, FormContainer, HeadingOne, HeadingTwo } from './auth.style';
import { useHistory } from 'react-router-dom';

export interface IForgotPasswordData {
   email: string;
}

const ForgotPassword: FC = () => {
   const [isLoading, setIsLoading] = useState(false);
   const { notify } = useSnackbar();
   const history = useHistory();
   const schema: SchemaOf<IForgotPasswordData> = useMemo(
      () =>
         object().shape({
            email: string().required('Email is required').email('Please enter valid email'),
         }),
      [],
   );

   const { register, errors, handleSubmit, reset } = useForm<IForgotPasswordData>({
      mode: 'onChange',
      resolver: yupResolver(schema),
   });

   async function onSubmit(payload: IForgotPasswordData) {
      setIsLoading(true);
      // const result = await requestPasswordResetApi(payload);
      const result = await sendOtp(payload);
      if (result.ok) {
         setIsLoading(false);
         
         history.push(`/reset-forgot-password?email=${payload.email}`)
         reset();
         return notify({
            message: 'We have sent an OTP to your email box. It will expire in 15 mins.',
            variant: 'success',
            duration: 10000,
         });
      }else {
         setIsLoading(false);
         notify({
            message: result?.data?.message || result?.data?.error?.message,
            variant: 'error',
            duration: 10000,
         });
      }

      
   }

   return (
      <>
         <Navbar />
         <AuthStyle className="flex-row">
            <FormContainer className="login">
               <div className='logo-box'>
                  <a href="/"><img src={LogoActive} alt="logo" className='logo'/></a>
               </div>
               <HeadingOne>Forgot your password ?</HeadingOne>
               {/* <HeadingTwo>Please enter your email...</HeadingTwo> */}
               <Form onSubmit={handleSubmit(onSubmit)}>
                  <InputWrapper>
                     <Input
                        error={errors.email?.message}
                        ref={register}
                        id="email"
                        label="Email Address"
                        placeholder="Yourmail@email.com"
                        name="email"
                     />
                  </InputWrapper>
                  <InputWrapper className="mt-20">
                     <Button fullWidth variant="primary" loading={isLoading} disabled={isLoading}>
                        Reset My Password
                     </Button>
                  </InputWrapper>
               </Form>
               {/* <Div className="text-center">
                  I Remember my password.
                  <Lynk to="/login">&nbsp; Login</Lynk>
               </Div> */}
            </FormContainer>
         </AuthStyle>
      </>
   );
};

export default ForgotPassword;
