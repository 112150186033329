import { ModalStyle, ModalBackDrop } from './modal.atyle';

interface IModalProps {
   children: React.ReactNode;
   visible: boolean;
   width?: string;
   height?: string;
   background?: string;
   handleClose: () => void;
}

const Modal = ({ children, visible, handleClose, width, height, background }: IModalProps) => {
   return (
      <>
         <ModalBackDrop className={visible ? 'visible' : ''} onClick={handleClose} />
         <ModalStyle
            background={background}
            width={width}
            height={height}
            className={visible ? 'visible' : ''}
         >
            {children}
         </ModalStyle>
      </>
   );
};

Modal.defaultProps = {
   width: '550px',
   height: '400px',
   background: '#fff',
};

export { Modal };
