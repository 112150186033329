/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const TOKEN = '<<<<token>>>>';
const USER_DATA = '<<<<user-data>>>>';

export const saveCookie = (token: string) => {
   cookies.set(TOKEN, token, { path: '/' });
};

export const getCookie = () => {
   return cookies.get(TOKEN);
};

export const saveUserInfoToStorage = (payload: Record<string, string | number | undefined>) => {
   
   // localStorage.setItem(USER_DATA, JSON.stringify({firstName: "niraj"}));
   try {
      if (payload.access_token) {
         delete payload.access_token;
      }
      localStorage.setItem(USER_DATA, JSON.stringify(payload));
   } catch (e) {
      console.log(e);
   }
};

export const getUserInfoFromStorage = () => {
   try {
      return JSON.parse(localStorage.getItem(USER_DATA) as string);
   } catch (e) {
      console.log(e);
   }
};

export const clearStorage = () => {
   try {
      localStorage.removeItem(USER_DATA);
      cookies.remove(TOKEN);
   } catch (e) {
      console.log(e);
   }
};
