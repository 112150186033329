import React, { useEffect, useState } from "react";
import {
  BoxCard,
  BoxCardTable,
  CircularProgressWrapper,
  CircularProgressWrapperMain,
  IncomeCardHead,
  IncomePageWrapperMain,
  IncomePageWrapperTop,
  RatingType,
  ListWrapper,
  IncomeTable,
  CompanyDetails,
  RiskInsights,
  MatrixTable,
} from "./IncomeAndJobVerification.style";
import {
  Collapse,
  IconButton,
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
  styled,
} from "@mui/material";
import { useMemo } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DashboardOverviewHeader } from "../../../components/dashboard-overview/dashoard-overview-header";
import { useSnackbar } from "../../../context/snackbar/snackbar";

import moment from "moment";

import { MainWrapper, OverviewWrapper } from "../dashboard.overview.style";
import { MonthlyAffordability } from "../tabs/monthly-affordability";
import { MonthlyExpenses } from "../tabs/monthly-expenses";
import { MonthlyIncome } from "../tabs/monthly-income";
import { getAccount, getAccountData, getClientConsents, getConsents, getKyc, getTransaction, getUserData } from "api/bank";
import CircularProgress from "components/CircularProgress/CircularProgress";
import styles from "./styles.module.css";
import { PageLoader } from "components/page-loader/page-loader"
const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const IncomeAndJobVerification = () => {
  const [incomeTab, setIncomeTab] = useState(false);
  const [endData, setEndData] = useState('2023-05-16')
  const [companyDetailData, setCompanyDetailsData]:any = useState({
    company_number: "",
    company_name: "",
    company_status: "",
    date_of_creation: ''
  })
  const { notify } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false)
  const [selectedConsent, setSelectedConsent] = useState({})
  const [data, setData]: any = useState();
  const [incomeAmount, setIncomeAmount] = useState(0);
  const [expensesAmount, setExpensesAmount] = useState(0);
  const [expensesData, setExpensesData]: any = useState([]);
  const [incomesData, setIncomesData]: any = useState([]);
  const [affordabilityData, setAffordabilityData]: any = useState([]);
  const [accountId, setAccountId] = useState()
  const [dashboardList, setDashboardList]: any = useState([])
  const [keyInfo, setKeyInfo]: any = useState({})
  const [selectedDate, setSelectedDate] = useState()
  const [totalTransactionsCount, setTotalTransactionsCount] = useState(0)
  const [metrics, setMetrics]:any = useState([])
  const [accountName, setAccountName] = useState()
  const [officers,setOfficers]= useState([])
  const [filingHistory,setFilingHistory]:any= useState([])
  const [topTransaction,setTopTransaction] = useState({
    inFlow:[],
    outFlow:[]
  })
  const [bottomTransaction,setBottomTransaction] = useState({
    inFlow:[],
    outFlow:[]
  })
  const [topPaymentSME,setTopPaymentSME] = useState({
    inFlow:[],
    outFlow:[]
  })
  const [topPaymentLME,setTopPaymentLME] = useState({
    inFlow:[],
    outFlow:[]
  })
  
  const [transactionInfo, setTransactionInfo]:any = useState();
  const [filterTransactionInfo, setFilterTransactionInfo]:any = useState([]);

  const [topInBound, setTopInBound] = useState(false);
  const [topOutBound, setTopOutBound] = useState(false);
  const [bottomInBound, setBottomInBound] = useState(false);
  const [bottomOutBound, setBottomOutBound] = useState(false);
  const [top5InBoundPaymentSME, setTop5InBoundPaymentSME] = useState(false);
  const [top5OutBoundPaymentSME, setTop5OutBoundPaymentSME] = useState(false);
  const [top5InBoundPaymentLME, setTop5InBoundPaymentLME] = useState(false);
  const [top5OutBoundPaymentLME, setTop5OutBoundPaymentLME] = useState(false);
  const [financialMatrics, setFinancialMatrics] = useState(false);

  
  const [isCheck, setIsCheck] = useState({
    isSalary: false,
    isDividends: false,
    businessIncome: false,
    investments: false,
    others: false,
  });

  const userData = JSON.parse(
    localStorage.getItem("<<<user-info>>>") as string
  );
  const [transactionDate, setTransactionDate] = useState({
    fromDate: moment().subtract(12, "month").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  });
  // const [setTransactions,setSetTransactions] = useState([]);
  const [isVerification, setIsVerification]: any = useState({
    isAddressVerification: true,
    isBankingVerification: true,
    isCustomerVerification: true,
  });
  const [sourceOfInCome, setSourceOfInCome] = useState({
    isSalary: false,
    isDividends: false,
    isBusinessIncome: false,
    isInvestments: false,
    isOthers: false
  });
  const isEmpty = (obj: any) => Object.keys(obj).length === 0;
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      // setIsLoading(true);
      const result = await getConsents({ bankName: "all" });
      if (result.ok) {

        setSelectedDate(result?.data?.data[0]?.createdAt)
        // setIsLoading(false);
        if (result?.data?.data?.length != 0) {
          // setSelectedConsent(result.data?.data[0]);
        } else {
          setIsLoading(false);
        }
        // setIsLoading(false);
        // return;
      } else {
        notify({
          message: result.data?.error?.message || result?.data?.message,
          variant: "error",
        });
        setIsLoading(false);
      }
      // setIsLoading(false);
    })();
  }, []);
  // useEffect(() => {
  //   // (async () => {
  //   //   const result = await getAccount({ bankName: selectedConsent['bankName'] });
  //   //   if (result.ok) {
  //   //     setAccountId(result?.data?.data?.Data?.Account[0]?.AccountId);
  //   //   } else {
  //   //     //   setIsLoading(false);
  //   //   }
  //   // })();
  //   }, [selectedConsent]);
  useEffect(() => {
    const dashboardList: any = [];
    if (accountId != "") {
      (async () => {
        if (userData.id && selectedDate) {
          const result = await getKyc({
            "userId": userData.id,
            "date_to": selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : "",
            "n_months": 12,
            "account_id": null
          });
          if (result?.data?.code === 200) {
            let obj = {
              isSalary: false,
              isDividends: false,
              isBusinessIncome: false,
              isInvestments: false,
              isOthers: false
            }
            if (!isEmpty(result?.data?.data?.kycDetails)) {
              setMetrics(result?.data?.data?.kycDetails?.monthly)
              setCompanyDetailsData(result?.data?.data?.companyDetails)
              setKeyInfo(result?.data?.data?.kycDetails)
              setTransactionInfo(result?.data?.data?.kycDetails);
              setTotalTransactionsCount(result?.data?.data?.kycDetails?.data.length)
              setOfficers(result?.data?.data?.companyDetails?.officers || [])
              setFilingHistory(result?.data?.data?.companyDetails?.filingHistory || [])
              setTopTransaction({
                inFlow: result?.data?.data.kycDetails.top_5_inflows,
                outFlow: result?.data?.data.kycDetails.top_5_outflows,
              });
              setBottomTransaction({
                inFlow: result?.data?.data.kycDetails.bottom_5_inflows,
                outFlow: result?.data?.data.kycDetails.bottom_5_outflows,
              });
              setTopPaymentSME({
                inFlow: result?.data?.data.kycDetails.small_business_inflows,
                outFlow: result?.data?.data.kycDetails.small_business_outflows,
              });
              setTopPaymentLME({
                inFlow: result?.data?.data.kycDetails.large_business_inflows,
                outFlow: result?.data?.data.kycDetails.large_business_outflows,
              });
              result?.data?.data?.kycDetails?.data.forEach((item) => {
                if (item?.category?.match('Inbound transfer')) {
                  obj.isSalary = true
                  item['category'] = 'Salary'
                  dashboardList.push(item)
                }
                else if (item?.category?.match('MONZO') ||
                  item?.category?.match('dividends') ||
                  item?.category?.match('dividend')
                  && item.CreditDebitIndicator === "Credit") {
                  obj.isDividends = true
                  item['category'] = 'Divident'
                  dashboardList.push(item)
                }
                else if (item?.category?.match('income') && item.CreditDebitIndicator === "Credit") {
                  obj.isBusinessIncome = true
                  item['category'] = 'Business Income'
                  dashboardList.push(item)
                }
                else if (item?.category?.match('interest') || item?.category?.match('investment') && item.CreditDebitIndicator === "Credit") {
                  obj.isInvestments = true
                  item['category'] = 'Investments'
                  dashboardList.push(item)
                }
                else if (item?.category?.match('Others') || item?.category?.match('Other') && item.CreditDebitIndicator === "Credit") {
                  obj.isOthers = true
                  item['category'] = 'Others'
                  dashboardList.push(item)
                }
                setSourceOfInCome({ ...obj })
              })
              setDashboardList(dashboardList)
              setIsLoading(false);

            } else {
              // setSetTransactions([]);
            }
          } else {
            // setIsLoading(false);
          }
        }
      })();
    }
  }, [selectedDate]);

  useEffect(() => {
    let obj = {
      isSalary: false,
      isDividends: false,
      businessIncome: false,
      investments: false,
      others: false,
    };
    transactionInfo?.data?.length &&
      transactionInfo?.data.forEach((item) => {
        if (item?.category?.match("Business Income")) {
          obj = { ...obj, businessIncome: false };
        } else if (item?.category?.match("Salary")) {
          obj = { ...obj, isSalary: true };
        } else if (item?.category?.match("Dividend")) {
          obj = { ...obj, isDividends: true };
        } else {
          obj = { ...obj, others: true };
        }
      });
    setFilterTransactionInfo(transactionInfo?.data);
    setIsCheck({ ...obj });
  }, [transactionInfo]);

  const handleSelect = (data) => {
    setSelectedConsent(data);
  };


  // const handleIncomeTab = () => {
  //   closeIncomeTab(true);
  // };
  const closeIncomeTab: any = () => {
    closeIncomeTab(false);
  };

  const [expenses, setExpenses] = useState(false);
  const handleExpensesTab = () => {
    setExpenses(true);
  };
  const closeExpenses = () => {
    setExpenses(false);
  };

  const [affordability, setAffordability] = useState(false);
  const handleAffordabilityTab = () => {
    setAffordability(true);
  };
  const closeAffordability = () => {
    setAffordability(false);
  };

  const themeOptions = useMemo(
    () => ({
    }),
    []
  );
  const theme = createTheme(themeOptions);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (<StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <OverviewWrapper>
        <DashboardOverviewHeader
          incomesData={incomesData}
          incomeAmount={incomeAmount}
          expensesData={expensesData}
          expensesAmount={expensesAmount}
          affordabilityData={affordabilityData}
          affordabilityAmount={incomeAmount - expensesAmount}
        />

        <MonthlyIncome
          visible={incomeTab}
          incomesData={incomesData}
          incomeAmount={incomeAmount}
          handleClose={closeIncomeTab}
        />

        <MonthlyExpenses
          visible={expenses}
          expensesData={expensesData}
          expensesAmount={expensesAmount}
          handleClose={closeExpenses}
        />

        <MonthlyAffordability
          visible={affordability}
          affordabilityData={affordabilityData}
          affordabilityAmount={incomeAmount - expensesAmount}
          handleClose={closeAffordability}
        />

        <MainWrapper>
          <IncomePageWrapperMain>
            <IncomePageWrapperTop>
              <BoxCard>
                <div>
                  <IncomeCardHead>Account and Company House Details</IncomeCardHead>
                </div>
                <CompanyDetails>
                  <ul>
                    <li>Company Name:- {companyDetailData?.company_name}</li>
                    <li>Company Address:- {companyDetailData?.registered_office_address?.address_line_1},{companyDetailData?.registered_office_address?.address_line_2},{companyDetailData?.registered_office_address?.locality},{companyDetailData?.registered_office_address?.postal_code},{companyDetailData?.registered_office_address?.region}</li>
                    <li>Company Number:- {companyDetailData?.company_number}</li>
                    <li>Company Status:- {companyDetailData?.company_status}</li>
                    <li>Date Of Creation:- {companyDetailData?.date_of_creation}</li>
                    <li>No Of Directors :- 
                      <ul>{officers && officers.length ? officers.map((i:any)=><li>{i?.name}</li>):""}
                      </ul>
                      </li>
                    <li>PEP List :-</li>
                    <li>Filing History :-{filingHistory && filingHistory.length ? filingHistory[0]?.action_date && moment(filingHistory[0].action_date).format('YYYY-MM-DD'):""}</li>
                    <li>Other companies directors are linked to :-</li>
                    <li>Debt owed :-</li>
                    <li>Capacity to borrow :-</li>
                  </ul>

                </CompanyDetails>
              </BoxCard>

              <BoxCard>
                <div>
                  <IncomeCardHead>Verification</IncomeCardHead>
                </div>

                <div>
                  <ListWrapper className={styles.list}>
                    <input
                      type="checkbox"
                      id="BankAccountVerification"
                      name="Bank account verification"
                      checked={isVerification?.isBankingVerification}
                    />
                    <label htmlFor="BankAccountVerification">
                      Bank account
                    </label>
                  </ListWrapper>

                  <ListWrapper className={styles.list}>
                    <input
                      type="checkbox"
                      id="CustomerVerification"
                      name="Customer Verification"
                      checked={isVerification?.isCustomerVerification}

                    />
                    <label htmlFor="CustomerVerification">
                      Customer Verification
                    </label>
                  </ListWrapper>

                  {/* <ListWrapper className={styles.list}>
                  <input
                    type="checkbox"
                    id="FinancialStatementVerification"
                    name="Financial statement Verification"
                    value="Financial statement Verification"
                  />
                  <label htmlFor="FinancialStatementVerification">
                    Financial statement Verification
                  </label>
                </ListWrapper> */}

                  <ListWrapper className={styles.list}>
                    <input
                      type="checkbox"
                      id="AddressVerification"
                      name="Address Verification"
                      checked={isVerification?.isAddressVerification}
                    // onChange={(e) => { setIsVerification({ ...isVerification, isAddressVerification: e.target.checked }) }}
                    />
                    <label htmlFor="AddressVerification">
                      Company Details
                    </label>
                  </ListWrapper>

                  {/* <ListWrapper className={styles.list}>
                    <input
                      type="checkbox"
                      id="SourceOfIncome"
                      name="Source of income"
                      checked={isVerification?.isAddressVerification}
                    // onChange={(e) => { setIsVerification({ ...isVerification, isAddressVerification: e.target.checked }) }}
                    />
                    <label htmlFor="AddressVerification">
                      Source of income
                    </label>
                  </ListWrapper> */}
                </div>
              </BoxCard>


            </IncomePageWrapperTop>

            <IncomePageWrapperTop>

              <BoxCard>
                <div>
                  <IncomeCardHead>Risk Insights</IncomeCardHead>
                </div>
                <RiskInsights>
                <ul>
                      <li>Sanctioned List :- </li>
                      <div style={{marginLeft:'20px',display:'flex', flexDirection:"column"}}>
                        <span>a. Transactions to Sanctioned Country List</span>
                        <span>b. Transactions to High-risk countries</span>
                      </div>
                      <li>Other Risks :- </li>
                    </ul>
                    </RiskInsights>
              </BoxCard>

              <BoxCard>
                
                {/* <div>
                  <IncomeCardHead>Metrics</IncomeCardHead>
                </div>

                <MatrixTable>
                  <thead>
                    <tr>
                      <th style={{width: '20%'}}></th>
                      <th>Monthly Recurring Revenue (MRR)</th>
                      <th>Growth Rate (MoM)</th>
                      <th>Monthly Spend</th>
                      <th>No of unique transactions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metrics && metrics.length ? (
                      <>
                        {metrics.map((item) => (
                          <tr>
                              <td>{item?.month && moment(item?.month).format("MMM-YY")}</td>
                              <td>{item?.revenue}</td>
                              <td>{item?.growth_rate}</td>
                              <td>{item?.burn_rate}</td>
                              <td>{item?.unique_customer_number}</td>

                          </tr>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </MatrixTable> */}
                   
        
                  <div>
                    <div
                      onClick={() => setFinancialMatrics(!financialMatrics)}
                      aria-expanded={financialMatrics}
                      aria-label="show more"
                      style={{
                        cursor: "pointer",
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        padding: "8px",
                      }}
                    >
                      <IncomeCardHead>Financial Metrics</IncomeCardHead>

                      <ExpandMore
                        expand={financialMatrics}
                        onClick={() => setFinancialMatrics(!financialMatrics)}
                        aria-expanded={financialMatrics}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse in={financialMatrics} timeout="auto" unmountOnExit>
                    <MatrixTable>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Monthly Recurring Revenue (MRR)</th>
                        <th>MMR Growth Rate (MoM)</th>
                        <th>Monthly Burn Rate</th>
                        <th>Monthly Salary Payments</th>
                        <th>No of unique transactions</th>
                        <th>Payment to Directors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {metrics && metrics.length ? (
                        <>
                          {metrics.map((item) => (
                            <tr>
                              <td>
                                <div style={{ width: "80px" }}>
                                  {item?.month &&
                                    moment(item?.month).format("MMM-YY")}
                                </div>
                              </td>
                              <td>
                                {" "}
                                £{" "}
                                {(item?.revenue || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td>
                                {" "}
                                {(item?.growth_rate || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}{" "}
                                %
                              </td>
                              <td>
                                £{" "}
                                {(item?.burn_rate || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td></td>
                              <td>{item?.unique_customer_number}</td>
                              <td></td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() => setTopInBound(!topInBound)}
                      aria-expanded={topInBound}
                      aria-label="show more"
                      style={{
                        cursor: "pointer",
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        padding: "8px",
                      }}
                    >
                      <IncomeCardHead>Top 5 Inflows</IncomeCardHead>

                      <ExpandMore
                        expand={topInBound}
                        onClick={() => setTopInBound(!topInBound)}
                        aria-expanded={topInBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse in={topInBound} timeout="auto" unmountOnExit>
                      <MatrixTable
                        style={{
                          height: "450px",
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topTransaction && topTransaction?.inFlow?.length ? (
                            <>
                              {topTransaction?.inFlow.map((item:any, index: number) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>INBOUND</td>
                                  <td>{item?.description}</td>
                                  <td>
                                  
                                    {item?.date &&
                                      moment(item?.date).format("DD-MMM-YYYY")}
                                  </td>
                                  <td>
                                  £{" "}
                                {(item?.amount || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() => setTopOutBound(!topOutBound)}
                      aria-expanded={topOutBound}
                      aria-label="show more"
                      style={{
                        cursor: "pointer",
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        padding: "8px",
                      }}
                    >
                      <IncomeCardHead>
                        Top 5 Outflows
                      </IncomeCardHead>

                      <ExpandMore
                        expand={topOutBound}
                        onClick={() => setTopOutBound(!topOutBound)}
                        aria-expanded={topOutBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse in={topOutBound} timeout="auto" unmountOnExit>
                      <MatrixTable
                        style={{
                          height: "450px",
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topTransaction && topTransaction?.outFlow?.length ? (
                            <>
                              {topTransaction?.outFlow.map((item:any, index: number) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>OUTBOUND</td>
                                  <td>{item?.description}</td>
                                  <td>
                                    {item?.date &&
                                      moment(item?.date).format("DD-MMM-YYYY")}
                                  </td>
                                  <td>
                                  £{" "}
                                {(item?.amount || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() => setBottomInBound(!bottomInBound)}
                      aria-expanded={bottomInBound}
                      aria-label="show more"
                      style={{
                        cursor: "pointer",
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        padding: "8px",
                      }}
                    >
                      <IncomeCardHead>
                        Bottom 5 Inflows
                      </IncomeCardHead>

                      <ExpandMore
                        expand={bottomInBound}
                        onClick={() => setBottomInBound(!bottomInBound)}
                        aria-expanded={bottomInBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse in={bottomInBound} timeout="auto" unmountOnExit>
                      <MatrixTable
                        style={{
                          height: "450px",
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bottomTransaction &&
                          bottomTransaction?.inFlow?.length ? (
                            <>
                              {bottomTransaction?.inFlow.map((item:any, index: number) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>INBOUND</td>
                                  <td>{item?.description}</td>
                                  <td>
                                    {item?.date &&
                                      moment(item?.date).format("DD-MMM-YYYY")}
                                  </td>
                                  <td>
                                  £{" "}
                                {(item?.amount || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() => setBottomOutBound(!bottomOutBound)}
                      aria-expanded={bottomOutBound}
                      aria-label="show more"
                      style={{
                        cursor: "pointer",
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        padding: "8px",
                      }}
                    >
                      <IncomeCardHead>
                        Bottom 5 Outflows
                      </IncomeCardHead>

                      <ExpandMore
                        expand={bottomOutBound}
                        onClick={() => setBottomOutBound(!bottomOutBound)}
                        aria-expanded={bottomOutBound}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse in={bottomOutBound} timeout="auto" unmountOnExit>
                      <MatrixTable
                        style={{
                          height: "450px",
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bottomTransaction &&
                          bottomTransaction?.outFlow?.length ? (
                            <>
                              {bottomTransaction?.outFlow.map((item:any, index: number) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>OUTBOUND</td>
                                  <td>{item?.description}</td>
                                  <td>
                                    {item?.date &&
                                      moment(item?.date).format("DD-MMM-YYYY")}
                                  </td>
                                  <td>
                                  £{" "}
                                {(item?.amount || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() =>
                        setTop5InBoundPaymentSME(!top5InBoundPaymentSME)
                      }
                      aria-expanded={top5InBoundPaymentSME}
                      aria-label="show more"
                      style={{
                        cursor: "pointer",
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        padding: "8px",
                      }}
                    >
                      <IncomeCardHead>
                        Top 5 Inbound Small Business Payments
                      </IncomeCardHead>

                      <ExpandMore
                        expand={top5InBoundPaymentSME}
                        onClick={() =>
                          setTop5InBoundPaymentSME(!top5InBoundPaymentSME)
                        }
                        aria-expanded={top5InBoundPaymentSME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>
                    <Collapse
                      in={top5InBoundPaymentSME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MatrixTable
                        style={{
                          height: "450px",
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">SME</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topPaymentSME && topPaymentSME?.inFlow?.length ? (
                            <>
                              {topPaymentSME?.inFlow.map((item:any, index: number) => (
                                <>
                                  {index < 5 && (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>INBOUND</td>
                                      <td>{item?.description}</td>
                                      <td>
                                        {item?.date &&
                                          moment(item?.date).format(
                                            "DD-MMM-YYYY"
                                          )}
                                      </td>
                                      <td>
                                  £{" "}
                                {(item?.amount || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                    <div
                      onClick={() =>
                        setTop5OutBoundPaymentSME(!top5OutBoundPaymentSME)
                      }
                      aria-expanded={top5OutBoundPaymentSME}
                      aria-label="show more"
                      style={{
                        cursor: "pointer",
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        padding: "8px",
                      }}
                    >
                      <IncomeCardHead>
                        Top 5 Outbound Small Business Payments
                      </IncomeCardHead>

                      <ExpandMore
                        expand={top5OutBoundPaymentSME}
                        onClick={() =>
                          setTop5OutBoundPaymentSME(!top5OutBoundPaymentSME)
                        }
                        aria-expanded={top5OutBoundPaymentSME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse
                      in={top5OutBoundPaymentSME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MatrixTable
                        style={{
                          height: "450px",
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">SME</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topPaymentSME && topPaymentSME?.outFlow?.length ? (
                            <>
                              {topPaymentSME?.outFlow.map((item:any, index: number) => (
                                <>
                                  {index < 5 && (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>INBOUND</td>
                                      <td>{item?.description}</td>
                                      <td>
                                        {item?.date &&
                                          moment(item?.date).format(
                                            "DD-MMM-YYYY"
                                          )}
                                      </td>
                                      <td>
                                  £{" "}
                                {(item?.amount || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() =>
                        setTop5InBoundPaymentLME(!top5InBoundPaymentLME)
                      }
                      aria-expanded={top5InBoundPaymentLME}
                      aria-label="show more"
                      style={{
                        cursor: "pointer",
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        padding: "8px",
                      }}
                    >
                      <IncomeCardHead>
                        Top 5 Inbound Large Payments
                      </IncomeCardHead>

                      <ExpandMore
                        expand={top5InBoundPaymentLME}
                        onClick={() =>
                          setTop5InBoundPaymentLME(!top5InBoundPaymentLME)
                        }
                        aria-expanded={top5InBoundPaymentLME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse
                      in={top5InBoundPaymentLME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MatrixTable
                        style={{
                          height: "450px",
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">LME</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topPaymentLME && topPaymentLME?.inFlow?.length ? (
                            <>
                              {topPaymentLME?.inFlow.map((item:any, index: number) => (
                                <>
                                  {index < 5 && (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>OUTBOUND</td>
                                      <td>{item?.description}</td>
                                      <td>
                                        {item?.date &&
                                          moment(item?.date).format(
                                            "DD-MMM-YYYY"
                                          )}
                                      </td>
                                      <td>
                                  £{" "}
                                {(item?.amount || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
                  <div>
                    <div
                      onClick={() =>
                        setTop5OutBoundPaymentLME(!top5OutBoundPaymentLME)
                      }
                      aria-expanded={top5OutBoundPaymentLME}
                      aria-label="show more"
                      style={{
                        cursor: "pointer",
                        marginBottom: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        padding: "8px",
                      }}
                    >
                      <IncomeCardHead>
                        Top 5 Outbound Large Business Payments
                      </IncomeCardHead>

                      <ExpandMore
                        expand={top5OutBoundPaymentLME}
                        onClick={() =>
                          setTop5OutBoundPaymentLME(!top5OutBoundPaymentLME)
                        }
                        aria-expanded={top5OutBoundPaymentLME}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </div>

                    <Collapse
                      in={top5OutBoundPaymentLME}
                      timeout="auto"
                      unmountOnExit
                    >
                      <MatrixTable
                        style={{
                          height: "450px",
                          margin: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th align="center">INBOUND/OUTBOUND</th>
                            <th align="center">Description</th>
                            <th align="center">Date</th>
                            <th align="center">LME</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topPaymentLME && topPaymentLME?.outFlow?.length ? (
                            <>
                              {topPaymentLME?.outFlow.map((item:any, index: number) => (
                                <>
                                  {index < 5 && (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>OUTBOUND</td>
                                      <td>{item?.description}</td>
                                      <td>
                                        {item?.date &&
                                          moment(item?.date).format(
                                            "DD-MMM-YYYY"
                                          )}
                                      </td>
                                      <td>
                                  £{" "}
                                {(item?.amount || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </MatrixTable>
                    </Collapse>
                  </div>
              </BoxCard>
            </IncomePageWrapperTop>

            <BoxCardTable>
              <IncomeTable>
                <thead>
                  <tr>
                    <th>Source of Income</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Category</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                {filterTransactionInfo?.length ? (
                      <>
                        {filterTransactionInfo.map((item) => (
                          <tr>
                            <td>{item?.detail}</td>
                            <td>
                              {item?.description !== "Total" &&
                                moment(item?.date).format("DD MMM YYYY")}
                            </td>
                            <td>{item?.description}</td>
                            <td>{item?.category}</td>
                            <td>
                              {" "}
                              £{" "}
                              {(item.amount || 0).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                </tbody>
              </IncomeTable>
            </BoxCardTable>
          </IncomePageWrapperMain>
        </MainWrapper>
      </OverviewWrapper>
      </ThemeProvider>
    </StyledEngineProvider>
    )}
    </>

  );
};

export default IncomeAndJobVerification;
