/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import SuccessImg from "assets/images/Success.png";
import { Link, NavLink } from "react-router-dom";
import {
  HeaderLeftSide,
  HeaderStyled,
  Name,
  UserDetailsWrapper,
  UserHistory,
  UserName,
} from "./dashboard-overview-header.style";
import { Button } from "components/button/button";
import { MdChevronLeft } from "react-icons/md";
import { MonthlyIncome } from "pages/dashboard-overview/tabs/monthly-income";
import { MonthlyAffordability } from "pages/dashboard-overview/tabs/monthly-affordability";
import { MonthlyExpenses } from "pages/dashboard-overview/tabs/monthly-expenses";

const DashboardOverviewHeader = (props) => {
  const [incomeTab, setIncomeTab] = useState(false);
  const [userData, setUserData]: any = useState();
  const handleIncomeTab = () => {
    setIncomeTab(true);
  };
  const closeIncomeTab = () => {
    setIncomeTab(false);
  };

  const [affordability, setAffordability] = useState(false);
  const [expenses, setExpenses] = useState(false);

  const handleAffordabilityTab = () => {
    setAffordability(true);
  };
  const handleExpensesTab = () => {
    setExpenses(true);
  };
  const closeExpensesTab = () => {
    setExpenses(false);
  };
  const closeAffordability = () => {
    setAffordability(false);
  };
  useEffect(() => {
    const userData = JSON.parse(
      localStorage.getItem("<<<user-info>>>") as string
    );

    setUserData(userData);
  }, []);
  const { variant } = props;
  return (
    <HeaderStyled className={variant}>
      <HeaderLeftSide>
        <Link to="/dashboard">
          <MdChevronLeft size={30} />
        </Link>

        <UserDetailsWrapper>
          <UserName>
            <Name>
              {userData?.firstName} {userData?.lastName}
            </Name>
            <img src={SuccessImg} alt="" />
          </UserName>

          {/* <UserHistory>
            Amoueq Oscae, Open banking date fetched 04, Aug 2022 . 3:15
          </UserHistory> */}
        </UserDetailsWrapper>
      </HeaderLeftSide>

      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn">
        <span className="navicon"></span>
      </label>

      <ul className="menu">
        {/* <li>
          <NavLink
            className="menu-item"
            to="/dashboard-overview"
            activeClassName="active-item"
          >
            Overview
          </NavLink>
        </li> */}
        {/* <li>
          <span className="menu-item" onClick={() => handleIncomeTab()}>
            Income
          </span>
        </li>
        <li>
          <span className="menu-item" onClick={() => handleExpensesTab()}>
            Expenses
          </span>
        </li>
        <li>
          <span className="menu-item" onClick={() => handleAffordabilityTab()}>
            Affordability
          </span>
        </li> */}

        <li>
          <NavLink
            className="menu-item"
            to="/kyc"
            activeClassName="active-item"
          >
            KYC
          </NavLink>
        </li>
        <li>
          <NavLink
            className="menu-item"
            to="/proof-of-funds"
            activeClassName="active-item"
          >
            Proof Of Funds 
          </NavLink>
        </li>

        {/* <li>
          <Button className="btn" onClick={() => handleExpensesTab()}>
            Export
          </Button>
        </li> */}
        {/* <li>
          <NavLink
            className="menu-item"
            to="/Risk"
            activeClassName="active-item"
          >
            Risk
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            className="menu-item"
            to="/contact"
            activeClassName="active-item"
          >
            Transactions
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            className="menu-item"
            to="/contact"
            activeClassName="active-item"
          >
            Category Insights
          </NavLink>
        </li> */}
      </ul>

      <MonthlyIncome
        visible={incomeTab}
        incomesData={props.incomesData}
        incomeAmount={props.incomeAmount}
        handleClose={closeIncomeTab}
      />
      <MonthlyExpenses
        visible={expenses}
        expensesData={props.expensesData}
        expensesAmount={props.expensesAmount}
        handleClose={closeExpensesTab}
      />
      <MonthlyAffordability
        visible={affordability}
        affordabilityData={props.affordabilityData}
        affordabilityAmount={props.affordabilityAmount}
        handleClose={closeAffordability}
      />
    </HeaderStyled>
  );
};

export { DashboardOverviewHeader };
